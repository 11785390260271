import "../styles/learning-buddy.scss"
import classNames from "classnames"
import { connect } from "react-redux"

import theme from "../theme/theme"
import Values from "values.js"

const mapStateToProps = ({ session }) => {
  return {
    buddy: session.user.learningBuddy,
  }
}

function LearningBuddyMessage(props) {
  const backgroundColor = new Values(theme.palette.secondary.main)
    .tint(70)
    .hexString()

  let buddyLabel = props.buddy ? props.buddy.attributes.name : ""
  if (props.selectedBuddy) {
    buddyLabel = props.selectedBuddy.attributes.name
  }

  if (!props.inline) {
    return (
      <div className={classNames("buddy-container")}>
        {props.message ? (
          <div style={{ backgroundColor }} className="buddy-message">
            <div
              className={classNames("buddy-image", buddyLabel.toLowerCase())}
            ></div>
            <p>{props.message}</p>
          </div>
        ) : (
          <div
            className={classNames(
              "buddy-image standalone",
              buddyLabel.toLowerCase()
            )}
          ></div>
        )}
      </div>
    )
  } else {
    return (
      <div className={classNames("buddy-container inline")}>
        <div
          className={classNames("buddy-image", buddyLabel.toLowerCase())}
        ></div>
        <div style={{ backgroundColor }} className="buddy-message">
          <p>{props.message}</p>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(LearningBuddyMessage)
