import Loading from "@mobilemind/common/src/components/Loading"
import { Container, Paper } from "@mui/material"
import { useAsyncEffect } from "@react-hook/async"
import { saveCourse } from "actions/courses"
import { resetFilters } from "features/courses/coursesSlice"
import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { getCategories } from "store/reducers/categories"
import { getSubGroups } from "store/reducers/session"
import { CourseTemplateNavbar } from "./CourseTemplateNavbar"
import { fetchActiveCourse } from "./activeCourseSlice"
import { CourseForm } from "./components/CourseForm"
import { CourseTemplateView } from "./components/CourseTemplateView"
import { CourseFormValues } from "./types"

export const CourseTemplateContainer = () => {
  const { id: courseId } = useParams<{
    id: string
  }>()
  const history = useHistory()

  const dispatch = useAppDispatch()
  const session = useAppSelector((state) => state.session)
  const categories = useAppSelector((state) => state.categories)

  const [message, setMessage] = useState<string | null>(null)

  const {
    status,
    error,
    value: course,
  } = useAsyncEffect(async () => {
    if (!session.subGroups.fetched && session.group.uuid) {
      setMessage("Loading data…")
      dispatch(getSubGroups())
    }
    if (!categories.fetched) {
      await dispatch(getCategories())
    }
    // @ts-ignore
    dispatch(resetFilters())

    setMessage("Getting community course…")
    // Just fetch the course and create a new one from the template.
    return await dispatch(
      fetchActiveCourse({ id: courseId, template: true, shouldLock: false })
    ).unwrap()
  }, [])

  if (error) {
    throw error
  }

  if (status === "loading") {
    return <Loading fullPage={true} message={message} />
  }

  if (!course) {
    return null
  }

  const isOwnCommunityCourse =
    course.creatorOrg?.id === session.group.uuid[0].value

  if (!isOwnCommunityCourse) {
    return (
      <>
        <CourseTemplateNavbar course={course} />
        <Container
          fixed={true}
          maxWidth="md"
          sx={{
            marginBlockStart: "100px",
            position: "relative",
            paddingBlock: 5,
          }}
        >
          <Paper>
            <CourseTemplateView course={course} />
          </Paper>
        </Container>
      </>
    )
  }

  const handleSubmit = async (values: CourseFormValues) => {
    await dispatch(
      saveCourse({
        isOwnCommunityCourse,
        activeCourse: values,
        isDraft: false,
        isCopy: false,
        fromTemplate: false,
        isSharing: true,
      })
    )
    history.push("/create/courses")
  }

  return <CourseForm initialValues={course} onSubmit={handleSubmit} />
}
