import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"

import moment from "moment"

/**
 * Get all available goal entities and include learning paths
 */
export function getGoals() {
  return async (dispatch, getState) => {
    const { session } = getState()

    let pages = 1
    let i = 0
    let response, data
    let goals = []
    let included = []

    try {
      while (i < pages) {
        let query = {
          filter: {
            org: {
              group: {
                conjunction: "OR",
              },
            },
            "org-self": {
              condition: {
                path: "field_organization.id",
                value: session.group.uuid[0].value,
                memberOf: "org",
              },
            },
            "org-mobilemind": {
              condition: {
                path: "field_organization.id",
                value: null,
                memberOf: "org",
              },
            },
          },
          include:
            "field_learning_path,field_subgroup,field_teacher,field_job_title",
        }

        response = await fetchWrapper.get(
          "/api/goal_entity/learning_path_goal?" + qs.stringify(query)
        )

        if (response.ok) {
          data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)
          goals = goals.concat(data.data)
          included = included.concat(data.included)
        }
        i++
      }

      dispatch({
        type: "goalsSlice/getGoals",
        payload: goals,
        meta: {
          included,
          fetched: true,
        },
      })

      return goals
    } catch (err) {
      console.log(err)
    }
  }
}

export function getOrgGoals(filters) {
  return async (dispatch, getState) => {
    const { session, entityList } = getState()
    const filters = entityList.orgGoals.filters

    let orgId = session.group && session.group.uuid && session.group.id[0].value
    const groupRoles = session.groupRoles
    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    let query = {
      sort_by: filters.sortBy,
      sort_order: filters.sortOrder,
      combine: filters.searchQuery,
    }

    if (
      filters.sortBy === "name" ||
      filters.sortBy === "field_goal_date_value"
    ) {
      query.sort_order = "ASC"
    }
    if (!filters.status) {
      query.field_draft_value = 1
    }
    if (filters.status === 1) {
      query.field_draft_value = 0
    }
    if (filters.subGroup !== "any") {
      query.field_subgroup_target_id = filters.subGroup
    }
    if (filters.subGroup === "all") {
      query.field_subgroup_target_id = 0
    }
    if (filters.jobTitle !== "any") {
      query.field_job_title_target_id = filters.jobTitle
    }

    try {
      let content = []
      query.page = entityList.orgGoals.currentPage

      let url
      if (session.isSiteAdmin) {
        url = "/api/goal/mm_goal_list"
      } else {
        if (isGroupAdmin) {
          let groupIds =
            session.subgroups.data &&
            session.subgroups.data
              .map((group) => group.drupal_internal__id)
              .join("+")
          url = "/api/goal/goal_list/" + orgId + "/" + groupIds
        } else {
          url = "/api/goal/goal_list/" + orgId + "/all"
        }
      }

      let response = await fetchWrapper.get(url + "?" + qs.stringify(query))
      if (response.ok) {
        let data = await response.json()

        if (!data.rows.content) {
          data.rows.forEach((row) => {
            content.push(row)
          })
        }

        dispatch({
          type: "entityList/getOrgGoals",
          payload: content,
          meta: {
            pages: data.pager.total_pages,
            totalRecords: Number(data.pager.total_items),
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveGoal(activeGoal, isDraft, firstPublish) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const { groupRoles } = session

    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    const deadlineDate = activeGoal.deadline
    const expirationDate = activeGoal.expirationDate
    const deadline = moment(deadlineDate)
    const expiration = moment(expirationDate)

    let body = {
      data: {
        type: "goal_entity--goal_entity",
        attributes: {
          field_goal_date: deadline.format("YYYY-MM-DD"),
          field_expiration_date: expiration.format("YYYY-MM-DD"),
          name: activeGoal.name,
          field_email_send: activeGoal.isEmailSend,
          field_goal_reminder: activeGoal.goalReminders.includes("none")
            ? []
            : activeGoal.goalReminders,
        },
        relationships: {
          field_learning_path: {
            data: {
              type: "learning_path--learning_path",
              id: activeGoal.learningPath.field_lp_uuid,
            },
          },
        },
      },
    }

    if (isDraft || firstPublish) {
      body.data.attributes.field_draft = true
    }

    // Set subgroup owner if user is group level
    if (activeGoal.subGroupOwner !== "org" && isGroupAdmin) {
      body.data.relationships.field_subgroup_owner = {
        data: {
          type: "group--group",
          id: activeGoal.subGroupOwner
            ? activeGoal.subGroupOwner
            : session.subgroups.data[0].id,
        },
      }
    }

    // Include the org if they are not a MM administrator
    if (
      session.roles &&
      !session.roles.find((role) => role.target_id === "administrator")
    ) {
      body.data.relationships.field_organization = {
        data: {
          type: "group--organization",
          id: session.group.uuid[0].value,
        },
      }
    }

    if (!activeGoal.isIndividualGoal) {
      // First clear out any teachers
      body.data.relationships.field_teacher = {
        data: [],
      }

      // Then get on with it
      if (activeGoal.subGroups.length) {
        body.data.relationships.field_subgroup = {
          data: [],
        }

        activeGoal.subGroups.forEach((group) => {
          body.data.relationships.field_subgroup.data.push({
            type: "group--group",
            id: group.id,
          })
        })
      } else if (isGroupAdmin && session.subgroups.data.length === 1) {
        body.data.relationships.field_subgroup = {
          data: [
            {
              type: "group--group",
              id: session.subgroups.data[0].id,
            },
          ],
        }
      } else {
        body.data.relationships.field_subgroup = {
          data: null,
        }
      }

      if (activeGoal.jobTitles.length) {
        body.data.relationships.field_job_title = {
          data: [],
        }

        activeGoal.jobTitles.forEach((jobTitle) => {
          body.data.relationships.field_job_title.data.push({
            type: "taxonomy_term--job_titles",
            id: jobTitle.id,
          })
        })
      } else {
        body.data.relationships.field_job_title = {
          data: null,
        }
      }
    }
    // Target audience = INDIVIDUALS
    else {
      // First clear out any groups and job titles
      body.data.relationships.field_subgroup = {
        data: [],
      }
      body.data.relationships.field_job_title = {
        data: [],
      }

      // Then get it on with it
      body.data.relationships.field_teacher = {
        data: activeGoal.users.map((user) => {
          return {
            type: "user--user",
            id: user.uuid,
          }
        }),
      }
    }

    if (activeGoal.id) {
      body.data.id = activeGoal.id
    }

    try {
      let response
      if (activeGoal.id) {
        response = await fetchWrapper.patch(
          "/api/goal_entity/learning_path_goal/" + activeGoal.id,
          session.token,
          JSON.stringify(body)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/goal_entity/learning_path_goal",
          session.token,
          JSON.stringify(body)
        )
      }
      if (response.ok) {
        let data = await response.json()

        if (!isDraft) {
          data.data.attributes.field_draft = false
        }

        if (activeGoal.id) {
          dispatch({
            type: "entityList/updateGoal",
            payload: data.data,
          })
        } else {
          dispatch({
            type: "entityList/addCreatedGoal",
            payload: data.data,
          })
        }

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteGoal(goalId) {
  return async (dispatch, getState) => {
    const { session } = getState()
    try {
      let response = await fetchWrapper.remove(
        "/api/goal_entity/learning_path_goal/" + goalId,
        session.token
      )
      if (response.ok) {
        dispatch({
          type: "entityList/deleteGoal",
          payload: goalId,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}
