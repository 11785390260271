import GoogleMapsContainer from "../GoogleMapsContainer"
import {
  MobileMindLocationLocationBase,
  MobileMindRoomRoomBase,
} from "../../types/jsonapi"
import { addressToString } from "../../functions"
import { Link, Stack, Typography } from "@mui/material"
import RoomIcon from "../../img/room.svg"
import LocationIcon from "../../img/location-color.svg"
type MapProps = {
  location: MobileMindLocationLocationBase
  eventRoom?: MobileMindRoomRoomBase
}

export function SidebarLocationMap({ location, eventRoom }: MapProps) {
  const { name, field_address } = location.attributes
  return (
    <>
      <Stack
        sx={{
          paddingBlock: 2,
        }}
        spacing={2}
      >
        <Stack sx={{ alignItems: "flex-start" }} direction="row" spacing={2}>
          <img src={LocationIcon} width={25} alt="Location" />
          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
            {name}
            {field_address && (
              <Link
                href={
                  "https://www.google.com/maps?q=" +
                  addressToString(field_address)
                }
                sx={{ fontSize: 12, display: "block" }}
                target="_blank"
                rel="noreferrer"
              >
                <strong>
                  {field_address.address_line1},{" "}
                  {field_address.address_line2 &&
                    field_address.address_line2 + ","}{" "}
                  {field_address.locality} {field_address.administrative_area}{" "}
                  {field_address.postal_code}
                </strong>
              </Link>
            )}
          </Typography>
        </Stack>

        {eventRoom && (
          <Stack sx={{ alignItems: "center" }} direction="row" spacing={2}>
            <img src={RoomIcon} width={25} alt="Room" />
            <Typography sx={{ fontSize: 12 }}>
              {eventRoom.attributes.name}
            </Typography>
          </Stack>
        )}

        <GoogleMapsContainer location={location} />

        {location.attributes.field_directions && (
          <Typography sx={{ fontSize: 12, lineHeight: "1.15rem" }}>
            &quot;{location.attributes.field_directions}&quot;
          </Typography>
        )}
      </Stack>
    </>
  )
}
