const apiUrl = process.env.REACT_APP_API_URL

function generateHeaders(token) {
  return new Headers({
    "Content-Type": "application/vnd.api+json",
    Accept: "application/vnd.api+json",
    "X-CSRF-Token": token,
  })
}

async function get(url, cookie = null) {
  let options = {
    method: "GET",
    headers: new Headers({
      Accept: "application/vnd.apijson",
      "Content-Type": "application/vnd.apijson",
    }),
  }
  if (cookie) {
    options.cookie = cookie
  } else {
    options.credentials = "include"
  }
  return await fetch(apiUrl + url, options)
}

async function post(url, token, body) {
  let options = {
    credentials: "include",
    method: "POST",
    body: body,
    headers: generateHeaders(token),
  }
  return await fetch(apiUrl + url, options)
}

async function patch(url, token, body) {
  let options = {
    credentials: "include",
    method: "PATCH",
    body: body,
    headers: generateHeaders(token),
  }
  return await fetch(apiUrl + url, options)
}

async function remove(url, token) {
  let options = {
    credentials: "include",
    method: "DELETE",
    headers: generateHeaders(token),
  }
  return await fetch(apiUrl + url, options)
}

const fetchWrapper = { get, post, patch, remove }

export default fetchWrapper
