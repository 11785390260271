import theme from "../theme/theme"

function Plus() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-1071.000000, -245.000000)"
          fill={theme.palette.primary.main}
          fillRule="nonzero"
        >
          <g id="plus" transform="translate(1071.000000, 245.000000)">
            <path
              d="M10,0 C15.5228474,0 20,4.47715263 20,10 C20,15.5228474 15.5228474,20 10,20 C4.47715263,20 0,15.5228474 0,10 C0,4.47715263 4.47715263,0 10,0 Z M10,4.21052632 C9.41864789,4.21052632 8.94736842,4.68180579 8.94736842,5.26315789 L8.94736842,5.26315789 L8.94736842,8.94736842 L5.26315789,8.94736842 C4.68180579,8.94736842 4.21052632,9.41864789 4.21052632,10 C4.21052632,10.5813521 4.68180579,11.0526316 5.26315789,11.0526316 L5.26315789,11.0526316 L8.94736842,11.0526316 L8.94736842,14.7368421 C8.94736842,15.3181942 9.41864789,15.7894737 10,15.7894737 C10.5813521,15.7894737 11.0526316,15.3181942 11.0526316,14.7368421 L11.0526316,14.7368421 L11.0526316,11.0526316 L14.7368421,11.0526316 C15.3181942,11.0526316 15.7894737,10.5813521 15.7894737,10 C15.7894737,9.41864789 15.3181942,8.94736842 14.7368421,8.94736842 L14.7368421,8.94736842 L11.0526316,8.94736842 L11.0526316,5.26315789 C11.0526316,4.68180579 10.5813521,4.21052632 10,4.21052632 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Plus
