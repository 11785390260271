import { Button } from "@mui/material"
import ButtonLarge from "./ButtonLarge"
import theme from "../theme/theme"
import "../styles/sidebar.scss"
import classNames from "classnames"

interface SidebarProps {
  sections: {
    title: string
    id?: string
    children: {
      title: string
      iconClass: string
      onClick?: () => void
      count?: number
    }[]
  }[]
  updateFilters?: (params: {
    dateRange: string
    category: number | string
  }) => void
  setDateMin?: (arg: string) => void

  activeItem: string
  action?: {
    href: string
    label: string
  }
}

const SidebarSectioned = ({ sections, activeItem, action }: SidebarProps) => {
  return (
    <div id="sidebar" className="sectioned">
      {sections.map((section) => {
        return (
          <div key={section.title} id={section.id}>
            <h3>{section.title}</h3>
            <ul>
              {section.children.map((child) => {
                const count = child.count ? child.count : 0

                return (
                  <li key={child.title}>
                    <Button
                      className={classNames(
                        activeItem === child.title && "active"
                      )}
                      style={{
                        backgroundColor:
                          activeItem === child.title
                            ? theme.palette.secondary.main
                            : "inherit",
                      }}
                      onClick={child.onClick}
                    >
                      <span className={"icon " + child.iconClass} />
                      <div style={{ flexGrow: 1 }}>{child.title}</div>
                      {count > 0 && <span className="count">{count}</span>}
                    </Button>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}

      {action && (
        <a style={{ padding: 15, display: "flex" }} href={action.href}>
          <ButtonLarge style={{ width: "100%" }} className="button primary">
            <span>{action.label}</span>
          </ButtonLarge>
        </a>
      )}
    </div>
  )
}

export default SidebarSectioned
