import { useState } from "react"
import { Menu, MenuItem, Dialog } from "@mui/material"

import classNames from "classnames"
import "../styles/backpack.scss"

import "react-alice-carousel/lib/alice-carousel.css"

import Certificate from "../../../icons/Certificate"

import ButtonSmall from "../../ButtonSmall"
import BadgeCertificate from "./BadgeCertificate"
import LearningPathCertificate from "./LearningPathCertificate"
import { Badge, LearningPath } from "../../../types/explore"
import { UserEventsLearner } from "../../../types/events"
import EventCertificate from "./EventCertificate"
import { Categories } from "../../../types/taxonomy/category"
import { BackpackUserData } from "../../../types/backpack"
import { MandatedTrainingHistory } from "../../../types/mandatedTraining"
import { MandatedTrainingCertificate } from "./MandatedTrainingCertificate"

type CertProps = {
  label: string
  badge?: Badge
  learningPath?: LearningPath
  categories?: Categories | undefined
  event?: UserEventsLearner
  training?: MandatedTrainingHistory
  userId?: number
  hideShare?: boolean
  userData?: BackpackUserData
  // eslint-disable-next-line
  user: any
}

function CertificateMenu(props: CertProps) {
  const {
    badge,
    categories,
    event,
    learningPath,
    user,
    label,
    training,
    userId,
    userData,
    hideShare,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSharing, setIsSharing] = useState(false)

  function handleClick(type: string) {
    if (type === "share") {
      setIsSharing(true)
    }
    if (type === "view") {
      setIsSharing(false)
    }

    setModalOpen(true)
    setAnchorEl(null)
  }

  let printHref, ariaOwns, eventCategory

  if (badge) {
    ariaOwns = "certificate-menu-" + badge.id
    printHref =
      process.env.REACT_APP_TEACHER_URL + "/certificate/badge/" + badge.uuid
  }
  if (learningPath) {
    ariaOwns = "certificate-menu-" + learningPath.field_lp_id
    printHref =
      process.env.REACT_APP_TEACHER_URL +
      "/certificate/learning-path/" +
      learningPath.field_lp_uuid
  }
  if (event) {
    ariaOwns = "certificate-menu-" + event.id
    printHref =
      process.env.REACT_APP_TEACHER_URL + "/certificate/event/" + event.uuid_1

    eventCategory =
      categories &&
      categories.data.find(
        (cat) =>
          cat.attributes.drupal_internal__tid ===
          Number(event.field_event_category)
      )
  }
  if (training) {
    ariaOwns = "certificate-menu-" + training.umta_id
    printHref =
      process.env.REACT_APP_TEACHER_URL +
      "/certificate/mandated-training/" +
      training.umta_id
  }

  return (
    <div className={classNames("certificate-menu-wrapper")}>
      <Dialog
        open={isModalOpen}
        className="certificate-modal"
        onClose={() => {
          setModalOpen(false)
        }}
      >
        {badge && userData && (
          <BadgeCertificate
            userData={userData}
            isSharing={isSharing}
            user={user}
            badge={badge}
          />
        )}
        {event && userData && (
          <EventCertificate
            userData={userData}
            category={eventCategory}
            isSharing={isSharing}
            user={user}
            event={event}
          />
        )}
        {learningPath && userData && (
          <LearningPathCertificate
            userData={userData}
            isSharing={isSharing}
            user={user}
            learningPath={learningPath}
          />
        )}
        {training && <MandatedTrainingCertificate training={training} />}
      </Dialog>

      <ButtonSmall
        // eslint-disable-next-line
        onClick={(event: any) => {
          setAnchorEl(event.currentTarget)
        }}
        aria-owns={anchorEl ? ariaOwns : undefined}
      >
        <Certificate />
        {label && <span style={{ paddingLeft: 10 }}>{label}</span>}
      </ButtonSmall>

      <Menu
        className={classNames(
          "certificate-menu",
          badge && "certificate-menu-badge",
          learningPath && "certificate-menu-learning-path",
          event && "certificate-menu-event",
          training && "certificate-menu-training"
        )}
        id={ariaOwns}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <MenuItem
          onClick={() => {
            handleClick("view")
          }}
        >
          <span className="icon certificate" />
          View
        </MenuItem>
        {!userId && (
          <>
            {!hideShare && (
              <MenuItem
                onClick={() => {
                  handleClick("share")
                }}
              >
                <span className="icon share" />
                Share
              </MenuItem>
            )}

            <MenuItem>
              <a href={printHref} target="_blank" rel="noreferrer">
                <span className="icon print" />
                Print
              </a>
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  )
}

export default CertificateMenu
