import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import classNames from "classnames"
import he from "he"
import moment from "moment"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import Loading from "../../components/Loading"

import "./styles/backpack.scss"

import CertificateMenu from "./certificate/CertificateMenu"
import BackpackProps from "../../types/backpack"
import { Categories } from "../../types/taxonomy/category"
import { Badge } from "../../types/explore"
import { SlideItem } from "./ContentRow"

type RowProps = {
  animationDelay: number
  backpack: BackpackProps
  categories: Categories
  changeNavbar?: (tag: string) => void
  data: Badge[]
  goTo?: (location: string) => void
  print: boolean
  setBadgeCarousel: (index: number) => void
  increaseBadgePage: (item: number) => void
  userId?: number
  total: number
  // eslint-disable-next-line
  session: any
}

function BadgesRow(props: RowProps) {
  const {
    data,
    setBadgeCarousel,
    increaseBadgePage,
    backpack,
    userId,
    session,
  } = props

  const [chunkSize, setChunkSize] = useState(12)

  const dispatch = useDispatch()

  function onResize() {
    if (window.innerWidth < 1275) {
      setChunkSize(6)
    } else if (window.innerWidth < 1600) {
      setChunkSize(9)
    } else {
      setChunkSize(12)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const chunkedData = []

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize)
    chunkedData.push(chunk)
  }

  function nextSlideChanged(event: SlideItem) {
    !backpack.badges.isFetching &&
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setBadgeCarousel(event.item > backpack.badges.activeIndex ? 1 : -1)
      )
  }

  function nextSlide(event: SlideItem) {
    if (event.itemsInSlide - 2 === event.item) {
      if (backpack.badges.currentPage < backpack.badges.totalPages - 1) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          increaseBadgePage(event.item)
        )
      }
    }
  }

  const items = chunkedData.map((chunk: Badge[], index: number) => {
    return (
      <div key={index} style={{ flexWrap: "wrap" }} className="chunk">
        {chunk.map((badge: Badge, index: number) => {
          return (
            <div key={index} className="item badge-item">
              <div
                className="badgeImage"
                style={{
                  backgroundImage: "url(" + badge.field_badge_image_uri + ")",
                }}
              />
              <div style={{ flex: 1 }}>
                <strong
                  style={{
                    display: "block",
                    fontSize: 14,
                    lineHeight: 1.2,
                  }}
                >
                  {he.decode(badge.name)}
                </strong>
                <span style={{ opacity: 0.6, fontSize: 12 }}>
                  {moment(badge.earned_date).format("MMMM Do, YYYY")}
                </span>
              </div>

              <CertificateMenu
                label={""}
                user={session.user}
                userId={userId}
                userData={props.backpack.userData}
                badge={badge}
              />
            </div>
          )
        })}
      </div>
    )
  })

  return (
    <div
      className={classNames(
        "inner inner-carousel",
        !backpack.badges.activeIndex && "disablePrev",
        chunkedData.length - 1 === backpack.badges.activeIndex && "disableNext"
      )}
    >
      {backpack.badges.isFetching && <Loading />}
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AliceCarousel
          infinite={true}
          activeIndex={backpack.badges.activeIndex}
          disableDotsControls={chunkedData.length === 1}
          paddingRight={25}
          paddingLeft={25}
          autoWidth={true}
          items={items}
          onSlideChange={(event) => nextSlide(event)}
          onSlideChanged={(event) => nextSlideChanged(event)}
        />
      }
    </div>
  )
}

export default BadgesRow
