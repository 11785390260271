/* eslint-disable */
import { useState, useEffect } from "react"

import { useDispatch } from "react-redux"

import Loading from "../../../components/Loading"
import moment from "moment"
import classNames from "classnames"

import { convertEstimatedTime } from "../../../functions/index"

import theme from "../../../theme/theme"
import TimeCapsule from "../../../components/TimeCapsule"
import SocialShare from "./SocialShare"
import he from "he"

import "../styles/certificate.scss"

import { createLearningPathCertificate } from "../../../actions/user"
import { LearningPath } from "../../../types/explore"
import { BackpackUserData } from "../../../types/backpack"

type CertProps = {
  isSharing: boolean
  learningPath: LearningPath
  userData: BackpackUserData
  user: any
}

function LearningPathCertificate(props: CertProps) {
  const { learningPath, user, userData, isSharing } = props

  const [certificateGenerated, setCertificateGenerated] = useState(false)
  const [newCertId, setNewCertId] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isSharing) {
      if (learningPath.field_user_lp_cert_uuid) {
        setCertificateGenerated(true)
        setNewCertId(learningPath.field_user_lp_cert_uuid)
      } else if (!newCertId) {
        createCertificate()
      }
    }
  }, [learningPath.field_user_lp_cert_uuid, newCertId, isSharing])

  const createCertificate = async () => {
    const newCert = await dispatch(
      // @ts-ignore
      createLearningPathCertificate(learningPath)
    )

    setTimeout(() => {
      // eslint-disable-next-line
      // @ts-ignore
      setNewCertId(newCert.data.id)
      setCertificateGenerated(true)
    }, 1000)
  }

  const shareUrl =
    process.env.REACT_APP_TEACHER_URL +
    "/certificate-share/learning-path/" +
    newCertId

  const totalMinutes = Number(learningPath.field_user_lp_total_time_spent)
  const hours =
    totalMinutes >= 60 ? Math.floor(totalMinutes / 60).toLocaleString() : 0
  const minutes = totalMinutes % 60

  return (
    <div
      id="certificateWrapper"
      className={classNames(
        "certificateWrapper",
        window.location.href.includes("certificate/learning-path") && "print"
      )}
    >
      <div className="mm-bg" />
      <div className="certificateLearningPath">
        {
          <div className="inner">
            <h2
              style={{
                marginBottom: window.location.href.includes("backpack/")
                  ? 80
                  : 20,
              }}
            >
              <span>
                This certifies that on{" "}
                {moment(learningPath.field_user_lp_updated).format(
                  "MMMM D, YYYY"
                )}
              </span>
              {userData ? (
                <>
                  {he.decode(userData.field_first_name_value)}{" "}
                  {he.decode(userData.field_last_name_value)}
                </>
              ) : (
                <>
                  {user && (
                    <>
                      {he.decode(user.attributes.field_first_name)}{" "}
                      {he.decode(user.attributes.field_last_name)}
                    </>
                  )}
                </>
              )}
              <span>
                successfully completed all the required courses for the Learning
                Path
              </span>
            </h2>

            <span className="title">{he.decode(learningPath.name)}</span>
            <div className="flexRow categories">
              {learningPath.field_ulp_time_spent &&
                learningPath.field_ulp_time_spent.map((category: any) => {
                  return (
                    <div key={category.field_parent_id} className="category">
                      <img
                        alt="category"
                        src={
                          process.env.REACT_APP_API_URL +
                          category.field_category_image
                        }
                      />
                      <div className="time">
                        <strong>
                          {he.decode(category.field_category_label)}
                        </strong>
                        <span>
                          {convertEstimatedTime(
                            Number(category.field_time_spent)
                          )}
                        </span>
                      </div>
                    </div>
                  )
                })}
            </div>
            <div
              className="learningPathImage"
              style={{
                backgroundImage: "url(" + learningPath.field_lp_image_uri + ")",
              }}
            />
            <img
              className="learningPathImage printBackground"
              src={learningPath.field_lp_image_uri}
              alt={learningPath.name}
            />

            <div className="logoWrapper">
              <img
                className="logo"
                // eslint-disable-next-line
                // @ts-ignore
                src={theme.mixins.images.logo}
                alt="MobileMind"
              />
            </div>

            {totalMinutes > 0 && (
              <div className="totalEstimatedTime">
                <header>Total Estimated Time Spent</header>
                <TimeCapsule hours={Number(hours)} minutes={Number(minutes)} />
                {window.location.href.includes("certificate") && (
                  <em style={{ display: "block", marginTop: 10, fontSize: 12 }}>
                    Time may count towards PD credit, depending on district
                    policies.
                  </em>
                )}
              </div>
            )}
          </div>
        }
      </div>

      {isSharing && (
        <div className="shareLink">
          {certificateGenerated ? (
            <>
              <strong>Copy the link below to share your certificate!</strong>
              <SocialShare shareUrl={shareUrl} learningPath={learningPath} />
            </>
          ) : (
            <Loading message={"Preparing certificate..."} />
          )}
        </div>
      )}
    </div>
  )
}

export default LearningPathCertificate
