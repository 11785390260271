import {
  Typography,
  Stack,
  MenuItem,
  Select,
  Button,
  TextField,
} from "@mui/material"
import { addressToString } from "@mobilemind/common/src/functions"
import { Box } from "@mui/material"
import {
  MobileMindLocationLocationBase,
  MobileMindRoomRoomBase,
} from "@mobilemind/common/src/types/jsonapi"

import RoomIcon from "../../../img/room.svg"
import UsersIcon from "../../../img/users.svg"
import PlusIcon from "../../../img/plus.svg"
import { useState } from "react"
import Loading from "@mobilemind/common/src/components/Loading"
import { useAppDispatch } from "store/hooks"
import { addNewRoom } from "actions/events"

type LocationPanelProps = {
  location: MobileMindLocationLocationBase
  currentRoom?: MobileMindRoomRoomBase
  availableRooms?: MobileMindRoomRoomBase[]
  changeRoom: (fullRoom: MobileMindRoomRoomBase) => void
  isConference: boolean
  isSession?: boolean
}

export function EventLocationItem({
  location,
  currentRoom,
  availableRooms,
  isConference,
  isSession,
  changeRoom,
}: LocationPanelProps) {
  const [isRoomFormOpen, setIsRoomFormOpen] = useState(false)
  const [newRoomName, setNewRoomName] = useState("")
  const [newRoomInstructions, setNewRoomInstructions] = useState("")
  const [newRoomCapacity, setNewRoomCapacity] = useState(0)
  const [isSaving, setIsSaving] = useState(false)

  const roomsForLocation = availableRooms?.filter(
    (room) => room.relationships.field_location.data.id === location.id
  )

  const dispatch = useAppDispatch()

  return (
    <Box
      sx={(theme) => ({
        marginTop: "0!important",
        paddingBlock: 3.5,
        paddingInline: 5,
        borderBottom: `1px solid ${theme.palette.grey.A200}`,
      })}
    >
      <Stack
        style={{ width: "100%", alignItems: "flex-start" }}
        direction="row"
        spacing={2}
      >
        <span className="icon location color" />
        <Box sx={{ width: "100%" }}>
          <Stack sx={{ width: "100%" }} direction={"row"} spacing={1}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ alignItems: "center", fontWeight: "bold" }}>
                {location.attributes.name}
              </Typography>
              <Typography sx={{ fontSize: 12 }}>
                {addressToString(location.attributes.field_address)}
              </Typography>
            </Box>
            {location.attributes.field_capacity > 0 && (
              <Stack
                direction={"row"}
                sx={{ alignItems: "center", marginTop: 1 }}
                spacing={2}
              >
                <img src={UsersIcon} width={20} alt="Users" />

                <Typography
                  sx={(theme) => ({
                    fontSize: 12,
                    padding: 1,
                    backgroundColor: theme.palette.grey.A200,
                    borderRadius: 0.75,
                  })}
                >
                  {location.attributes.field_capacity}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
      {!isConference && !isRoomFormOpen && (
        <Stack
          sx={{ marginTop: 2, width: "100%" }}
          direction={"row"}
          spacing={1}
        >
          <img src={RoomIcon} width={20} alt="Users" />
          {roomsForLocation && (
            <Box sx={{ flex: 1 }}>
              <Select
                variant="standard"
                labelId="label-currentRoom-select"
                id="currentRoom-select"
                value={currentRoom?.id ?? "none"}
                onChange={(event: any) => {
                  const fullRoom = roomsForLocation.find(
                    (room) => room.id === event.target.value
                  )
                  if (fullRoom) {
                    changeRoom(fullRoom)
                  }
                }}
              >
                {!isSession && (
                  <MenuItem key={"none"} value={"none"}>
                    None
                  </MenuItem>
                )}
                {roomsForLocation.map((room) => {
                  return (
                    <MenuItem key={room.id} value={room.id}>
                      {room.attributes.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          )}

          {!isRoomFormOpen && !isSession && (
            <Button
              size="small"
              startIcon={<img src={PlusIcon} alt="Add" width={20} />}
              onClick={() => {
                setIsRoomFormOpen(true)
              }}
            >
              Add Room
            </Button>
          )}
        </Stack>
      )}

      {isRoomFormOpen && (
        <Box>
          <Typography sx={{ marginTop: 5, fontWeight: "bold" }}>
            New Room
          </Typography>
          <Stack spacing={3} direction={"row"} sx={{ paddingBlock: 2 }}>
            <TextField
              onChange={(event) => {
                setNewRoomName(event.target.value)
              }}
              value={newRoomName}
              label="Name"
              variant="standard"
            />
            <Box sx={{ maxWidth: 100 }}>
              <TextField
                onChange={(event) => {
                  setNewRoomCapacity(Number(event.target.value))
                }}
                type="number"
                label="Max Capacity"
                variant="standard"
                value={newRoomCapacity}
              />
            </Box>
          </Stack>

          <TextField
            onChange={(event) => {
              setNewRoomInstructions(event.target.value)
            }}
            multiline
            value={newRoomInstructions}
            label="Entry Instructions"
            variant="standard"
          />

          <Stack
            spacing={3}
            direction={"row"}
            sx={{ justifyContent: "flex-end" }}
          >
            {!isSaving && (
              <>
                <Button
                  sx={{
                    "&.MuiButton-textPrimary": {
                      color: "#777",
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setIsRoomFormOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  disabled={!newRoomName}
                  onClick={() => {
                    setIsSaving(true)
                    dispatch(
                      addNewRoom(
                        {
                          attributes: {
                            field_room_number_name: newRoomName,
                            field_entry_instructions: newRoomInstructions,
                            field_capacity: newRoomCapacity,
                          },
                        },
                        location
                      )
                    ).then(() => {
                      setIsRoomFormOpen(false)
                      setIsSaving(false)
                      setNewRoomName("")
                      setNewRoomInstructions("")
                      setNewRoomCapacity(0)
                    })
                  }}
                >
                  Save Room
                </Button>
              </>
            )}
            {isSaving && <Loading message="Saving room..." />}
          </Stack>
        </Box>
      )}
    </Box>
  )
}
