import { Component } from "react"
import { connect } from "react-redux"
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import Autocomplete from "@mui/material/Autocomplete"
import ImageUpload from "../../components/ImageUpload"
import CourseSelect from "../courses/CourseSelect"
import CategoryIcon from "../../components/CategoryIcon"
import MandatedTrainingNavbarContainer from "./MandatedTrainingNavbarContainer"
import { fetchLearningPathTypes } from "../learningPathTypes/learningPathTypesSlice"
import DeleteButton from "../../components/DeleteButton"
import classNames from "classnames"
import { getSubGroups, getJobTitles } from "../../store/reducers/session"

import { isGroupLevel } from "../../functions"

import Loading from "@mobilemind/common/src/components/Loading"

import {
  fetchActiveMandatedTraining,
  updateField,
  removeImage,
  setFilename,
  setImage,
  addCourse,
  removeCourse,
  startNewMT,
  updateCadence,
  dropCourse,
} from "./activeMandatedTrainingSlice"

import "../../styles/create.scss"
import ErrorBoundary from "../../components/ErrorBoundary"

import { withRouter } from "react-router-dom"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"

const mapStateToProps = (
  { session, categories, activeMandatedTraining },
  ownProps
) => {
  return {
    trainingId: ownProps.match.params.id,
    session,
    subGroups: session.subGroups,
    activeTraining: activeMandatedTraining,
    categories,
  }
}

const mapDispatchToProps = {
  getSubGroups,
  getJobTitles,
  fetchActiveMandatedTraining,
  fetchLearningPathTypes,
  updateField,
  removeImage,
  setFilename,
  setImage,
  addCourse,
  removeCourse,
  startNewMT,
  dropCourse,
  updateCadence,
}

class CreateMandatedTrainingLayout extends Component {
  componentDidMount() {
    const { session, trainingId } = this.props
    const { subGroups, jobTitles } = session

    trainingId !== "new" &&
      this.props.fetchActiveMandatedTraining({ trainingId })
    trainingId === "new" && this.props.startNewMT()
    if (!subGroups.fetched) {
      this.props.getSubGroups(session.group.uuid[0].value)
    }
    if (!jobTitles.fetched) {
      this.props.getJobTitles()
    }
  }

  render() {
    const { session, activeTraining, categories } = this.props
    let { subGroups, jobTitles } = session

    if (!activeTraining.fetched) {
      return (
        <Loading fullPage={true} message={"Getting mandated training..."} />
      )
    }

    if (session.subgroups && session.subgroups.data && isGroupLevel(session)) {
      subGroups = session.subgroups
    } else {
      subGroups = session.subGroups
    }

    // Single group member
    let isSingleGroupMember =
      isGroupLevel(session) && subGroups.data.length === 1

    let ownerValue
    if (isGroupLevel(session)) {
      ownerValue = activeTraining.subGroupOwner
        ? activeTraining.subGroupOwner
        : subGroups.data[0] && subGroups.data[0].id
    } else {
      ownerValue = activeTraining.subGroupOwner
        ? activeTraining.subGroupOwner
        : "org"
      let canFindGroup = subGroups.data.find(
        (group) => group.id === activeTraining.subGroupOwner
      )

      if (!canFindGroup && ownerValue !== "org") {
        let byEntityId = subGroups.data.find(
          (group) => group.id === activeTraining.subGroupOwner
        )
        if (byEntityId) {
          ownerValue = byEntityId.id
        }
      }
    }

    /**
     * Checks if the current date is within the range of the active training's
     * open date and deadline date.
     * @returns {boolean} True if the current date is within the range, false otherwise.
     */
    const isInsideMandatedTrainingWindow =
      moment().isBetween(
        moment(activeTraining.openDate),
        moment(activeTraining.deadlineDate)
      ) && activeTraining.field_draft === false

    return (
      <ErrorBoundary>
        <div className="page create learningPath mandatedTraining">
          <MandatedTrainingNavbarContainer history={this.props.history} />
          <div className="flexRow">
            <div className="column left">
              <div
                className={classNames(
                  "required",
                  activeTraining.missingFields.includes("Name") && "active"
                )}
              >
                <TextField
                  variant="standard"
                  label="Name"
                  required
                  value={activeTraining.name ? activeTraining.name : ""}
                  inputProps={{ maxLength: 50 }}
                  helperText={"Maximum length is 50 characters."}
                  onChange={(event) =>
                    this.props.updateField({
                      field: "name",
                      value: event.target.value,
                    })
                  }
                />
              </div>
              <div
                className={classNames(
                  "required",
                  activeTraining.missingFields.includes("Image") && "active"
                )}
              >
                <h3>Training Image</h3>
                <ImageUpload
                  helperText={
                    "For best results, we recommend an image sized at least 860x200."
                  }
                  shape={"rectangle"}
                  activeImage={activeTraining.image}
                  setImage={setImage}
                  setFilename={setFilename}
                  removeImage={removeImage}
                />
              </div>
              {!activeTraining.field_draft && (
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={activeTraining.archived}
                      onChange={(event) => {
                        this.props.updateField({
                          field: "archived",
                          value: event.target.checked,
                        })
                      }}
                      name="archived"
                    />
                  }
                  label="Archived"
                />
              )}

              <div
                className={classNames(
                  "required",
                  activeTraining.missingFields.includes("Description") &&
                    "active"
                )}
              >
                <TextField
                  variant="standard"
                  label="Description"
                  required
                  helperText="Briefly describe what will be covered in the training."
                  multiline
                  value={
                    activeTraining.body
                      ? activeTraining.body.replace(/(<([^>]+)>)/gi, "")
                      : ""
                  }
                  onChange={(event) =>
                    this.props.updateField({
                      field: "body",
                      value: event.target.value,
                    })
                  }
                />
              </div>

              <div className="datepicker-form-control flexRow">
                <div style={{ marginRight: 35, flex: 1 }}>
                  <DatePicker
                    label="Open Date"
                    disabled={isInsideMandatedTrainingWindow}
                    minDate={moment()}
                    value={moment(activeTraining.openDate)}
                    onChange={(date) => {
                      this.props.updateField({
                        field: "openDate",
                        value: moment(date).format(),
                      })
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <DatePicker
                    label="Deadline Date"
                    minDate={moment()}
                    value={moment(activeTraining.deadlineDate)}
                    onChange={(date) => {
                      this.props.updateField({
                        field: "deadlineDate",
                        value: moment(date).format(),
                      })
                    }}
                  />
                </div>
              </div>

              {isInsideMandatedTrainingWindow && (
                <p
                  style={{
                    marginTop: -10,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "rgb(17 188 118)",
                    backgroundColor: "white",
                    borderRadius: 12,
                    padding: "10px 15px",
                    textAlign: "left",
                  }}
                >
                  Because the mandated training window is open, you won't be
                  able to edit the Open Date until after the Deadline Date
                  passes.{" "}
                </p>
              )}

              {subGroups.data.length > 0 && (
                <>
                  <h3>Training Owner</h3>

                  <FormControl
                    variant="standard"
                    className={classNames(
                      "inputSelect subGroupSelect",
                      (isSingleGroupMember || !isGroupLevel(session)) &&
                        "locked"
                    )}
                  >
                    <Select
                      variant="standard"
                      labelId="label-subgroup-owner-select"
                      id="subgroup-owner-select"
                      value={ownerValue}
                      onChange={(event) =>
                        this.props.updateField({
                          field: "subGroupOwner",
                          value: event.target.value,
                        })
                      }
                    >
                      {this.props.session.orgRoles.includes(
                        "organization-admin"
                      ) && (
                        <MenuItem key={0} value={"org"}>
                          Org - {this.props.session.group.label[0].value}
                        </MenuItem>
                      )}
                      {subGroups.data.map((group) => {
                        return (
                          <MenuItem key={group.id} value={group.id}>
                            {group.attributes.label}
                          </MenuItem>
                        )
                      })}
                    </Select>

                    <p
                      style={{
                        fontSize: "0.75rem",
                        color: "rgba(0, 0, 0, 0.54)",
                        margin: "10px 0 0",
                        lineHeight: "1.4",
                      }}
                    >
                      {ownerValue === "org" ? (
                        <>
                          Organization Admins and Creators will be able to edit
                          this training.
                        </>
                      ) : (
                        <>
                          Group Admins and Creators in{" "}
                          {isSingleGroupMember ? (
                            <>your group</>
                          ) : (
                            <>this group</>
                          )}{" "}
                          will be able to edit this training. Organization
                          Admins and Creators will also be able to edit.
                        </>
                      )}
                    </p>
                  </FormControl>
                </>
              )}

              {subGroups.data.length > 0 && (
                <>
                  <div
                    className={classNames(
                      "required",
                      activeTraining.missingFields.includes(
                        "Make visible to at least 1 group"
                      ) && "active"
                    )}
                  >
                    <h3>Group Visibility</h3>

                    <FormControl
                      variant="standard"
                      className={classNames(
                        "subGroupSelect",
                        isSingleGroupMember && "locked"
                      )}
                    >
                      <Autocomplete
                        multiple
                        id="subgroup-select"
                        options={
                          subGroups.data.length > 0 ? subGroups.data : []
                        }
                        value={
                          isSingleGroupMember
                            ? subGroups.data
                            : activeTraining.subGroups
                        }
                        getOptionLabel={(option) => option.attributes.label}
                        onChange={(event, values) =>
                          this.props.updateField({
                            field: "subGroups",
                            value: values,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              activeTraining.subGroups.length
                                ? "Add Groups"
                                : "All Groups"
                            }
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                    <p
                      style={{
                        fontSize: "0.75rem",
                        color: "rgba(0, 0, 0, 0.54)",
                        margin: isSingleGroupMember ? "-20px 0 0" : "-10px 0 0",
                        lineHeight: "1.4",
                      }}
                    >
                      Learners in{" "}
                      {isSingleGroupMember ? (
                        <>your group</>
                      ) : (
                        <>these groups</>
                      )}{" "}
                      will be required to complete this training in the Learn
                      app.
                    </p>
                  </div>
                </>
              )}

              {jobTitles.data.length > 0 && (
                <>
                  <h3>Job Title Visibility</h3>

                  <FormControl
                    variant="standard"
                    className={classNames("inputSelect subGroupSelect")}
                  >
                    <Autocomplete
                      multiple
                      id="subgroup-select"
                      options={jobTitles.data.length > 0 ? jobTitles.data : []}
                      value={activeTraining.jobTitles}
                      getOptionLabel={(option) => option.attributes.name}
                      onChange={(event, values) =>
                        this.props.updateField({
                          field: "jobTitles",
                          value: values,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            activeTraining.jobTitles.length
                              ? "Add Job Titles"
                              : "All Job Titles"
                          }
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </>
              )}

              {/* {activeTraining.estimatedTime > 0 && (
                <>
                  <h3>Estimated Time</h3>
                  <span className="icon time" />
                  <span className="count">
                    {convertEstimatedTime(activeTraining.estimatedTime)}
                  </span>
                </>
              )} */}
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    checked={activeTraining.field_no_prior_credit}
                    onChange={(event) => {
                      this.props.updateField({
                        field: "field_no_prior_credit",
                        value: event.target.checked,
                      })
                    }}
                    name="field_no_prior_credit"
                  />
                }
                label="Do Not Give Prior Credit"
              />
              <p
                style={{
                  fontSize: "0.75rem",
                  color: "rgba(0, 0, 0, 0.54)",
                  margin: "-20px 0 0",
                  lineHeight: "1.4",
                }}
              >
                If checked, a learner will have to complete all courses after
                the initial open date in order to receive credit.
              </p>

              <div
                className={classNames(
                  "required",
                  activeTraining.missingFields.includes(
                    "Include at least 1 course"
                  ) && "active"
                )}
              >
                <h3>Mandated Courses</h3>

                <DragDropContext
                  onDragEnd={(droppedItem) => {
                    this.props.dropCourse(droppedItem)
                  }}
                >
                  <Droppable droppableId={"course-list"}>
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="courseList"
                      >
                        {activeTraining.courses.length > 0 &&
                          activeTraining.courses.map((course, index) => {
                            let category
                            if (!course.category) {
                              category = categories.data.find(
                                (cat) =>
                                  course.relationships.field_category.data &&
                                  cat.id ===
                                    course.relationships.field_category.data.id
                              )
                            } else {
                              category = course.category
                            }
                            return (
                              <Draggable
                                key={course.id}
                                draggableId={course.id}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    className="column"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div
                                      className="flexRow"
                                      style={{
                                        marginBottom: 5,
                                        flex: 1,
                                        width: "100%",
                                      }}
                                    >
                                      <span className="icon hamburger" />
                                      {category && (
                                        <CategoryIcon category={category} />
                                      )}
                                      <span className="name">
                                        {course.attributes.name}
                                      </span>
                                      <DeleteButton
                                        onClick={() =>
                                          this.props.removeCourse(course)
                                        }
                                      />
                                    </div>
                                    <div
                                      className="flexRow"
                                      style={{ justifyContent: "flex-start" }}
                                    >
                                      To be completed every
                                      <TextField
                                        type={"number"}
                                        value={course.retakeCadence}
                                        onChange={(event) => {
                                          this.props.updateCadence({
                                            course,
                                            value: event.target.value,
                                          })
                                        }}
                                      />
                                      year(s)
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            )
                          })}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            <div className="column right">
              <CourseSelect
                coursesAdded={activeTraining.courses}
                addCourse={addCourse}
                hideMM={true}
                removeCourse={removeCourse}
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateMandatedTrainingLayout)
)
