import he from "he"
import React from "react"
import "./styles/backpack.scss"

import TimeCapsule from "../../components/TimeCapsule"
import moment from "moment"
import { UserEventsLearner } from "../../types/events"

interface RowProps {
  data: UserEventsLearner[]
}
const EventsPrintRow: React.FC<RowProps> = ({ data }) => {
  return (
    <div className="print-section">
      {data.map((event: UserEventsLearner, index: number) => {
        const totalMinutes = Number(event.field_credit.replace("min", ""))
        const hours =
          totalMinutes >= 60
            ? Math.floor(totalMinutes / 60).toLocaleString()
            : 0
        const minutes = totalMinutes % 60

        return (
          <div key={index} className="item event-item">
            <img
              className="icon calendar"
              src={
                process.env.REACT_APP_TEACHER_URL +
                "/static/media/calendar-black.ef86fd96b77dbe1d596e.svg"
              }
            />

            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <strong
                style={{
                  display: "block",
                  fontSize: 12,
                  lineHeight: 1.2,
                }}
              >
                {he.decode(event.name)}
              </strong>
              <span style={{ fontSize: 10, opacity: 0.6 }}>
                {moment(event.field_event_date_time_value).format(
                  "MMMM Do, YYYY"
                )}
              </span>
            </div>

            {(Number(hours) > 0 || minutes > 0) && (
              <TimeCapsule hours={Number(hours)} minutes={minutes} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default EventsPrintRow
