import {
  FormControl,
  FormControlProps,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  styled,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import classNames from "classnames"
import "../styles/categories.scss"
import { Categories, CategoryTaxonomy } from "../types/taxonomy/category"
import { CategoryIcon } from "./CategoryIcon"

type CategorySelectProps = Pick<
  FormControlProps,
  "margin" | "required" | "error"
> & {
  className?: string
  onChange: (arg: CategoryTaxonomy | null) => void
  currentValue: CategoryTaxonomy | null
  label?: string
  allowedLevels: number
  placeholder?: string
  hideOrgContent?: boolean
  hideParents?: boolean
  categories: Categories
}

export const CategorySelect = styled((props: CategorySelectProps) => {
  const {
    className,
    onChange,
    label,
    currentValue,
    placeholder,
    hideOrgContent,
    allowedLevels,
    categories,
    hideParents,
    margin,
    required,
    error,
  } = props

  let categoryTree: CategoryTaxonomy[] = []
  categories.topCategories.forEach((category: CategoryTaxonomy) => {
    categoryTree.push(category)

    const children = categories.subCategories.filter(
      (sub: CategoryTaxonomy) =>
        sub.relationships.parent.data[0].id === category.id
    )

    if (children) {
      children.forEach((sub: CategoryTaxonomy) => {
        categoryTree.push(sub)
        sub.children &&
          sub.children.forEach((child: CategoryTaxonomy) => {
            categoryTree.push(child)
          })
      })
    }
  })

  if (hideOrgContent) {
    categoryTree = categoryTree.filter(
      (cat: CategoryTaxonomy) => !cat.relationships.field_organization.data
    )
  }

  const parent =
    currentValue &&
    categories.data.find(
      (cat: CategoryTaxonomy) =>
        cat.id === currentValue.relationships.parent.data[0].id
    )
  const parentParent =
    parent &&
    categories.data.find(
      (cat: CategoryTaxonomy) =>
        cat.id === parent.relationships.parent.data[0].id
    )

  if (allowedLevels === 1) {
    categoryTree = categories.topCategories
  }

  return (
    <FormControl
      variant="standard"
      id="category-autocomplete"
      className={classNames(
        "categorySelectFormControl",
        currentValue && "hasValue",
        className
      )}
      margin={margin}
      required={required}
    >
      <Autocomplete
        id="categorySelect"
        options={categoryTree}
        clearOnBlur={true}
        value={currentValue}
        componentsProps={{
          popper: {
            placement: "bottom-start",
            sx: { width: "400px !important" },
          },
        }}
        renderInput={(params) => {
          return (
            <TextField
              variant="standard"
              {...params}
              placeholder={placeholder ? placeholder : "Start typing to search"}
              label={label}
              required={required}
              InputLabelProps={{ shrink: true, error }}
              InputProps={{
                ...params.InputProps,
                ...(currentValue?.image && {
                  startAdornment: (
                    <InputAdornment position="start">
                      <CategoryIcon
                        src={
                          process.env.REACT_APP_API_URL +
                          currentValue.image.attributes.uri.url
                        }
                      />
                    </InputAdornment>
                  ),
                }),
              }}
              margin="none"
              error={error}
            />
          )
        }}
        onChange={(event, newValue: CategoryTaxonomy | null) => {
          onChange(newValue)
        }}
        getOptionLabel={(category) =>
          category && category.attributes ? category.attributes.name : ""
        }
        renderOption={(props: object, option: CategoryTaxonomy) => {
          const isSecondLevel = option.isSecondLevel && !option.isThirdLevel
          const isThirdLevel = option.isThirdLevel
          const isTopLevel = option.isTopLevel && !option.isSecondLevel

          const shouldReturn =
            isTopLevel ||
            (isSecondLevel && allowedLevels >= 2) ||
            (isThirdLevel && allowedLevels > 2)

          if (shouldReturn) {
            return (
              <ListItem
                {...props}
                key={option.id}
                sx={(theme) => ({
                  gap: 2,
                  paddingLeft: option.isThirdLevel
                    ? `${theme.spacing(9)} !important`
                    : option.isSecondLevel
                    ? `${theme.spacing(6)} !important`
                    : `${theme.spacing(3)} !important`,
                  paddingRight: 3,
                })}
                dense={true}
              >
                <ListItemAvatar sx={{ minWidth: 0 }}>
                  {option.image && (
                    <CategoryIcon
                      src={
                        process.env.REACT_APP_API_URL +
                        option.image?.attributes.uri.url
                      }
                    />
                  )}
                </ListItemAvatar>
                <ListItemText>{option.attributes.name}</ListItemText>
              </ListItem>
            )
          }
          return null
        }}
      />
      {!hideParents && (
        <div className="category-breadcrumbs">
          {parentParent && (
            <span className="parent">{parentParent.attributes.name}</span>
          )}
          {parentParent && parent && <span className="caret" />}
          {parent && <span className="parent">{parent.attributes.name}</span>}
        </div>
      )}
    </FormControl>
  )
  // Provide an empty default stylesheet that can be overridden by parent component
})(() => ({}))

export default CategorySelect
