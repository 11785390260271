import { Component } from "react"
import theme from "../theme/theme"

class UserInitial extends Component {
  render() {
    const { size, initials, fontSize, containerStyle } = this.props
    let borderSize = size * 0.09

    if (size > 100) {
      borderSize = 5
    }

    return (
      <div style={containerStyle}>
        <div
          style={{
            boxSizing: "border-box",
            width: size,
            height: size,
            backgroundColor: theme.palette.secondary.main,
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: "100%",
            flexShrink: 0,
            border: borderSize + "px solid white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "normal",
              fontStyle: "normal",
              paddingTop: 2,
              fontSize: fontSize ? fontSize : "100%",
            }}
          >
            <span className="userInitials-initials">
              {initials.replace("null", "")}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default UserInitial
