import { styled } from "@mui/material"

type CategoryIconProps = {
  size?: "small" | "medium" | "large"
}

export const CategoryIcon = styled("img", {
  shouldForwardProp: (prop) => prop !== "size",
})<CategoryIconProps>(({ size = "medium" }) => ({
  ...(size === "small" && {
    width: 15,
    height: 15,
    borderRadius: 15,
  }),
  ...(size === "medium" && {
    width: 25,
    height: 25,
    borderRadius: 25,
  }),
  ...(size === "large" && {
    width: 50,
    height: 50,
    borderRaduius: 50,
  }),
  objectFit: "contain",
  display: "block",
  border: "3px solid white",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
}))
