import { useEffect, useState } from "react"
import { Dialog } from "@mui/material"
import { UserEventsLearner } from "../../types/events"
import { useDispatch } from "react-redux"
import Values from "values.js"
import theme from "../../theme/theme"
import he from "he"
import moment from "moment"
import { convertMinutesToHoursMinutes } from "../../functions"
import TimeCapsule from "../TimeCapsule"
import Loading from "../Loading"
import { getHQConferenceSessions } from "../../actions/backpack"

const primary = new Values(theme.palette.primary.main)

type ModalProps = {
  events: UserEventsLearner[]
  setModalConferenceId: (id: string) => void
  // eslint-disable-next-line
  getConferenceSessions?: (id: UserEventsLearner) => any
  conferenceId: string
  conference?: UserEventsLearner
  onClose?: () => void
  userId?: number
}

function ConferenceSessionsModal(props: ModalProps) {
  const {
    events,
    setModalConferenceId,
    conference,
    getConferenceSessions,
    conferenceId,
    onClose,
    userId,
  } = props

  let targetConference: UserEventsLearner | undefined = conference
    ? conference
    : events.find((event) => event.uuid === conferenceId)

  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const [sessionData, setSessionData] = useState([])
  const [HQTargetConference, setHQTargetConference] = useState(null)

  useEffect(() => {
    if (targetConference) {
      // eslint-disable-next-line
      // @ts-ignore
      setHQTargetConference(targetConference)
      setFetched(false)
      if (getConferenceSessions) {
        dispatch(getConferenceSessions(targetConference)).then(
          // eslint-disable-next-line
          (response: any) => {
            if (response && !response.payload.rows.content) {
              setSessionData(response.payload.rows)
            }
            setFetched(true)
          }
        )
      } else {
        dispatch(
          // eslint-disable-next-line
          // @ts-ignore
          getHQConferenceSessions({
            userId,
            conferenceId: targetConference.id_1,
          })
        ).then(
          // eslint-disable-next-line
          (response: any) => {
            if (response && !response.rows.content) {
              setSessionData(response.rows)
            }
            setFetched(true)
          }
        )
      }
    }
  }, [targetConference, dispatch])

  if (HQTargetConference) {
    targetConference = HQTargetConference
  }

  const isSameDayConference =
    targetConference &&
    moment(targetConference.field_event_date_time_value).format("MMMM Do") ===
      moment(targetConference.field_event_date_time_end_value).format("MMMM Do")

  let conferenceCredit: number =
    targetConference &&
    targetConference.field_award_credit_for_conferenc !== "Yes"
      ? Number(targetConference.field_credit)
      : 0

  if (!conferenceCredit) {
    conferenceCredit = 0
    sessionData.forEach((session: UserEventsLearner) => {
      conferenceCredit += Number(session.field_credit)
    })
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        if (onClose) {
          onClose()
        } else {
          setModalConferenceId("")
        }
      }}
      className="conferenceModal"
    >
      <div className="dialog">
        <div className="inner">
          <h2>
            {targetConference && (
              <>
                <div className="flexGrow">
                  <span
                    className="chip"
                    style={{ backgroundColor: primary.shade(15).hexString() }}
                  />
                  {he.decode(targetConference.name)}
                </div>

                <div
                  className="total"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {conferenceCredit > 0 && (
                    <>
                      <span style={{ paddingRight: 10 }}>Total PD Credit</span>
                      <TimeCapsule
                        hours={
                          convertMinutesToHoursMinutes(Number(conferenceCredit))
                            .hours
                        }
                        minutes={
                          convertMinutesToHoursMinutes(Number(conferenceCredit))
                            .minutes
                        }
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </h2>
          {targetConference && (
            <span className="datetime">
              {moment(targetConference.field_event_date_time_value).format(
                "MMMM Do, YYYY h:mm A"
              )}{" "}
              -{" "}
              {isSameDayConference ? (
                <>
                  {moment(
                    targetConference.field_event_date_time_end_value
                  ).format("h:mm A")}
                </>
              ) : (
                <>
                  {moment(
                    targetConference.field_event_date_time_end_value
                  ).format("MMMM Do, YYYY h:mm A")}
                </>
              )}
            </span>
          )}

          {fetched ? (
            <ul
              style={{
                marginTop: 10,
                borderTop: "1px solid #efefef",
              }}
            >
              {sessionData.map((session: UserEventsLearner) => {
                return (
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 10,
                      paddingLeft: 20,
                      borderBottom: "1px solid #efefef",
                    }}
                    key={session.id}
                  >
                    <span
                      className="chip"
                      style={{
                        backgroundColor: session.field_hex_color
                          ? session.field_hex_color
                          : primary.tint(50).hexString(),
                      }}
                    />

                    <span className="title">
                      {session.name}
                      <div
                        style={{
                          opacity: 0.6,
                          fontWeight: "bold",
                          fontSize: 12,
                        }}
                      >
                        {moment(session.field_event_date_time_value).format(
                          "MMMM Do, h:mma"
                        )}
                      </div>
                    </span>

                    {session.field_credit && (
                      <span className="credit">
                        <TimeCapsule
                          hours={
                            convertMinutesToHoursMinutes(
                              Number(session.field_credit)
                            ).hours
                          }
                          minutes={
                            convertMinutesToHoursMinutes(
                              Number(session.field_credit)
                            ).minutes
                          }
                        />
                      </span>
                    )}
                  </li>
                )
              })}
            </ul>
          ) : (
            <div
              style={{ padding: 20, display: "flex", justifyContent: "center" }}
            >
              <Loading message="Getting sessions..." />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default ConferenceSessionsModal
