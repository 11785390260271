import "../styles/Unauthorized.scss"

function Unauthorized() {
  return (
    <div className="page unauthorized">
      <div className="icon warning" />
      <div className="content">
        <h2>Uh oh!</h2>
        <p>
          It looks like you don&apos;t have permission to view this page. If
          this is a mistake, contact your organization to request access.
        </p>
      </div>
    </div>
  )
}

export default Unauthorized
