import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import parseDataUrl from "parse-data-url"
import { setUserBadges } from "./index"
import { getOrgSuccessChecklist } from "../store/reducers/session"
import he from "he"

/**
 * Get all available badge entities and include courses
 */
export function getBadges() {
  return async (dispatch, getState) => {
    const { session } = getState()
    let images, badges
    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value

    try {
      let response = await fetchWrapper.get(
        "/api/badges_entity/badges_entity?include=field_badge_image,field_course"
      )
      if (response.ok) {
        let data = await response.json()
        let pages = Math.ceil(Number(data.meta.count) / 50)

        badges = data.data
        images = data.included.filter(
          (included) => included.type === "file--image"
        )

        // Only include content from MM or the user's org (if they belong to one)
        let badgesByOrg = badges.filter(
          (badge) =>
            !badge.relationships.field_group.data ||
            badge.relationships.field_group.data.id === orgId
        )

        if (pages < 2) {
          dispatch({
            type: "badgesSlice/getBadges",
            payload: badgesByOrg,
            meta: {
              images,
              fetched: true,
            },
          })
        }

        dispatch({
          type: "activeUserSlice/getBadges",
          payload: badgesByOrg,
        })

        for (let i = 1; i < pages; i++) {
          try {
            let query = {
              page: {
                offset: i * 50,
              },
            }

            let response = await fetchWrapper.get(
              "/api/badges_entity/badges_entity?include=field_badge_image&" +
                qs.stringify(query)
            )

            if (response.ok) {
              let data = await response.json()
              let includedImages = data.included.filter(
                (included) => included.type === "file--image"
              )

              data.data.forEach((badge) => {
                badges.push(badge)
              })
              includedImages.forEach((image) => {
                images.push(image)
              })

              let badgesByOrg = badges.filter(
                (badge) =>
                  !badge.relationships.field_group.data ||
                  badge.relationships.field_group.data.id === orgId
              )

              dispatch({
                type: "badgesSlice/getBadges",
                payload: badgesByOrg,
                meta: {
                  images,
                  fetched: pages - 1 === i,
                },
              })
              dispatch({
                type: "activeUserSlice/getBadges",
                payload: badgesByOrg,
              })
            }
          } catch (err) {
            console.log(err)
          }
        }
      }

      return {
        badges,
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getOrgBadges(filters) {
  return async (dispatch, getState) => {
    const { session, entityList } = getState()

    let orgId = session.group && session.group.uuid && session.group.id[0].value
    const groupRoles = session.groupRoles
    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    filters = entityList.orgBadges.filters

    let query = {
      sort_by: filters.sortBy,
      sort_order: filters.sortOrder,
      search: entityList.orgBadges.filters.searchQuery,
    }

    if (!filters.status) {
      query.field_draft_value = 1
    }
    if (filters.status === 1) {
      query.field_draft_value = 0
    }

    if (filters.subGroup !== "any") {
      query.field_subgroup_target_id = filters.subGroup
    }
    if (filters.subGroup === "all") {
      query.field_subgroup_target_id = "0"
    }

    if (filters.jobTitle !== "any") {
      query.field_job_title_target_id = filters.jobTitle
    }

    if (filters.status === "archived") {
      query.field_archive_value = 1
    }
    if (filters.organization !== "any") {
      query.field_group_target_id =
        filters.organization &&
        filters.organization.attributes.drupal_internal__id
    }

    if (filters.category) {
      query.tid_raw = filters.category.attributes.drupal_internal__tid
    }

    query.page = entityList.orgBadges.currentPage

    try {
      let content = []
      let pages
      let fetched

      let url = "/api/badges_entity/badges_list/" + orgId

      if (session.isSiteAdmin) {
        url = "/api/badges_entity/mm_badges_list"
      } else {
        if (isGroupAdmin) {
          let groupIds =
            session.subgroups.data &&
            session.subgroups.data
              .map((group) => group.drupal_internal__id)
              .join("+")
          url =
            "/api/badges_entity/badges_list/" +
            (session.group && session.group.type[0].target_id === "partner"
              ? "partner/"
              : "") +
            orgId +
            "/" +
            groupIds
        } else {
          url =
            "/api/badges_entity/badges_list/" +
            (session.group && session.group.type[0].target_id === "partner"
              ? "partner/"
              : "") +
            orgId +
            "/all"
        }
      }

      let response = await fetchWrapper.get(url + "?" + qs.stringify(query))

      if (response.ok) {
        let data = await response.json()
        pages = data.pager.total_pages

        if (!data.rows.content) {
          data.rows.forEach((row) => {
            row.image = row.field_badge_image
            content.push(row)
          })
        }

        dispatch({
          type: "entityList/getOrgBadges",
          payload: content,
          meta: {
            pages,
            totalRecords: Number(data.pager.total_items),
            fetched,
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveBadge(activeBadge, isDraft, isSession) {
  return async (dispatch, getState) => {
    const { session, courses } = getState()
    const { groupRoles } = session
    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    const isDrafter =
      session.orgRoles &&
      ((!session.orgRoles.includes("organization-admin") &&
        session.orgRoles.includes("organization-drafter")) ||
        session.groupRoles.includes("group-drafter"))

    let courseData =
      activeBadge.courses &&
      activeBadge.courses.map((course) => {
        return {
          type: "course_entity--course_entity",
          id: course.id,
        }
      })

    let body = {
      data: {
        type: "badges_entity--badges_entity",
        attributes: {
          name: activeBadge.name,
          field_archive: activeBadge.archived,
        },
        relationships: {
          user_id: {
            data: {
              type: "user--user",
              id: session.user.id,
            },
          },
        },
      },
    }

    // Set subgroup owner if user is group level
    if (activeBadge.subGroupOwner !== "org" && isGroupAdmin) {
      body.data.relationships.field_subgroup_owner = {
        data: {
          type: "group--group",
          id: activeBadge.subGroupOwner
            ? activeBadge.subGroupOwner
            : session.subgroups.data[0].id,
        },
      }
    }

    if (activeBadge.courses) {
      body.data.relationships.field_course = {
        data: courseData,
      }
    }

    if (!isDrafter) {
      if (isDraft) {
        body.data.attributes.field_draft = true
      } else {
        body.data.attributes.field_draft = false
      }
    }

    // Only include the org if they are not a MM administrator
    if (
      session.roles &&
      !session.roles.find((role) => role.target_id === "administrator")
    ) {
      if (session.group.type && session.group.type[0].target_id === "partner") {
        body.data.relationships.field_partner = {
          data: [
            {
              type: "group--partner",
              id: session.group.uuid[0].value,
            },
          ],
        }
      } else {
        body.data.relationships.field_group = {
          data: [
            {
              type: "group--organization",
              id: session.group.uuid[0].value,
            },
          ],
        }
      }
    }

    if (activeBadge.subGroups.length) {
      body.data.relationships.field_subgroup = {
        data: [],
      }

      activeBadge.subGroups.forEach((group) => {
        body.data.relationships.field_subgroup.data.push({
          type: "group--group",
          id: group.id,
        })
      })
    } else if (isGroupAdmin && session.subgroups.data.length === 1) {
      body.data.relationships.field_subgroup = {
        data: [
          {
            type: "group--group",
            id: session.subgroups.data[0].id,
          },
        ],
      }
    } else {
      body.data.relationships.field_subgroup = {
        data: null,
      }
    }

    if (activeBadge.jobTitles.length) {
      body.data.relationships.field_job_title = {
        data: [],
      }

      activeBadge.jobTitles.forEach((jobTitle) => {
        body.data.relationships.field_job_title.data.push({
          type: "taxonomy_term--job_titles",
          id: jobTitle.id,
        })
      })
    } else {
      body.data.relationships.field_job_title = {
        data: null,
      }
    }

    if (activeBadge.users.length) {
      body.data.relationships.field_teacher = {
        data: [],
      }

      activeBadge.users.forEach((user) => {
        body.data.relationships.field_teacher.data.push({
          type: "user--user",
          id: user.id,
        })
      })
    } else {
      body.data.relationships.field_teacher = {
        data: null,
      }
    }

    if (activeBadge.id) {
      body.data.id = activeBadge.id
    }

    try {
      let response
      if (activeBadge.id) {
        response = await fetchWrapper.patch(
          "/api/badges_entity/badges_entity/" + activeBadge.id,
          session.token,
          JSON.stringify(body)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/badges_entity/badges_entity",
          session.token,
          JSON.stringify(body)
        )
      }
      if (response.ok) {
        let data = await response.json()
        let createdBadge = data.data

        createdBadge.image = activeBadge.image
        createdBadge.courses = courses.data.filter((course) =>
          createdBadge.relationships.field_course.data
            .map((course) => course.id)
            .includes(course.id)
        )
        let id = activeBadge.id ? activeBadge.id : createdBadge.id

        if (!isDraft) {
          data.data.attributes.field_draft = false
        }

        if (activeBadge.id) {
          dispatch({
            type: "entityList/updateBadge",
            payload: data.data,
          })
        } else {
          dispatch({
            type: "entityList/addCreatedBadge",
            payload: data.data,
          })
        }

        if (isSession) {
          dispatch({
            type: "activeConferenceSlice/addNewBadge",
            payload: data.data,
          })
        } else {
          dispatch({
            type: "activeEventSlice/addNewBadge",
            payload: data.data,
          })
        }

        // If they have uploaded a new image
        if (activeBadge.filename && !activeBadge.image.type) {
          let options = {
            credentials: "include",
            method: "POST",
            headers: new Headers({
              Accept: "application/vnd.api+json",
              "Content-Type": "application/octet-stream",
              "X-CSRF-Token": session.token,
              "Content-Disposition":
                'file; filename="' + he.encode(activeBadge.filename) + '"',
            }),
            body: parseDataUrl(activeBadge.image).toBuffer(),
          }
          await fetch(
            process.env.REACT_APP_API_URL +
              "/api/badges_entity/badges_entity/" +
              id +
              "/field_badge_image",
            options
          )
        }

        // Refetch org success checklist
        dispatch(getOrgSuccessChecklist())

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getUserBadges(userId) {
  return async (dispatch) => {
    try {
      let i = 0
      let pages = 1

      let query = {
        filter: {
          "field_teacher.id": userId,
        },
        page: {
          offset: i * 50,
        },
      }

      while (i < pages) {
        let response = await fetchWrapper.get(
          "/api/user_badge/user_badge?" +
            qs.stringify(query) +
            "&include=field_badge"
        )
        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(Number(data.meta.count) / 50)
          dispatch(setUserBadges(data, i === pages - 1))
        }
        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteBadge(badgeId) {
  return async (dispatch, getState) => {
    const { session } = getState()
    try {
      let response = await fetchWrapper.remove(
        "/api/badges_entity/badges_entity/" + badgeId,
        session.token
      )
      if (response.ok) {
        dispatch({
          type: "entityList/deleteBadge",
          payload: badgeId,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}
