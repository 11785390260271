import { api } from "@mobilemind/common/src/api"
import { Categories } from "@mobilemind/common/src/types/taxonomy/category"
import { Entity, PagedResponse } from "@mobilemind/common/src/types/utilities"
import { CourseFormValues } from "features/courseForm/types"
import {
  RawCourseData,
  transformCourseToFormValues,
} from "features/courseForm/utility"
import qs from "qs"

type CommunityCourse = {
  id: string
  name: string
  created: string
  cat_name: string
  field_creator_org: string
  org_state: string
  course_content: string
  course_objective: string
  field_course_estimated_time: string
  number_used: string
  number_completed: string
  field_credit_organization: unknown
  category_image: string
}

const communityCourseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCommunityCourse: builder.query<PagedResponse<CommunityCourse>, string>({
      query: (search) => ({
        url: "/api/community_courses?" + qs.stringify({ search }),
        method: "GET",
      }),
    }),
    getCommunityCourseById: builder.query<
      CourseFormValues,
      { id: string; categories: Categories }
    >({
      query: ({ id }) => ({
        url:
          "/api/course_template/course_template?" +
          qs.stringify({
            filter: { drupal_internal__id: id },
            include: ["field_mul", "field_category", "field_quiz"].join(","),
          }),
        method: "GET",
      }),
      transformResponse: (
        response: {
          data: RawCourseData[]
          included: Entity[]
        },
        meta,
        { categories }
      ) => {
        const {
          data: [data],
          included,
        } = response
        return transformCourseToFormValues(data, { included, categories })
      },
    }),
  }),
})

export const { useGetCommunityCourseQuery, useGetCommunityCourseByIdQuery } =
  communityCourseApi
