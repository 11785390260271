import { debounce } from "lodash"
import type { Dispatch } from "redux"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (actionCreator: any, delay: number) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let lastArgs: any[]

  const thunk = (dispatch: Dispatch) => {
    dispatch(actionCreator(...lastArgs))
  }

  const debounced = debounce(thunk, delay)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    lastArgs = args.slice()
    return debounced
  }
}
