import { useState } from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import Dropzone from "react-dropzone"

function FileUpload(props) {
  const dispatch = useDispatch()

  const [isDragging, setIsDragging] = useState(0)
  const [fileSizeExceeded, setFileSizeExceeded] = useState(0)
  const [isRejected, setIsRejected] = useState(0)

  function onDrop(file) {
    let uploaded = file[0] ? file[0] : file.target.files[0]
    setIsDragging(false)
    if (uploaded) {
      // Limit to 50MB
      if (uploaded.size <= 50000000) {
        setFileSizeExceeded(0)
        setIsRejected(0)

        // Read the file
        let reader = new FileReader()
        reader.readAsDataURL(uploaded)

        // Once we're done, get the file and name it
        reader.onloadend = async (event) => {
          let filename = uploaded.name
          if (filename) {
            dispatch(
              props.addFile({
                file: event.target.result,
                filename,
                extension: filename.split(".").pop(),
              })
            )
          }
        }
      } else {
        setFileSizeExceeded(1)
        setIsRejected(1)
      }
    }
  }

  return (
    <div
      className={classNames(
        "uploadImageWrapper uploadFileWrapper",
        props.disabled && "disabled",
        isDragging && "isDragging"
      )}
    >
      <Dropzone
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDrop={onDrop.bind(this)}
      >
        {({ getRootProps }) => (
          <div
            {...getRootProps()}
            className={classNames("fileUpload", { isRejected: isRejected })}
          >
            <input onChange={onDrop.bind(this)} type="file" />
            <div className="instructions">
              <strong>Click to Upload or Drag and Drop</strong>
              <strong>50MB limit</strong>
              <span style={{ fontWeight: "normal" }}>
                png, jpg, jpeg, doc, docx, ppt, pptx, xlsx, xlx, csv, txt, pdf
              </span>
            </div>
          </div>
        )}
      </Dropzone>
      {isRejected > 0 && (
        <div className={classNames("rejection")}>
          {fileSizeExceeded > 0 && (
            <strong>
              Uh oh! This file is too large. Try again with an image smaller
              than 50MB.{" "}
            </strong>
          )}
        </div>
      )}
    </div>
  )
}

export default FileUpload
