import moment from "moment"
import React from "react"
import { UserRubricHistoryItem } from "../../types/rubrics"

interface RowProps {
  data: UserRubricHistoryItem[]
}

const UserRubricPrintRow: React.FC<RowProps> = ({ data }) => {
  return (
    <div className="print-section">
      {data.map((rubric: UserRubricHistoryItem, index: number) => {
        return (
          <div key={index} className="item rubric-item">
            <img
              className="icon rubric-black"
              src={
                process.env.REACT_APP_TEACHER_URL +
                "/static/media/pencil-ruler-black.fa185e914436df2ede8b.svg"
              }
            />

            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <strong className="title">{rubric.name}</strong>
              <span style={{ opacity: 0.6, fontSize: 10 }}>
                {moment(rubric.field_completed_date).format("MMMM Do, YYYY")}
              </span>
            </div>
            {Number(rubric.total_score) > 0 && (
              <div className="total-score-print">{rubric.total_score}</div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default UserRubricPrintRow
