import { Button } from "@mui/material"
import classNames from "classnames"
import theme from "../theme/theme"

function ButtonSmall(props) {
  return (
    <Button
      disabled={Boolean(props.disabled)}
      onClick={props.onClick}
      style={{
        color: theme.palette.primary.main,
        opacity: props.disabled ? 0.5 : 1,
      }}
      className={classNames("small button", props.classes)}
    >
      {props.children}
    </Button>
  )
}

export default ButtonSmall
