import { Component } from "react"
import PropTypes from "prop-types"

class Rating extends Component {
  render() {
    const { level } = this.props
    return (
      <>
        <span className="icon star"></span>
        {level === "intermediate" && <span className="icon star"></span>}
        {level === "advanced" && (
          <>
            <span className="icon star"></span>
            <span className="icon star"></span>
          </>
        )}
      </>
    )
  }
}

Rating.propTypes = {
  level: PropTypes.string,
}

export default Rating
