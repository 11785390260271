import theme from "../theme/theme"

function Facebook() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="facebook" fill={theme.palette.primary.main} fillRule="nonzero">
          <path
            d="M18.8959961,-2.26230853e-08 L1.10302734,-2.26230853e-08 C0.493774406,0.0003662125 -2.26230853e-08,0.494506844 -2.26230853e-08,1.10400391 L-2.26230853e-08,18.8969727 C0.0003662125,19.5062256 0.494506844,20 1.10400391,20 L10.6835938,20 L10.6835938,12.265625 L8.0859375,12.265625 L8.0859375,9.23828124 L10.6835938,9.23828124 L10.6835938,7.01037596 C10.6835938,4.42675782 12.2608643,3.02050782 14.5655518,3.02050782 C15.6694336,3.02050782 16.618042,3.10278318 16.8945312,3.13952638 L16.8945312,5.83984376 L15.3052978,5.83984376 C14.0513916,5.83984376 13.8085938,6.43566894 13.8085938,7.31018066 L13.8085938,9.23828124 L16.8066406,9.23828124 L16.4160156,12.265625 L13.8085938,12.265625 L13.8085938,20 L18.8959961,20 C19.5056152,20 20,19.5061035 20,18.8964844 C20,18.8963623 20,18.8962402 20,18.8959961 L20,1.10302734 C19.9997559,0.493774406 19.5054932,-2.26230853e-08 18.8959961,-2.26230853e-08 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Facebook
