import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import parseDataUrl from "parse-data-url"
import { getOrgSuccessChecklist } from "../store/reducers/session"
import he from "he"

export function getOrgLearningPaths() {
  return async (dispatch, getState) => {
    const { session, entityList } = getState()

    const filters = entityList.orgLearningPaths.filters

    let orgId = session.group.uuid && session.group.id[0].value
    const groupRoles = session.groupRoles
    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    let query = {
      sort_by: filters.sortBy,
      sort_order: filters.sortOrder,
      search: filters.searchQuery,
    }

    if (!filters.status) {
      query.field_draft_value = 1
    }
    if (filters.status === 1) {
      query.field_learning_path_archive_value = 0
      query.field_draft_value = 0
    }
    if (filters.subGroup !== "any") {
      query.field_subgroup_target_id = filters.subGroup
    }
    if (filters.subGroup === "all") {
      query.field_subgroup_target_id = "0"
    }
    if (filters.jobTitle !== "any") {
      query.field_job_title_target_id = filters.jobTitle
    }

    if (filters.status === "archived") {
      query.field_learning_path_archive_value = 1
    }

    if (filters.pathType !== "any") {
      query.field_type_target_id = filters.pathType
    }
    if (filters.organization !== "any") {
      query.field_organization_target_id =
        filters.organization &&
        filters.organization.attributes.drupal_internal__id
    }

    try {
      let content = []
      query.page = entityList.orgLearningPaths.currentPage

      let url
      if (session.isSiteAdmin) {
        url = "/api/learning_path/mm_learning_path_list"
      } else {
        if (isGroupAdmin) {
          let groupIds =
            session.subgroups.data &&
            session.subgroups.data
              .map((group) => group.drupal_internal__id)
              .join("+")
          url =
            "/api/learning_path/learning_path_list/" +
            (session.group.type && session.group.type[0].target_id === "partner"
              ? "partner/"
              : "") +
            orgId +
            "/" +
            groupIds
        } else {
          url =
            "/api/learning_path/learning_path_list/" +
            (session.group.type && session.group.type[0].target_id === "partner"
              ? "partner/"
              : "") +
            orgId +
            "/all"
        }
      }

      let response = await fetchWrapper.get(url + "?" + qs.stringify(query))
      if (response.ok) {
        let data = await response.json()

        if (!data.rows.content) {
          data.rows.forEach((row) => {
            row.image = row.field_image
            content.push(row)
          })
        }

        dispatch({
          type: "entityList/getOrgLearningPaths",
          payload: content,
          meta: {
            pages: data.pager.total_pages,
            totalRecords: Number(data.pager.total_items),
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveLearningPath(activePath, isDraft) {
  return async (dispatch, getState) => {
    const { session, learningPathTypes } = getState()
    const isDrafter =
      session.orgRoles &&
      !session.orgRoles.includes("organization-admin") &&
      (session.orgRoles.includes("organization-drafter") ||
        session.groupRoles.includes("group-drafter"))
    const { groupRoles } = session
    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    let courseData = activePath.courses.map((course) => {
      return {
        type: "course_entity--course_entity",
        id: course.id,
      }
    })

    let body = {
      data: {
        type: "learning_path--learning_path",
        attributes: {
          name: activePath.name,
          field_description: activePath.body,
          field_learning_path_archive: activePath.archived,
          field_learning_path_video: activePath.videoLink,
        },
        relationships: {
          field_type: {
            data: {
              type: "taxonomy_term--learning_path_types",
              id: activePath.type
                ? activePath.type
                : learningPathTypes.data[0].id,
            },
          },
          user_id: {
            data: {
              type: "user--user",
              id: session.user.id,
            },
          },
          field_courses: {
            data: courseData,
          },
        },
      },
    }

    // Set subgroup owner if user is group level
    if (activePath.subGroupOwner !== "org" && isGroupAdmin) {
      body.data.relationships.field_subgroup_owner = {
        data: {
          type: "group--group",
          id: activePath.subGroupOwner
            ? activePath.subGroupOwner
            : session.subgroups.data[0].id,
        },
      }
    }

    // Only include the org if they are not a MM administrator
    if (!session.roles.find((role) => role.target_id === "administrator")) {
      if (session.group.type && session.group.type[0].target_id === "partner") {
        body.data.relationships.field_partner = {
          data: [
            {
              type: "group--partner",
              id: session.group.uuid[0].value,
            },
          ],
        }
      } else {
        body.data.relationships.field_organization = {
          data: [
            {
              type: "group--organization",
              id: session.group.uuid[0].value,
            },
          ],
        }
      }
    }

    if (!isDrafter) {
      body.data.attributes.field_draft = isDraft ? true : false
    }

    if (activePath.id) {
      body.data.id = activePath.id
    }
    if (
      activePath.prerequisitePath &&
      activePath.prerequisitePath.field_lp_uuid
    ) {
      body.data.relationships.field_prerequisite_learning_path = {
        data: {
          type: "learning_path--learning_path",
          id: activePath.prerequisitePath.field_lp_uuid,
        },
      }
    } else {
      body.data.relationships.field_prerequisite_learning_path = {
        data: null,
      }
    }

    if (activePath.subGroups.length) {
      body.data.relationships.field_subgroup = {
        data: [],
      }

      activePath.subGroups.forEach((group) => {
        body.data.relationships.field_subgroup.data.push({
          type: "group--group",
          id: group.id,
        })
      })
    } else if (isGroupAdmin && session.subgroups.data.length === 1) {
      body.data.relationships.field_subgroup = {
        data: [
          {
            type: "group--group",
            id: session.subgroups.data[0].id,
          },
        ],
      }
    } else {
      body.data.relationships.field_subgroup = {
        data: null,
      }
    }

    if (activePath.jobTitles.length) {
      body.data.relationships.field_job_title = {
        data: [],
      }

      activePath.jobTitles.forEach((jobTitle) => {
        body.data.relationships.field_job_title.data.push({
          type: "taxonomy_term--job_titles",
          id: jobTitle.id,
        })
      })
    } else {
      body.data.relationships.field_job_title = {
        data: null,
      }
    }

    if (activePath.users.length) {
      body.data.relationships.field_teacher = {
        data: [],
      }

      activePath.users.forEach((user) => {
        body.data.relationships.field_teacher.data.push({
          type: "user--user",
          id: user.id,
        })
      })
    } else {
      body.data.relationships.field_teacher = {
        data: null,
      }
    }

    try {
      let response
      if (activePath.id) {
        response = await fetchWrapper.patch(
          "/api/learning_path/learning_path/" + activePath.id,
          session.token,
          JSON.stringify(body)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/learning_path/learning_path/",
          session.token,
          JSON.stringify(body)
        )
      }
      if (response.ok) {
        let data = await response.json()
        let createdPath = data.data
        createdPath.image = activePath.image
        let id = activePath.id ? activePath.id : createdPath.id

        // Refetch org success checklist
        dispatch(getOrgSuccessChecklist())

        if (activePath.id) {
          dispatch({
            type: "entityList/updateLearningPath",
            payload: data.data,
          })
        } else {
          dispatch({
            type: "entityList/addCreatedLearningPath",
            payload: data.data,
          })
        }

        dispatch({
          type: "activeLearningPathSlice/startNewLearningPath",
          payload: data.data,
        })

        // If they have uploaded a new image
        if (activePath.filename) {
          let options = {
            credentials: "include",
            method: "POST",
            headers: new Headers({
              Accept: "application/vnd.api+json",
              "Content-Type": "application/octet-stream",
              "X-CSRF-Token": session.token,
              "Content-Disposition":
                'file; filename="' + he.encode(activePath.filename) + '"',
            }),
            body: parseDataUrl(activePath.image).toBuffer(),
          }
          await fetch(
            process.env.REACT_APP_API_URL +
              "/api/learning_path/learning_path/" +
              id +
              "/field_image",
            options
          )
        }

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteLearningPath(pathId) {
  return async (dispatch, getState) => {
    const { session } = getState()
    try {
      let response = await fetchWrapper.remove(
        "/api/learning_path/learning_path/" + pathId,
        session.token
      )
      if (response.ok) {
        dispatch({
          type: "entityList/deleteLearningPath",
          payload: pathId,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}
