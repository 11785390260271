import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import moment from "moment"

export function createImportedSessionBase(args) {
  const { sessionToImport, startTime, targetRoomUUID, selectedLocation } = args

  return async (dispatch, getState) => {
    const { session, activeConference } = getState()

    // fetch the full session
    let originalAddOn, fetchedSession
    let originalResponse = await fetchWrapper.get(
      "/api/mobilemind_event_entity/conference_event/" +
        sessionToImport.uuid +
        "?include=field_addon"
    )
    if (originalResponse.ok) {
      const responseBody = await originalResponse.json()
      originalAddOn = responseBody.included && responseBody.included[0]
      fetchedSession = responseBody.data
    }

    const {
      field_attachment,
      field_event_image,
      field_organization,
      field_event_category,
    } = fetchedSession.relationships

    const {
      field_description,
      field_event_image_path,
      field_event_notes,
      field_helpful_links,
      field_hex_color,
      field_contact_info,
      field_type,
      name,
    } = fetchedSession.attributes

    let importedSessionBody = {
      data: {
        type: "mobilemind_event_entity--conference_event",
        attributes: {
          name: name,
          field_type,
          field_description: field_description.value,
          field_contact_info: field_contact_info?.value,
          field_helpful_links: field_helpful_links?.value,
          field_draft: true,
          field_editor_lock_time: null,
          field_event_date_time: [
            {
              value: moment(startTime).format(),
              end_value: moment(startTime).add(1, "hour").format(),
            },
          ],
          field_registration_start: null,
          field_registration_end: moment(startTime).format(),
          field_event_image_path,
          field_event_notes: field_event_notes?.value,
          field_hex_color,
        },
        relationships: {
          field_organization: {
            data: {
              type: field_organization.data.type,
              id: field_organization.data.id,
            },
          },
          field_parent_conference: {
            data: {
              type: "mobilemind_event_entity--conference",
              id: activeConference.id,
            },
          },
        },
      },
    }

    // Only attach the location, room, and type if the conference is not Virtual Live
    if (targetRoomUUID && targetRoomUUID !== "none") {
      importedSessionBody.data.relationships.field_location = {
        data: {
          type: "mobile_mind_location--location_base",
          id: activeConference.currentLocation?.id ?? selectedLocation,
        },
      }

      importedSessionBody.data.relationships.field_room = {
        data: {
          type: "mobile_mind_room--room_base",
          id: targetRoomUUID ?? activeConference.availableRooms[0].id,
        },
      }
    } else {
      // Set the type to virtual live
      importedSessionBody.data.attributes.field_type = "Virtual Live"
    }
    // Add attachment if it exists
    if (field_attachment && field_attachment.data) {
      importedSessionBody.data.relationships.field_attachment = {
        data: field_attachment.data.map((attachment) => {
          return {
            id: attachment.id,
            type: attachment.type,
          }
        }),
      }
    }

    // Add image if it exists
    if (field_event_image && field_event_image.data) {
      importedSessionBody.data.relationships.field_event_image = {
        data: {
          id: field_event_image.data.id,
          type: field_event_image.data.type,
        },
      }
    }

    // Add category if it exists
    if (field_event_category && field_event_category.data) {
      importedSessionBody.data.relationships.field_event_category = {
        data: {
          id: field_event_category.data.id,
          type: field_event_category.data.type,
        },
      }
    }

    try {
      const importResponse = await fetchWrapper.post(
        "/api/mobilemind_event_entity/conference_event",
        session.token,
        JSON.stringify(importedSessionBody)
      )

      if (importResponse.ok) {
        const importedSession = await importResponse.json()

        // Alright, this is still a draft, but we've gotta add it to the conference so
        const conferenceFetch = await fetchWrapper.get(
          "/api/mobilemind_event_entity/conference/" +
            activeConference.id +
            "?include=field_event"
        )

        if (conferenceFetch.ok) {
          const conferenceFetchData = await conferenceFetch.json()
          let field_event = []

          if (conferenceFetchData.included) {
            field_event = conferenceFetchData.included.map((event) => {
              return {
                id: event.id,
                type: event.type,
              }
            })
          }

          field_event.push({
            id: importedSession.data.id,
            type: importedSession.data.type,
          })

          let conferenceBody = {
            data: {
              type: "mobilemind_event_entity--conference",
              id: activeConference.id,
              relationships: {
                field_event: {
                  data: field_event,
                },
              },
            },
          }

          await fetchWrapper.patch(
            "/api/mobilemind_event_entity/conference/" + activeConference.id,
            session.token,
            JSON.stringify(conferenceBody)
          )
        }

        return {
          importedSession: importedSession.data,
          originalAddOn,
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function createAttachImportedSessionAddOn(args) {
  const { importedSession, startTime, originalAddOn, conferenceAddOn } = args

  return async (dispatch, getState) => {
    const { session } = getState()

    const {
      field_replacement_courses,
      field_prereq_learning_path,
      field_prereq_courses,
      field_feedback_form,
    } = originalAddOn.relationships

    const {
      field_anonymous_feedback,
      field_feedback_form_url,
      field_feedback_required_for_cred,
      field_attendance_method,
      field_level,
    } = originalAddOn.attributes

    let importedSessionAddOnBody = {
      data: {
        type: "mobilemind_event_addon--event_addon_base",
        attributes: {
          name: (importedSession.attributes.name + " AddOn").substring(0, 50),
          field_participants_type: "all",
          field_level,
          field_credit: 60,
          field_anonymous_feedback,
          field_feedback_form_url,
          field_feedback_required_for_cred,
          field_attendance_method,
        },
        relationships: {
          field_event: {
            id: importedSession.id,
            type: "mobilemind_event_entity--conference_event",
          },
          field_replacement_courses,
          field_prereq_learning_path,
          field_prereq_courses,
          field_feedback_form,
          // Use the participants from the CONFERENCE's addon
          // NOT the original session's addon, which likely came from that older conference
          field_participants_group:
            conferenceAddOn.data.relationships.field_participants_group,
          field_participants_job_title:
            conferenceAddOn.data.relationships.field_participants_job_title,
          field_participants_organization:
            conferenceAddOn.data.relationships.field_participants_organization,
          field_participants_users:
            conferenceAddOn.data.relationships.field_participants_users,
        },
      },
    }

    try {
      const importAddOnResponse = await fetchWrapper.post(
        "/api/mobilemind_event_addon/event_addon_base",
        session.token,
        JSON.stringify(importedSessionAddOnBody)
      )
      if (importAddOnResponse.ok) {
        const importAddOn = await importAddOnResponse.json()

        let patchedSession = {
          data: {
            id: importedSession.id,
            type: "mobilemind_event_entity--conference_event",
            relationships: {
              field_addon: {
                data: {
                  type: "mobilemind_event_addon--event_addon_base",
                  id: importAddOn.data.id,
                },
              },
            },
          },
        }

        let patchedSessionResponse = await fetchWrapper.patch(
          "/api/mobilemind_event_entity/conference_event/" + importedSession.id,
          session.token,
          JSON.stringify(patchedSession)
        )

        if (patchedSessionResponse.ok) {
          let logId
          if (
            importAddOn.data.attributes.field_attendance_method === "join_code"
          ) {
            logId = importAddOn.data.relationships.field_attendance_log.data.id

            let checkInStart = moment(startTime).format()
            let checkInEnd = moment(startTime).add(1, "hour").format()

            let logBody = {
              data: {
                type: "mm_attendance_log--attendance_log_base",
                id: logId,
                attributes: {
                  field_check_in_period: {
                    value: checkInStart,
                    end_value: checkInEnd,
                  },
                },
              },
            }

            // We'll also update the attendance log if they set a join code to set the check in date range
            const logURL = "/api/mm_attendance_log/attendance_log_base/" + logId
            fetchWrapper.patch(logURL, session.token, JSON.stringify(logBody))
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}
