import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import classNames from "classnames"

import Loading from "../Loading"
import he from "he"

import BackpackProps from "../../types/backpack"

import { SlideItem } from "./ContentRow"
import theme from "../../theme/theme"

import moment from "moment"
import { MandatedTrainingHistory } from "../../types/mandatedTraining"
import CertificateMenu from "./certificate/CertificateMenu"
import { generateTimestamp } from "../../functions"

interface RowProps {
  // fix with view, need to set up UserRubric type
  data: MandatedTrainingHistory[]
  backpack: BackpackProps
  goTo?: (location: string) => void
  increaseMandatedTrainingPage: (arg: number) => void
  setMandatedTrainingCarousel: (arg: number) => void
  userId?: number
  // Need to type this someday
  // eslint-disable-next-line
  session: any
}

export const MandatedTrainingRow = (props: RowProps) => {
  const {
    increaseMandatedTrainingPage,
    setMandatedTrainingCarousel,
    backpack,
    data,
    goTo,
  } = props

  const [chunkSize, setChunkSize] = useState(5)

  const dispatch = useDispatch()

  function onResize() {
    if (window.innerWidth < 1275) {
      setChunkSize(3)
    } else if (window.innerWidth < 1600) {
      setChunkSize(4)
    } else {
      setChunkSize(5)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const chunkedData = []

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize)
    chunkedData.push(chunk)
  }

  function nextSlideChanged(event: SlideItem) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setMandatedTrainingCarousel(
        event.item > backpack.mandatedTraining.activeIndex ? 1 : -1
      )
    )
  }

  function nextSlide(event: SlideItem) {
    if (event.itemsInSlide - 2 === event.item) {
      if (
        backpack.mandatedTraining.currentPage <
        backpack.mandatedTraining.totalPages - 1
      ) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          increaseMandatedTrainingPage(event.item)
        )
      }
    }
  }

  const items = chunkedData.map(
    (chunk: MandatedTrainingHistory[], index: number) => {
      return (
        <div key={index} className="chunk">
          {chunk.map((training: MandatedTrainingHistory, index: number) => {
            type styleProps = {
              backgroundColor: string
              backgroundImage?: string
              backgroundSize?: string
            }

            const style: styleProps = {
              backgroundColor: theme.palette.primary.main,
            }

            if (training.field_image) {
              style.backgroundImage =
                "url(" +
                process.env.REACT_APP_API_URL +
                training.field_image +
                ")"
              style.backgroundSize = "cover"
            }

            return (
              <div key={index} className="item event-item">
                <div
                  onClick={() => {
                    if (goTo) {
                      goTo("/mandated-training/" + training.rcs_id)
                    }
                  }}
                >
                  <div className="event-background" style={style} />

                  <div style={{ marginTop: 15, textAlign: "center" }}>
                    <span
                      style={{
                        fontSize: 12,
                        marginTop: -5,
                        marginBottom: 5,
                        fontWeight: "bold",
                        opacity: 0.6,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Completed{" "}
                      {training.field_comp_date &&
                        generateTimestamp(
                          moment(training.field_comp_date).format("MM/DD/YYYY")
                        )}
                    </span>
                    <strong
                      style={{
                        display: "block",
                        fontSize: 14,
                        lineHeight: 1.2,
                      }}
                    >
                      <>{he.decode(training.rcs_title)}</>
                    </strong>
                  </div>
                  <footer
                    className="flexRow"
                    style={{ justifyContent: "center", marginTop: 10 }}
                  ></footer>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CertificateMenu
                    userId={props.userId}
                    userData={props.backpack.userData}
                    user={props.session.user}
                    label={""}
                    hideShare={true}
                    training={training}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  )

  return (
    <div
      className={classNames(
        "inner inner-carousel",
        !backpack.mandatedTraining.activeIndex && "disablePrev",
        chunkedData.length - 1 === backpack.mandatedTraining.activeIndex &&
          "disableNext"
      )}
    >
      {backpack.mandatedTraining.isFetching && <Loading />}

      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AliceCarousel
          infinite={true}
          activeIndex={backpack.mandatedTraining.activeIndex}
          disableDotsControls={chunkedData.length === 1}
          paddingRight={25}
          paddingLeft={25}
          autoWidth={true}
          items={items}
          onSlideChange={(event) => nextSlide(event)}
          onSlideChanged={(event) => nextSlideChanged(event)}
        />
      }
    </div>
  )
}
