import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { WithContext as ReactTags } from "react-tag-input"
import { getTags } from "../actions/course"
import Loading from "./Loading"
import "../styles/tags.scss"
import classNames from "classnames"
import TagTaxonomy from "../types/tags"

type TagProps = {
  id: string
  text?: string
  uuid?: string
}

type TagSelectProps = {
  tags: {
    data: TagTaxonomy[]
    fetched: boolean
  }
  placeholder?: string
  allowNewTags: boolean
  selectedTags: TagProps[]
  handleInputChange?: () => void
  addTag: (tag: TagProps) => void
  deleteTag: (i: number) => void
}

function SidebarCreateCourse(props: TagSelectProps) {
  const {
    tags,
    placeholder,
    selectedTags,
    handleInputChange,
    allowNewTags,
    addTag,
    deleteTag,
  } = props
  const delimiters: number[] = allowNewTags ? [13] : []

  const dispatch = useDispatch()

  useEffect(() => {
    if (!tags.fetched) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getTags({ isLearn: false })
      )
    }
  }, [tags.fetched, dispatch])

  const suggestions = tags.data.map((tag: TagTaxonomy) => {
    return {
      className: "tagSuggestion",
      id: tag.id,
      text: tag.attributes.name,
      uuid: tag.id,
    }
  })

  return (
    <div
      className={classNames(
        "tagSelectWrapper",
        selectedTags.length && "hasTags"
      )}
    >
      {/**
       * eslint-disable-next-line
       @ts-ignore */}
      <ReactTags
        // eslint-disable-next-line
        // @ts-ignore
        tags={selectedTags}
        autofocus={false}
        classNames={{
          // eslint-disable-next-line
          // @ts-ignore
          tagInput: "tagInput",
          // eslint-disable-next-line
          // @ts-ignore
          selectedTag: "selected",
          // eslint-disable-next-line
          // @ts-ignore
          root: "tag",
          // eslint-disable-next-line
          // @ts-ignore
          remove: "remove",
          // eslint-disable-next-line
          // @ts-ignore
          suggestions: "suggestions",
          // eslint-disable-next-line
          // @ts-ignore
          suggestionActive: "activeSuggestion",
        }}
        placeholder={placeholder ? placeholder : "Press Enter to add a new tag"}
        allowDeleteFromEmptyInput={false}
        allowDragDrop={false}
        handleInputChange={handleInputChange}
        suggestions={suggestions}
        delimiters={delimiters}
        handleDelete={(index: number) => {
          deleteTag(index)
        }}
        handleAddition={(tag: TagProps) => {
          // If we haven't already added a typed in tag and we're pressing Enter
          if (!selectedTags.find((existing) => existing.text === tag.text)) {
            const existing = suggestions.find((t) => t.text === tag.text)

            addTag(existing ? existing : tag)
          }
        }}
        inputFieldPosition="bottom"
      />
      {!tags.fetched && <Loading />}
    </div>
  )
}

export default SidebarCreateCourse
