import { Component } from "react"
import Geocode from "react-geocode"

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      clickableIcons={false}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      {props.isMarkerShown && (
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      )}
    </GoogleMap>
  ))
)

class GoogleMapsContainer extends Component {
  state = {
    lat: null,
    lng: null,
  }

  componentDidMount() {
    const { location } = this.props

    if (location?.attributes?.field_address?.address_line1) {
      let address = location.attributes.field_address.address_line1
      if (location.attributes.field_address.address_line2) {
        address += location.attributes.field_address.address_line2 + ", "
      }
      address +=
        location.attributes.field_address.locality +
        " " +
        location.attributes.field_address.administrative_area +
        " " +
        location.attributes.field_address.postal_code

      // Get latitude & longitude from address
      Geocode.fromAddress(address).then((response) => {
        const { lat, lng } = response.results[0].geometry.location
        this.setState({
          lat,
          lng,
        })
      })
    }
  }

  render() {
    if (this.state.lat && this.state.lng) {
      return (
        <MyMapComponent
          googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyBTqeLkPTvf1zppFz2GV5RqHEvLzFu7gD4&callback=initMap"
          }
          lat={this.state.lat}
          lng={this.state.lng}
          loadingElement={<div />}
          containerElement={<div className="googleMapsWrapper" />}
          mapElement={<div style={{ height: `250px` }} />}
        />
      )
    }
    return null
  }
}

export default GoogleMapsContainer
