import { DatePickerProps, DateTimePicker } from "@mui/x-date-pickers"
import moment, { Moment } from "moment"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

interface DateRangeSelectorProps
  extends Pick<DatePickerProps<Moment>, "maxDate"> {
  startDate: string
  endDate: string
  onChange: (params: { field: "startDate" | "endDate"; value: string }) => void
  hideSecondDate?: boolean
  label?: string
}

const DateRangeSelector = ({
  startDate,
  endDate,
  onChange,
  hideSecondDate,
  label,
  ...otherProps
}: DateRangeSelectorProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="dateTimeSelect flexRow">
        <DateTimePicker
          {...otherProps}
          label={label ?? "Start"}
          value={moment(startDate)}
          onChange={(date) =>
            onChange({ field: "startDate", value: moment(date).format() })
          }
        />
        {!hideSecondDate && (
          <DateTimePicker
            {...otherProps}
            label="End"
            value={moment(endDate)}
            onChange={(date) =>
              onChange({ field: "endDate", value: moment(date).format() })
            }
          />
        )}
      </div>
    </LocalizationProvider>
  )
}

export default DateRangeSelector
