import theme from "../theme/theme"

function Certificate() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 10 10">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="HQ-&gt;-Single-Learner-Backpack"
          transform="translate(-384.000000, -880.000000)"
        >
          <g
            id="diploma"
            transform="translate(384.000000, 880.000000)"
            fill={theme.palette.primary.main}
            fillRule="nonzero"
          >
            <path
              d="M8.92253906,0 L1.07746094,0 C0.483339844,0 0,0.48720798 0,1.08605959 L0,7.09577828 C0,7.69462989 0.483339844,8.18181818 1.07746094,8.18181818 L5.69011719,8.18181818 L5.69011719,6.93761484 C5.69011719,5.92589247 6.50669922,5.10280864 7.51041016,5.10280864 C8.51412109,5.10280864 9.33070313,5.92591215 9.33070313,6.93761484 L9.33070313,8.10062998 C9.72296875,7.93815513 10,7.54904629 10,7.09577828 L10,1.08605959 C10,0.48720798 9.51664063,0 8.92253906,0 Z M2.80337891,1.28619521 L7.19662109,1.28619521 C7.35839844,1.28619521 7.48958984,1.4184115 7.48958984,1.58149663 C7.48958984,1.74458177 7.35839844,1.87679806 7.19662109,1.87679806 L2.80337891,1.87679806 C2.64160156,1.87679806 2.51041016,1.74458177 2.51041016,1.58149663 C2.51041016,1.4184115 2.64160156,1.28619521 2.80337891,1.28619521 Z M4.72785156,6.60032155 L1.54816406,6.60032155 C1.38638672,6.60032155 1.25519531,6.46810526 1.25519531,6.30502012 C1.25519531,6.14193499 1.38638672,6.0097187 1.54816406,6.0097187 L4.72787109,6.0097187 C4.8896875,6.0097187 5.02083984,6.14193499 5.02083984,6.30502012 C5.02083984,6.46810526 4.8896875,6.60032155 4.72785156,6.60032155 L4.72785156,6.60032155 Z M8.45179688,4.40721629 L1.54816406,4.40721629 C1.38638672,4.40721629 1.25519531,4.275 1.25519531,4.11191487 C1.25519531,3.94882973 1.38638672,3.81661344 1.54816406,3.81661344 L8.45181641,3.81661344 C8.61363281,3.81661344 8.74478516,3.94882973 8.74478516,4.11191487 C8.74478516,4.275 8.61363281,4.40721629 8.45179688,4.40721629 Z M8.45179688,3.14200717 L1.54816406,3.14200717 C1.38638672,3.14200717 1.25519531,3.00979088 1.25519531,2.84670575 C1.25519531,2.68362061 1.38638672,2.55140432 1.54816406,2.55140432 L8.45181641,2.55140432 C8.61363281,2.55140432 8.74478516,2.68362061 8.74478516,2.84670575 C8.74478516,3.00979088 8.61363281,3.14200717 8.45179688,3.14200717 Z"
              id="Shape"
            ></path>
            <path
              d="M7.5,5.90909091 C6.87340781,5.90909091 6.36363636,6.4188763 6.36363636,7.04546354 C6.36363636,7.67205077 6.87342579,8.18181818 7.5,8.18181818 C8.12661017,8.18181818 8.63636364,7.67205077 8.63636364,7.04546354 C8.63636364,6.4188763 8.12661017,5.90909091 7.5,5.90909091 Z"
              id="Path"
            ></path>
            <path
              d="M6.36363636,8.18181818 L6.36363636,9.70085197 C6.36363636,9.81117777 6.41836968,9.91254906 6.50607964,9.96460082 C6.5459429,9.98829334 6.58969,10 6.63331123,10 C6.68565311,10 6.73783316,9.98312805 6.7829468,9.94974313 L7.5,9.41953317 L8.2170532,9.94974313 C8.2622028,9.98312805 8.31434689,10 8.36668877,10 C8.41031,10 8.4540571,9.9882734 8.49392036,9.96462076 C8.58163032,9.912569 8.63636364,9.81119771 8.63636364,9.70085197 L8.63636364,8.18181818 C8.3374701,8.48820559 7.93813625,8.6757714 7.5,8.6757714 C7.06186375,8.6757714 6.6625299,8.48820559 6.36363636,8.18181818 L6.36363636,8.18181818 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Certificate
