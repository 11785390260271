import { FieldArray, useFormikContext } from "formik"
import { Stack, Typography } from "@mui/material"
import DeleteButton from "components/DeleteButton"
import { CourseFormValues } from "../types"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import { useAppSelector } from "store/hooks"
import ReviewerGroupIcon from "../../../img/reviewer-group.svg"

type ListProps = {
  viewOnly?: boolean
}

export const ReviewersListGroups = ({ viewOnly }: ListProps) => {
  const { values } = useFormikContext<CourseFormValues>()
  const assessments = useAppSelector((state) => state.assessments)

  const { reviewerGroups } = assessments

  return (
    <FieldArray
      name="reviewerGroups"
      render={(arrayHelpers) => (
        <>
          {values.reviewerGroups.map((groupRecord: SubGroup, index: number) => {
            const recordId = groupRecord.id
            const fullGroup = (reviewerGroups.data as any[]).find(
              (group) => recordId === group.relationships?.entity_id?.data?.id
            )

            if (fullGroup) {
              return (
                <Stack
                  key={index}
                  direction="row"
                  sx={{
                    marginLeft: "0!important",
                    marginBottom: "15px!important",
                    alignItems: "center",
                    paddingRight: 6,
                  }}
                  spacing={2}
                >
                  <img
                    style={{ border: "2px solid transparent" }}
                    width={25}
                    height={25}
                    src={ReviewerGroupIcon}
                    alt="Reviewer Group"
                  />

                  <Typography sx={{ fontWeight: "bold" }}>
                    {fullGroup.attributes?.label}
                  </Typography>
                  {!viewOnly && (
                    <DeleteButton onClick={() => arrayHelpers.remove(index)} />
                  )}
                </Stack>
              )
            }
            return null
          })}
        </>
      )}
    />
  )
}
