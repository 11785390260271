import {
  YouTubeSearchItem,
  YouTubeSearchResponse,
} from "@mobilemind/genie-api/src/types"
import axios from "axios"
import debouncePromise from "debounce-promise"
import { useCallback, useContext, useRef, useState } from "react"
import { genieApi } from "../common/genieApi"
import { CreateCourseGenieStep } from "../common/types"
import { CommunityCourseReview } from "./CommunityCourseReview"
import { CreateCourseGenieContext } from "./CreateCourseGenieContext"
import { CreateCourseGenieSearchForm } from "./CreateCourseGenieSearchForm"
import { CreateCourseGenieSearchResults } from "./CreateCourseGenieSearchResults"
import { CreateCourseGenieSearchReview } from "./CreateCourseGenieSearchReview"
import { Formik } from "formik"
import yup from "@mobilemind/common/src/utility/yup"

const validationSchema = yup.object({
  search: yup.string().required(),
})

export type CreateCourseGenieSearchFormValues = yup.InferType<
  typeof validationSchema
>

export const CreateCourseGenieSearch = () => {
  const controllerRef = useRef<AbortController>()

  const { currentStep, setCurrentStep } = useContext(CreateCourseGenieContext)

  const [searchResults, setSearchResults] =
    useState<YouTubeSearchResponse | null>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getYouTubeRecommendations = useCallback(
    debouncePromise(async (q: string, pageToken?: string | null) => {
      controllerRef.current?.abort()
      controllerRef.current = new AbortController()
      const nextSearchResults = await genieApi
        .get<YouTubeSearchResponse>("/recommend", {
          params: { q, pageToken },
          signal: controllerRef.current.signal,
        })
        .then((response) => response.data)
        .catch((err) => {
          if (axios.isCancel(err)) {
            return null
          } else {
            throw err
          }
        })
      if (nextSearchResults) {
        if (searchResults) {
          setSearchResults({
            ...nextSearchResults,
            items: [...searchResults.items, ...nextSearchResults.items],
          })
        } else {
          setSearchResults(nextSearchResults)
        }
      }
    }),
    [searchResults]
  )

  return (
    <Formik
      initialValues={{
        search: process.env.REACT_APP_CREATE_COURSE_GENIE_SEARCH_TEXT ?? "",
      }}
      onSubmit={async (values: CreateCourseGenieSearchFormValues) => {
        await getYouTubeRecommendations(values.search)
        setCurrentStep({ step: CreateCourseGenieStep.SearchResults })
      }}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ values }) => (
        <>
          {currentStep?.step === CreateCourseGenieStep.Search && (
            <CreateCourseGenieSearchForm
              onBack={() => {
                setCurrentStep({ step: CreateCourseGenieStep.Start })
              }}
            />
          )}
          {currentStep?.step === CreateCourseGenieStep.CommunityCourseReview &&
            currentStep?.courseId && (
              <CommunityCourseReview courseId={currentStep.courseId} />
            )}
          {currentStep?.step === CreateCourseGenieStep.SearchResults &&
            searchResults && (
              <CreateCourseGenieSearchResults
                results={searchResults}
                onBack={() => {
                  // Stop current axios request, if necessary
                  controllerRef.current?.abort()
                  setCurrentStep({ step: CreateCourseGenieStep.Search })
                  setSearchResults(null)
                }}
                onClickMore={async () => {
                  await getYouTubeRecommendations(
                    values.search,
                    searchResults.nextPageToken
                  )
                }}
                onSelect={(item: YouTubeSearchItem) => {
                  setCurrentStep({
                    step: CreateCourseGenieStep.SearchReview,
                    videoId: item.id,
                  })
                }}
              />
            )}

          {currentStep?.step === CreateCourseGenieStep.SearchReview &&
            currentStep?.videoId && (
              <CreateCourseGenieSearchReview
                videoId={currentStep.videoId}
                onBack={() => {
                  setCurrentStep({ step: CreateCourseGenieStep.SearchResults })
                }}
              />
            )}
        </>
      )}
    </Formik>
  )
}
