import { useState, useEffect } from "react"
import "../styles/index.scss"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import HubspotForm from "react-hubspot-form"
import { Link } from "react-router-dom"
import LearningBuddyMessage from "./LearningBuddyMessage"
import Loading from "./Loading"
import ButtonLarge from "./ButtonLarge"

type TicketProps = {
  portalId: string
  formId: string
  returnButtonText: string
}

function SubmitTicketLayout(props: TicketProps) {
  const [showReturnButton, setShowReturnButton] = useState(false)

  useEffect(() => {
    window.addEventListener("message", handler)
    return () => {
      window.removeEventListener("message", handler)
    }
  }, [])

  function handler(event: {
    data: {
      type: string
      eventName: string
    }
  }) {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      setShowReturnButton(true)
    }
  }
  return (
    <div className="page page-hubspot">
      <div style={{ minWidth: 768, maxWidth: 960 }}>
        {!showReturnButton && (
          <div style={{ marginBottom: 50 }}>
            <LearningBuddyMessage
              message={
                "Have an issue or a question? Complete the form below and we'll be in touch!"
              }
            />
          </div>
        )}

        <HubspotForm
          portalId={props.portalId}
          formId={props.formId}
          onSubmit={handler}
          loading={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 100,
              }}
            >
              <Loading />
            </div>
          }
        />

        {showReturnButton && (
          <footer>
            <Link to="/">
              <ButtonLarge>{props.returnButtonText}</ButtonLarge>
            </Link>
          </footer>
        )}
      </div>
    </div>
  )
}

export default SubmitTicketLayout
