import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { MobileMindUser } from "@mobilemind/common/src/types/session"
import {
  SliceCaseReducers,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"
import moment from "moment"
import qs, { ParsedQs } from "qs"
import type { AppDispatch, RootState } from "store/types"
import { CourseFormValues } from "./types"
import {
  RawCourseData,
  RawQuizData,
  transformCourseToFormValues,
} from "./utility"
import {
  AnswerKeyCourseAnswers,
  QuizEntityCourseQuiz,
} from "@mobilemind/common/src/types/jsonapi"
import { Entity } from "@mobilemind/common/src/types/utilities"

type RawCourseDataResponse = {
  data: RawCourseData[]
  included?: Entity[]
}

export const fetchActiveCourse = createAsyncThunk<
  CourseFormValues,
  { id: string; template: boolean; shouldLock: boolean },
  { state: RootState }
>(
  "activeCourseSlice/fetchActiveCourse",
  async (args, { dispatch, getState }) => {
    const { id, template, shouldLock } = args
    const { categories } = getState()
    let courseData: RawCourseDataResponse,
      editorLock: number | null = null,
      currentEditor: MobileMindUser | null = null
    let query: ParsedQs = {
      filter: {
        drupal_internal__id: id,
      },
    }

    if (template) {
      query.include = "field_mul,field_category,field_quiz"
      courseData = await fetchWrapper
        .get("/api/course_template/course_template?" + qs.stringify(query))
        .then((response: Response) => response.json())
    } else {
      query.include =
        "field_mul,field_tags,field_reviewers,field_category,field_current_editor,field_related_courses,field_subgroup,field_job_title,field_teacher,field_quiz,field_source_template"
      courseData = await fetchWrapper
        .get("/api/course_entity/course_entity?" + qs.stringify(query))
        .then((response: Response) => response.json())
    }

    const data = courseData.data[0]
    const included = courseData.included

    if (!data) {
      throw new Error("Course not found")
    }

    let answers: AnswerKeyCourseAnswers["data"] | undefined
    let quiz: RawQuizData | undefined

    let answerQuery: ParsedQs = {
      filter: {
        "field_course.id": data.id,
      },
    }
    if (template) {
      answerQuery.filter = { "field_template.id": data.id }
    }
    const answersData = await fetchWrapper
      .get("/api/answer_key/course_answers?" + qs.stringify(answerQuery))
      .then((response: Response) => response.json())
    answers = answersData.data[0]

    // If there's a quiz, fetch it
    const quizEntity = included?.find(
      (included): included is QuizEntityCourseQuiz["data"] =>
        included.type === "quiz_entity--course_quiz"
    )
    if (quizEntity?.attributes?.drupal_internal__id) {
      const quizData: RawQuizData[] = await fetchWrapper
        .get("/api/full-quiz/" + quizEntity.attributes.drupal_internal__id)
        .then((response: Response) => response.json())
      quiz = quizData[0]
    }

    const course = transformCourseToFormValues(data, {
      included,
      categories,
      answers,
      quiz,
    })

    if (!course.subGroups || course.subGroups.length === 0) {
      let subGroupIds = data.relationships?.field_subgroup?.data?.map(
        (group) => group.id
      )
      let groupLevelSubGroups = getState().session.subgroups
      course.subGroups =
        groupLevelSubGroups?.data?.filter((group) =>
          subGroupIds?.includes(group.id)
        ) ?? []
    }

    if (data.type === "course_entity--course_entity") {
      editorLock = data.attributes?.field_editor_lock_time ?? null
      currentEditor =
        included
          ?.filter(
            (included): included is MobileMindUser =>
              included.type === "user--user"
          )
          .find(
            (user) =>
              user.id === data.relationships?.field_current_editor?.data?.id
          ) ?? null
    }

    // Set the state course lock if it's not a template and the course should be locked by the current user.
    // @todo: this is a side effect and should be handled in a middleware.
    if (!template && shouldLock) {
      dispatch(
        setCourseLock({
          editorLock,
          currentEditor,
        })
      )
    }

    return course
  }
)

// @todo: combine this with setContentLock
export const lockCourse = createAsyncThunk<
  { editorLock: string; currentEditor: any },
  string,
  { dispatch: AppDispatch; state: RootState }
>("activeCourseSlice/createCourseLock", async (id, { getState }) => {
  const { session } = getState()
  const editorLock = moment().format()
  const currentEditor = session.user
  const body = {
    data: {
      id,
      type: "course_entity--course_entity",
      attributes: {
        field_editor_lock_time: editorLock,
      },
      relationships: {
        field_current_editor: {
          data: {
            type: "user--user",
            id: currentEditor.id,
          },
        },
      },
    },
  }
  await fetchWrapper.patch(
    "/api/course_entity/course_entity/" + id,
    session.token,
    JSON.stringify(body)
  )
  return {
    editorLock,
    currentEditor,
  }
})

// @todo: combine this with setContentLock
// @todo also: This does not work because the only ID it is being given is the drupal internal one, not UUID
export const unlockCourse = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("activeCourseSlice/clearCourseLock", async (id, { getState }) => {
  const { session } = getState()
  const body = {
    data: {
      id,
      type: "course_entity--course_entity",
      attributes: {
        field_editor_lock_time: null,
      },
      relationships: {
        field_current_editor: {
          data: null,
        },
      },
    },
  }
  await fetchWrapper.patch(
    "/api/course_entity/course_entity/" + id,
    session.token,
    JSON.stringify(body)
  )
})

export const getRelatedCourses = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("activeCourseSlice/getRelatedCourses", async (args, thunkAPI) => {
  const { session, activeCourse } = thunkAPI.getState()

  let url = session.isPartner
    ? "/api/mm_partner_portal/course_explore?"
    : "/api/course_entity/explore?"

  let response = await fetchWrapper.get(
    url + qs.stringify({ search: activeCourse.relatedSearch })
  )

  if (response.ok) {
    let data = await response.json()

    const courses: any[] = []
    data.data.forEach((course: any) => {
      if (!courses.find((existing) => existing.id === course.id)) {
        courses.push(course)
      }
    })

    return courses
  }
})

const debouncedGetRelated = debounceThunk(getRelatedCourses, 750)
export const updateRelatedSearch = createAsyncThunk<
  string,
  string,
  { state: RootState }
>("activeCourseSlice/updateRelatedSearch", async (args, thunkAPI) => {
  thunkAPI.dispatch(debouncedGetRelated())
  return args
})

type ActiveCourseState = {
  editorLock: string | null
  currentEditor: MobileMindUser | null
  relatedSearch: string
  relatedSearchResults: any[]
}

export const activeCourseSlice = createSlice<
  ActiveCourseState,
  SliceCaseReducers<ActiveCourseState>
>({
  name: "activeCourseSlice",
  initialState: {
    editorLock: null,
    currentEditor: null,
    relatedSearch: "",
    relatedSearchResults: [],
  },
  reducers: {
    setCourseLock: (state, action) => {
      state.editorLock = action.payload.editorLock
      state.currentEditor = action.payload.currentEditor
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateRelatedSearch.pending, (state, action) => {
      state.relatedSearch = action.meta.arg
    })
    builder.addCase(getRelatedCourses.fulfilled, (state, action) => {
      state.relatedSearchResults = action.payload
    })
    builder.addCase(lockCourse.fulfilled, (state, action) => {
      state.editorLock = action.payload.editorLock
      state.currentEditor = action.payload.currentEditor
    })
    builder.addCase(unlockCourse.fulfilled, (state, action) => {
      state.editorLock = null
      state.currentEditor = null
    })
  },
})

export const { setCourseLock } = activeCourseSlice.actions

// Selector function to determine if the active course is locked
export const selectIsContentLocked = createSelector(
  [
    (state: RootState) => state.activeCourse,
    (state: RootState) => state.session,
  ],
  (activeCourse, session) => {
    const { editorLock, currentEditor } = activeCourse
    return editorLock && currentEditor?.id !== session.user.id
  }
)

export default activeCourseSlice.reducer
