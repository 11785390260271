import fetchWrapper from "../functions/fetchWrapper"
import parseDataUrl from "parse-data-url"
import he from "he"

export function saveRequestAttachments({ requestId }) {
  return async (dispatch, getState) => {
    const { session, externalPD } = getState()
    const attachments = externalPD.currentRequest.attachments

    try {
      let i = 0
      let uploadedFiles = []

      while (i < attachments.length) {
        if (!attachments[i].published) {
          let body = parseDataUrl(attachments[i].file).toBuffer()

          let options = {
            credentials: "include",
            method: "POST",
            headers: new Headers({
              Accept: "application/vnd.api+json",
              "Content-Type": "application/octet-stream",
              "X-CSRF-Token": session.token,
              "Content-Disposition":
                'file; filename="' + he.encode(attachments[i].filename) + '"',
            }),
            body,
          }
          let response = await fetch(
            process.env.REACT_APP_API_URL +
              "/api/mm_ext_usr_event/mm_ext_usr_event/" +
              requestId +
              "/field_attachments",
            options
          )

          if (response.ok) {
            let data = await response.json()
            uploadedFiles.push(data)
          }
        }

        i++
      }

      return uploadedFiles
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveExternalTimeBlocks({ event, isRecommended, timeBlocks }) {
  return async (dispatch, getState) => {
    const { session, externalPD } = getState()

    if (!timeBlocks) {
      timeBlocks = isRecommended
        ? externalPD.currentEvent.timeBlocks
        : externalPD.currentRequest.timeBlocks
    }

    const bundle = isRecommended
      ? "mm_rec_ext_event--recommended_external_event"
      : "mm_ext_usr_event--mm_ext_usr_event"
    const url = "/api/" + bundle.replace("--", "/") + "/" + event.id

    try {
      let i = 0
      let uploadedBlocks = []

      const type = await fetchWrapper.get(
        "/api/paragraphs_type/paragraphs_type?filter[label]=External%20Event%20Time%20Spent"
      )

      let typeData = await type.json()
      let paragraphId = typeData.data[0].id

      while (i < timeBlocks.length) {
        let currentBlock = timeBlocks[i]
        let body = {
          data: {
            type: "paragraph--ext_event_time_spent",
            attributes: {
              parent_id: event.attributes.drupal_internal__id,
              parent_type: "mm_ext_usr_event",
              parent_field_name: "field_category",
              field_time_spent: currentBlock.hours * 60 + currentBlock.minutes,
            },
            relationships: {
              paragraph_type: {
                data: {
                  type: "paragraphs_type--paragraphs_type",
                  id: paragraphId,
                },
              },
              field_category_name: {
                data: {
                  type: "taxonomy_term--event_category",
                  id: currentBlock.categoryId,
                },
              },
            },
          },
        }

        let response
        if (currentBlock.id && !currentBlock.id.includes("new")) {
          body.data.id = currentBlock.id
          response = await fetchWrapper.patch(
            "/api/paragraph/ext_event_time_spent/" + currentBlock.id,
            session.token,
            JSON.stringify(body)
          )
        } else {
          response = await fetchWrapper.post(
            "/api/paragraph/ext_event_time_spent/",
            session.token,
            JSON.stringify(body)
          )
        }

        if (response.ok) {
          let data = await response.json()
          uploadedBlocks.push(data)
        }

        i++
      }

      let updatedEvent = {
        data: {
          id: event.id,
          type: bundle,
          relationships: {
            field_category: {
              data: uploadedBlocks.map((block) => {
                return {
                  type: "paragraph--ext_event_time_spent",
                  id: block.data.id,
                  meta: {
                    target_revision_id:
                      block.data.attributes.drupal_internal__revision_id,
                    drupal_internal__target_id:
                      block.data.attributes.drupal_internal__id,
                  },
                }
              }),
            },
          },
        },
      }

      await fetchWrapper.patch(url, session.token, JSON.stringify(updatedEvent))
      return uploadedBlocks
    } catch (err) {
      console.log(err)
    }
  }
}
