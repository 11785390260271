import "../styles/Loading.scss"
import classNames from "classnames"
import theme from "../theme/theme"
import { styled } from "@mui/material"
import { ReactNode } from "react"
// eslint-disable-next-line
const Spinner = require("react-spinkit")

type LoaderProps = {
  className?: string
  message?: ReactNode
  inline?: boolean
  hideSpinner?: boolean
  fullPage?: boolean
  color?: string
}

export const Loading = styled((props: LoaderProps) => {
  const color = props.color ? props.color : theme.palette.secondary.main

  return (
    <div
      className={classNames(
        props.className,
        "loadingWrapper",
        props.inline && "inline",
        props.fullPage && "fullPage"
      )}
    >
      {props.fullPage ? (
        <div
          className="panel"
          style={{
            padding: 20,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <>
            {!props.hideSpinner && (
              <Spinner name="ball-clip-rotate" fadeIn={"none"} color={color} />
            )}
            {props.message && <strong>{props.message}</strong>}
          </>
        </div>
      ) : (
        <>
          <Spinner name="ball-clip-rotate" fadeIn={"none"} color={color} />
          {props.message && <strong>{props.message}</strong>}
        </>
      )}
    </div>
  )
})(() => ({}))

export default Loading
