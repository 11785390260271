import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import parseDataUrl from "parse-data-url"
import moment from "moment"
import he from "he"

export function getOrgAnnouncements() {
  return async (dispatch, getState) => {
    const { session, entityList, announcementTypes } = getState()

    const filters = entityList.orgAnnouncements.filters

    const groupRoles = session.groupRoles
    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    const isCollectionAdminView =
      session.isCollectionAdmin && !session.activeMembership.data

    const orgId = isCollectionAdminView
      ? session.userCollection[0].id[0].value
      : session.group.id[0].value

    let query = {
      field_org_owner_target_id: orgId,
      sort_by: filters.sortBy === "name" ? "title" : filters.sortBy,
      sort_order: filters.sortOrder,
      search: filters.searchQuery,
    }

    if (!filters.status) {
      query.field_draft_value = 1
    }
    if (filters.status === 1) {
      query.field_draft_value = 0
    }
    if (filters.subGroup !== "any") {
      query.field_subgroup_target_id = filters.subGroup
    }
    if (filters.subGroup === "all") {
      query.field_subgroup_target_id = 0
    }
    if (filters.organization !== "any") {
      query.field_organization_target_id =
        filters.organization &&
        filters.organization.attributes.drupal_internal__id
    }

    const surveyType = announcementTypes.data.find(
      (type) => type.attributes.name === "Survey"
    )

    if (filters.announcementType !== "any") {
      let type = announcementTypes.data.find(
        (type) => type.id === filters.announcementType
      )
      query.field_announcement_type_target_id =
        type.attributes.drupal_internal__tid
      query.field_announcement_type_target_id_op = "or"
    } else if (announcementTypes.fetched && surveyType) {
      query.field_announcement_type_target_id =
        surveyType.attributes.drupal_internal__tid
      query.field_announcement_type_target_id_op = "not"
    }

    try {
      let content = []
      query.page = entityList.orgAnnouncements.currentPage

      let url
      if (session.isSiteAdmin) {
        url = "/api/node/mm_announcement_list"
      } else if (isCollectionAdminView) {
        url = "/api/node/collection_announcement_list/" + orgId
      } else {
        if (isGroupAdmin) {
          let groupIds =
            session.subgroups.data &&
            session.subgroups.data
              .map((group) => group.drupal_internal__id)
              .join("+")
          url =
            "/api/node/announcement_list/" +
            session.group.id[0].value +
            "/" +
            groupIds
        } else {
          url = "/api/node/announcement_list/" + orgId + "/all"
        }
      }

      let response = await fetchWrapper.get(url + "?" + qs.stringify(query))
      if (response.ok) {
        let data = await response.json()

        // This is dumb but saves more code, it's drupal content and not technically an entity,
        // so it has a "title" instead and an "nid"
        !data.rows.content &&
          data.rows.forEach((announcement) => {
            announcement.name = announcement.title
            announcement.id = announcement.nid
            announcement.image = announcement.field_media
            content.push(announcement)
          })

        dispatch({
          type: "entityList/getOrgAnnouncements",
          payload: content,
          meta: {
            pages: data.pager.total_pages,
            totalRecords: Number(data.pager.total_items),
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function sendAnnouncementEmail(activeAnnouncement) {
  return async (dispatch, getState) => {
    const { session } = getState()

    let body = {
      data: {
        type: "node--announcement",
        id: activeAnnouncement.id,
        attributes: {
          field_email_send: true,
        },
      },
    }

    try {
      await fetchWrapper.patch(
        "/api/node/announcement/" + activeAnnouncement.id,
        session.token,
        JSON.stringify(body)
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveAnnouncement(activeAnnouncement, isDraft) {
  return async (dispatch, getState) => {
    const { session, announcementTypes } = getState()
    const { groupRoles } = session

    const isCollectionAdminView =
      session.isCollectionAdmin && !session.activeMembership.data

    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    const isDrafter =
      session.orgRoles &&
      !session.orgRoles.includes("organization-admin") &&
      (session.orgRoles.includes("organization-drafter") ||
        session.groupRoles.includes("group-drafter"))

    const type = announcementTypes.data.find(
      (announcementType) => announcementType.id === activeAnnouncement.type
    )

    const isImageAllowed = type && type.attributes.name !== "Featured Course"

    let body = {
      data: {
        type: "node--announcement",
        attributes: {
          title: activeAnnouncement.name,
          field_expiration_date: moment(
            activeAnnouncement.expirationDate.replace('"', "").replace('"', "")
          ).format("YYYY-MM-DDTHH:mm:ss-04:00"),
          body: activeAnnouncement.body,
          field_include_collections_learn: true,
          field_email: activeAnnouncement.publishMethod,
          field_courses_complete: Number(activeAnnouncement.coursesRequired),
        },
        relationships: {},
      },
    }

    // Only include the org if they are not a MM administrator
    if (!session.roles.find((role) => role.target_id === "administrator")) {
      if (isCollectionAdminView) {
        body.data.relationships.field_collection_owner = {
          data: [
            {
              type: "group--organization",
              id: session.userCollection[0].uuid[0].value,
            },
          ],
        }
      } else {
        body.data.relationships.field_group = {
          data: [
            {
              type: "group--organization",
              id: session.group.uuid[0].value,
            },
          ],
        }
        body.data.relationships.field_org_owner = {
          data: {
            type: "group--organization",
            id: session.group.uuid[0].value,
          },
        }
      }
    }
    if (activeAnnouncement.type !== "Select Type") {
      body.data.relationships.field_announcement_type = {
        data: {
          type: "taxonomy_term--announcement_type",
          id: activeAnnouncement.type,
        },
      }
    }

    // Set subgroup owner if user is group level
    if (activeAnnouncement.subGroupOwner !== "org" && isGroupAdmin) {
      body.data.relationships.field_subgroup_owner = {
        data: {
          type: "group--group",
          id: activeAnnouncement.subGroupOwner
            ? activeAnnouncement.subGroupOwner
            : session.subgroups.data[0].id,
        },
      }
    }

    if (!isDrafter) {
      body.data.attributes.field_draft = isDraft ? true : false
    }

    // If we're patching an existing announcement
    if (activeAnnouncement.id) {
      body.data.id = activeAnnouncement.id
    }

    if (activeAnnouncement.featuredCourse) {
      body.data.relationships.field_course = {
        data: {
          type: "course_entity--course_entity",
          id: activeAnnouncement.featuredCourse.id,
        },
      }
    }

    if (activeAnnouncement.featuredLearningPath) {
      body.data.relationships.field_learning_path = {
        data: {
          type: "learning_path--learning_path",
          id:
            activeAnnouncement.featuredLearningPath.field_lp_uuid ??
            activeAnnouncement.featuredLearningPath.id,
        },
      }
    }
    if (
      type?.attributes?.name === "Mandated Training" &&
      activeAnnouncement.selectedTraining
    ) {
      body.data.relationships.field_renewable_course_series = {
        data: {
          type: "renew_course_ser--renew_course_ser",
          id: activeAnnouncement.selectedTraining.uuid,
        },
      }
    }

    if (activeAnnouncement.subGroups.length) {
      body.data.relationships.field_subgroup = {
        data: [],
      }

      activeAnnouncement.subGroups.forEach((group) => {
        body.data.relationships.field_subgroup.data.push({
          type: "group--group",
          id: group.id,
          meta: {
            drupal_internal__target_id: group.attributes.drupal_internal__id,
          },
        })
      })
    } else if (isGroupAdmin && session.subgroups.data.length === 1) {
      body.data.relationships.field_subgroup = {
        data: [
          {
            type: "group--group",
            id: session.subgroups.data[0].id,
            meta: {
              drupal_internal__target_id:
                session.subgroups.data[0].attributes.drupal_internal__id,
            },
          },
        ],
      }
    } else {
      body.data.relationships.field_subgroup = {
        data: null,
      }
    }

    if (!activeAnnouncement.image) {
      body.data.relationships.field_media = {
        data: null,
      }
    } else {
      if (activeAnnouncement.image.id) {
        body.data.relationships.field_media = {
          data: {
            type: "file--image",
            id: activeAnnouncement.image.id,
          },
        }
      }
    }

    // If we need to email this and it's not a draft, and we haven't already created an AEE, let's create one
    if (
      activeAnnouncement.publishMethod !== "no_email" &&
      !body.data.attributes.field_draft
    ) {
      let entityBody = {
        data: {
          type: "mm_announcement_email--mm_announcement_email",
          relationships: {
            field_group: {
              data: [],
            },
            field_subgroup: {
              data: [],
            },
          },
        },
      }

      if (!session.roles.find((role) => role.target_id === "administrator")) {
        if (
          !body.data.relationships.field_subgroup.data &&
          !session.isCollectionAdmin &&
          !session.activeMembership.data
        ) {
          // Stick the user's org on there but add in this stupid meta value
          // *** ONLY IF they are no groups ***
          entityBody.data.relationships.field_group =
            body.data.relationships.field_group

          entityBody.data.relationships.field_group.data[0].meta = {
            drupal_internal__target_id: session.group.id[0].value,
          }
        }

        // Do the same thing for subgroups
        if (body.data.relationships.field_subgroup.data) {
          entityBody.data.relationships.field_subgroup.data =
            body.data.relationships.field_subgroup.data.map((group) => {
              const fullGroup = session.subGroups.data.find(
                (full) => full.id === group.id
              )
              group.meta = {
                drupal_internal__target_id: fullGroup.entity_id,
              }

              return group
            })
        }
      }

      // POST or PATCH it
      let emailEntity
      if (!activeAnnouncement.emailEntityId) {
        emailEntity = await fetchWrapper.post(
          "/api/mm_announcement_email/mm_announcement_email",
          session.token,
          JSON.stringify(entityBody)
        )
      } else {
        entityBody.data.id = activeAnnouncement.emailEntityId
        emailEntity = await fetchWrapper.patch(
          "/api/mm_announcement_email/mm_announcement_email/" +
            activeAnnouncement.emailEntityId,
          session.token,
          JSON.stringify(entityBody)
        )
      }

      if (emailEntity.ok) {
        let emailEntityData = await emailEntity.json()

        // Add a reference to it on the announcement node itself
        body.data.relationships.field_announcement_email = {
          data: {
            type: "mm_announcement_email--mm_announcement_email",
            id: emailEntityData.data.id,
          },
        }
      }
    }

    try {
      let response
      if (activeAnnouncement.id) {
        response = await fetchWrapper.patch(
          "/api/node/announcement/" + activeAnnouncement.id,
          session.token,
          JSON.stringify(body)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/node/announcement",
          session.token,
          JSON.stringify(body)
        )
      }
      if (response.ok) {
        let data = await response.json()
        let createdAnnouncement = data.data

        createdAnnouncement.image = isImageAllowed && activeAnnouncement.image
        let id = activeAnnouncement.id
          ? activeAnnouncement.id
          : createdAnnouncement.id

        if (activeAnnouncement.id) {
          dispatch({
            type: "entityList/updateAnnouncement",
            payload: data.data,
          })
        } else {
          dispatch({
            type: "entityList/addCreatedAnnouncement",
            payload: data.data,
          })
        }

        dispatch({
          type: "activeAnnouncementSlice/startNewAnnouncement",
          payload: data.data,
        })

        // If they have uploaded a new image and it's allowed to be attached to this announcement type
        if (activeAnnouncement.filename && isImageAllowed) {
          let options = {
            credentials: "include",
            method: "POST",
            headers: new Headers({
              Accept: "application/vnd.api+json",
              "Content-Type": "application/octet-stream",
              "X-CSRF-Token": session.token,
              "Content-Disposition":
                'file; filename="' +
                he.encode(activeAnnouncement.filename) +
                '"',
            }),
            body: parseDataUrl(activeAnnouncement.image).toBuffer(),
          }

          await fetch(
            process.env.REACT_APP_API_URL +
              "/api/node/announcement/" +
              id +
              "/field_media",
            options
          )
        }

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteAnnouncement(announcementId) {
  return async (dispatch, getState) => {
    const { session } = getState()
    try {
      let response = await fetchWrapper.remove(
        "/api/node/announcement/" + announcementId,
        session.token
      )
      if (response.ok) {
        dispatch({
          type: "entityList/deleteAnnouncement",
          payload: announcementId,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}
