import { createAction } from "@reduxjs/toolkit"

export const setUserLearningPaths = createAction(
  "session/getUserLearningPaths",
  (data, fetched) => {
    return {
      payload: {
        data: data.data ? data.data : data,
        fetched: fetched,
        included: data.included,
      },
    }
  }
)

export const setAssessments = createAction(
  "assessments/get",
  (data, fetched, status) => {
    let payload = data.data
    if (data.data) {
      if (!Array.isArray(data.data)) {
        payload = [data.data]
      }
    } else {
      payload = data
    }

    return {
      payload,
      meta: {
        included: data.included,
        fetched: fetched ? true : false,
        status,
      },
    }
  }
)
