import React from "react"

import he from "he"
import "./styles/backpack.scss"

import TimeCapsule from "../../components/TimeCapsule"
import moment from "moment"
import { ExternalUserEventsLearner } from "../../types/events"

interface RowProps {
  data: ExternalUserEventsLearner[]
}
const ExternalRow: React.FC<RowProps> = ({ data }) => {
  return (
    <div className="print-section">
      {data.map((event: ExternalUserEventsLearner, index: number) => {
        const totalMinutes = Number(
          event.field_total_time_value.replace("min", "")
        )
        const hours =
          totalMinutes >= 60
            ? Math.floor(totalMinutes / 60).toLocaleString()
            : 0
        const minutes = totalMinutes % 60

        return (
          <div key={index} className="item external-item">
            <img
              className="icon external-pd"
              src={
                process.env.REACT_APP_TEACHER_URL +
                "/static/media/external-black.cce98b3842450d22481b.svg"
              }
              style={{}}
            />

            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <strong
                style={{
                  display: "block",
                  fontSize: 12,
                  lineHeight: 1.2,
                }}
              >
                {he.decode(event.title)}
              </strong>
              <span style={{ fontSize: 10, opacity: 0.6 }}>
                {moment(event.field_start_date_value).format("MMMM Do, YYYY")}
              </span>
            </div>

            <TimeCapsule hours={Number(hours)} minutes={minutes} />
          </div>
        )
      })}
    </div>
  )
}

export default ExternalRow
