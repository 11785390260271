interface CapsuleProps {
  hours: number
  minutes: number
}

const TimeCapsule = ({ hours, minutes }: CapsuleProps) => {
  return (
    <div className="capsule">
      <div className="icon clock white" />
      {hours > 0 && <>{hours.toLocaleString()}h </>}
      {minutes > 0 && <>{minutes}m</>}
      {!hours && !minutes && <>0h</>}
    </div>
  )
}

export default TimeCapsule
