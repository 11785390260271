import { BackButton } from "./BackButton"
import { CourseFormBody } from "./CourseFormBody"
import { CourseFormButtons } from "./CourseFormButtons"
import { CourseFormHeader } from "./CourseFormHeader"
import { CourseFormStep } from "./CourseFormStepper"
import { NextButton } from "./NextButton"

import {
  Box,
  Checkbox,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { Field, useFormikContext } from "formik"
import { ReactComponent as SmartphoneIcon } from "img/smartphone.svg"
import moment from "moment"
import { useAppSelector } from "store/hooks"

import { FastFieldWithError } from "components/FastFieldWithError"
import { isGroupLevel } from "functions"
import { CourseFormValues } from "../types"
import { CourseOwnerField } from "./CourseOwnerField"
import { CourseVisibilityField } from "./CourseVisibilityField"
import { RelatedCoursesField } from "./RelatedCoursesField"
export const CourseFormSettings = () => {
  const { values, setFieldValue } = useFormikContext<CourseFormValues>()

  const session = useAppSelector((state) => state.session)

  // @todo: get rid of duplicate subgroups or subGroups property on session slice.
  let subGroups: any
  // @ts-ignore
  if (session.subgroups && session.subgroups.data && isGroupLevel(session)) {
    subGroups = session.subgroups
  } else {
    subGroups = session.subGroups
  }

  const isOwnCommunityCourse =
    values.creatorOrg?.id === session.group.uuid[0].value
  return (
    <>
      <CourseFormHeader course={values} />
      <CourseFormBody>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
          }}
        >
          <Box sx={{ flex: 1 }}>
            {!values.isTemplate && (
              <Box sx={{ marginTop: 3, marginBottom: 5 }}>
                <RelatedCoursesField />
              </Box>
            )}

            <Box sx={{ marginBottom: 5 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack direction="row" sx={{ flex: 1 }}>
                  <Typography variant="h3">Mobile Friendly</Typography>
                  <SmartphoneIcon
                    style={{
                      width: 20,
                      height: 20,
                      opacity: 0.5,
                      marginLeft: 5,
                    }}
                  />
                </Stack>

                <Field
                  as={Checkbox}
                  name="mobileFriendly"
                  checked={values.mobileFriendly}
                  disabled={values.isTemplate && !isOwnCommunityCourse}
                />
              </Stack>

              <FormHelperText>
                Allows learners to complete this challenge on their iOS/Android
                device. MobileMind strongly recommends this for all courses.
              </FormHelperText>
            </Box>

            {!values.isTemplate && (
              <Box sx={{ marginBottom: 5 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Stack direction="row" sx={{ flex: 1 }}>
                    <Typography variant="h3">
                      Exclude From Course Library
                    </Typography>
                  </Stack>

                  <Field
                    as={Checkbox}
                    name="excludeFromExplore"
                    checked={values.excludeFromExplore}
                  />
                </Stack>

                <FormHelperText>
                  Removes this course from the <strong>Explore</strong> tab in
                  the Learn app. If checked, this course will still appear as
                  part of any Learning Path, Badge, or Mandated Training in
                  which it's used.
                </FormHelperText>
              </Box>
            )}

            {!values.isTemplate && (
              <>
                <Typography variant="h3">Release Date</Typography>
                <FormHelperText>
                  This course will not appear in the Learn app until this date.
                </FormHelperText>
                <DatePicker
                  value={moment(values.releaseDate)}
                  onChange={(newValue) => {
                    setFieldValue("releaseDate", newValue)
                  }}
                  sx={{ maxWidth: 250, marginBottom: 5, marginTop: 3 }}
                />
              </>
            )}
          </Box>

          <Box sx={{ flex: 1, marginTop: 3 }}>
            {!values.isTemplate && subGroups.data.length > 0 && (
              <>
                <CourseVisibilityField />
                <CourseOwnerField />
              </>
            )}

            {!values.isTemplate && (
              <>
                <Typography variant="h3" sx={{ marginTop: 5 }}>
                  Support Email
                </Typography>
                <FormHelperText>
                  A single email address learners can use to get help with this
                  challenge. A prompt to get help will automatically appear
                  after a second incomplete attempt.
                </FormHelperText>
                <FastFieldWithError
                  as={TextField}
                  name="supportEmail"
                  label="Support Email"
                  margin="normal"
                  variant="standard"
                />
              </>
            )}
          </Box>
        </Box>
        <CourseFormButtons>
          <BackButton step={CourseFormStep.Challenge} />
          <NextButton step={CourseFormStep.Review} />
        </CourseFormButtons>
      </CourseFormBody>
    </>
  )
}
