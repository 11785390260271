import { noop } from "lodash"
import { createContext } from "react"
import { CreateCourseGenieFormValues } from "../common/createCourseGenieSchema"
import { CreateCourseGenieStepPayload } from "../common/types"

type CreateCourseGenieContextValue = {
  currentStep: CreateCourseGenieStepPayload | null
  setCurrentStep: (payload: CreateCourseGenieStepPayload | null) => void
  onSubmit: (videoId: string, values: CreateCourseGenieFormValues) => void
}

export const CreateCourseGenieContext =
  createContext<CreateCourseGenieContextValue>({
    currentStep: null,
    setCurrentStep: noop,
    onSubmit: noop,
  })
