import { Box, Dialog, Typography } from "@mui/material"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {
  fetchActiveConference,
  getRoomsForConferenceLocation,
  reorderRooms,
} from "../activeConferenceSlice"
import { useAppDispatch } from "store/hooks"
import LocationIcon from "../../../../img/location-color.svg"

import { updateConferenceRoomOrder } from "../../../../actions/events"
import Loading from "@mobilemind/common/src/components/Loading"

function ArrangeRoomsModal({
  open,
  rooms,
  conference,
  selectedLocation,
  onClose,
  refresh,
}) {
  const [currentOrder, updateOrder] = useState(rooms)
  const dispatch = useAppDispatch()
  const [isSaving, setIsSaving] = useState(false)
  useEffect(() => {
    updateOrder(rooms)
  }, [rooms])

  function dropRoom(dropped) {
    let targetIndex = dropped.destination.index

    let current = currentOrder.filter((room) => room.id !== dropped.draggableId)
    current.splice(
      targetIndex,
      0,
      rooms.find((room) => room.id === dropped.draggableId)
    )
    updateOrder(current)
  }

  const fullLocation = conference.locations.find(
    (loc) => loc.id === selectedLocation
  )

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          maxHeight: "none",
        },
      }}
    >
      <div id="arrange-rooms-modal">
        <h2>
          {fullLocation && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <img src={LocationIcon} alt="Location" width={25} />
              <Typography
                sx={{ paddingLeft: 3, fontWeight: "bold", fontSize: 18 }}
              >
                {fullLocation.attributes.name}
              </Typography>
            </Box>
          )}
        </h2>

        <Typography
          sx={{ marginBottom: -2, fontSize: 14, fontStyle: "italic" }}
        >
          Drag a room to repostion its session column on the conference
          calendar.
        </Typography>

        <DragDropContext
          onDragEnd={(droppedItem) => {
            dropRoom(droppedItem)
          }}
        >
          <Droppable droppableId={"rooms-list"}>
            {(provided) => (
              <ul
                style={{ height: 41 * rooms.length }}
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="roomsList"
              >
                {currentOrder.length > 0 &&
                  currentOrder.map((room, index) => {
                    if (room.attributes.name !== "Virtual Sessions") {
                      return (
                        <Draggable
                          key={room.id}
                          draggableId={room.id}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <span className="icon hamburger" />
                              <span className="icon room" />
                              <span className="name">
                                {room.attributes.name}
                              </span>
                            </li>
                          )}
                        </Draggable>
                      )
                    }

                    return null
                  })}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        {!isSaving && (
          <footer>
            <ButtonSmall
              classes="cancel"
              onClick={() => {
                onClose()
              }}
            >
              Cancel
            </ButtonSmall>
            <ButtonSmall
              onClick={async () => {
                setIsSaving(true)
                dispatch(reorderRooms({ currentOrder, selectedLocation }))
                await dispatch(
                  updateConferenceRoomOrder({
                    conferenceId: conference.id,
                    selectedLocation,
                    currentOrder,
                  })
                )

                let response = await dispatch(
                  fetchActiveConference({
                    id: conference.drupal_internal__id,
                  })
                )

                response.payload.locations.forEach(async (location, index) => {
                  await dispatch(
                    getRoomsForConferenceLocation({
                      id: location.id,
                      index,
                      length: response.payload.locations.length,
                      conference: response.payload.data[0],
                    })
                  )
                })
                setIsSaving(false)
                refresh()
              }}
            >
              Save Changes
            </ButtonSmall>
          </footer>
        )}

        {isSaving && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loading message="Saving changes..." />
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default ArrangeRoomsModal
