import yup from "@mobilemind/common/src/utility/yup"
import { YouTubeVideo } from "@mobilemind/genie-api/src/types"
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material"
import { Form, Formik } from "formik"
import getYouTubeID from "get-youtube-id"
import { useContext } from "react"
import { createCourseGenieSchema } from "../common/createCourseGenieSchema"
import { genieApi } from "../common/genieApi"
import { CreateCourseGenieStep } from "../common/types"
import { CreateCourseGenieContext } from "./CreateCourseGenieContext"
import { CreateCourseGenieReviewFields } from "./CreateCourseGenieReviewFields"
import { CreateCourseGenieVideoUrlField } from "./CreateCourseGenieVideoUrlField"
import { GenieMessage } from "./GenieMessage"

const YOUTUBE_URL_REQUIRED = "A YouTube video URL is required"
const INVALID_YOUTUBE_URL = "That's not a valid YouTube URL"
const YOUTUBE_URL_NOT_FOUND = "That YouTube video could not be found"

export const validationSchema = createCourseGenieSchema.shape({
  videoUrl: yup
    .string()
    .required(YOUTUBE_URL_REQUIRED)
    .test("is-youtube-url", INVALID_YOUTUBE_URL, (value) => {
      return getYouTubeID(value) !== null
    })
    .default(process.env.REACT_APP_CREATE_COURSE_GENIE_YOUTUBE_URL ?? ""),
})

export type CreateCourseGenieVideoUrlFormValues = yup.InferType<
  typeof validationSchema
>

export const CreateCourseGenieVideoUrl = () => {
  const { setCurrentStep, onSubmit } = useContext(CreateCourseGenieContext)

  return (
    <Formik
      initialValues={validationSchema.getDefault()}
      onSubmit={async (values, { setFieldError }) => {
        try {
          const videoId = getYouTubeID(values.videoUrl)
          if (!videoId) {
            setFieldError("videoUrl", INVALID_YOUTUBE_URL)
            return
          }
          // Fetch the video to make sure it exists.
          await genieApi.get<YouTubeVideo>(`/video/${videoId}`)
          await onSubmit(videoId, values)
        } catch {
          setFieldError("videoUrl", YOUTUBE_URL_NOT_FOUND)
        }
      }}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ isSubmitting, isValid }) => (
        <Box component={Form} sx={{ display: "contents" }}>
          <DialogContent>
            <GenieMessage>
              <Typography gutterBottom={true}>
                <strong>Great!</strong> Enter the YouTube URL below and select a
                submission type. When you're ready, I'll create a new course
                with this video.
              </Typography>
            </GenieMessage>
            <Stack spacing={4}>
              <CreateCourseGenieReviewFields />
              <CreateCourseGenieVideoUrlField />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setCurrentStep({ step: CreateCourseGenieStep.Start })
              }}
            >
              Go Back
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              Let's Go!
            </Button>
          </DialogActions>
        </Box>
      )}
    </Formik>
  )
}
