import { useState, useRef, useEffect } from "react"
import { Box, Button } from "@mui/material"
import { Link } from "react-router-dom"

import moment from "moment"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import { sortEvents } from "../../../store/reducers/calendar"

import { connect } from "react-redux"
import { addressToString } from "@mobilemind/common/src/functions"
import classNames from "classnames"
import he from "he"
import RubricIcon from "../../../img/shopping-list.svg"
import UserInitial from "@mobilemind/common/src/components/UserInitial"

import Values from "values.js"
import {
  toggleExternalEventModal,
  openEvent,
} from "../../../store/reducers/externalPD"

import theme from "@mobilemind/common/src/theme/theme"
import { useAppDispatch, useAppSelector } from "store/hooks"
import AttendanceSummary from "../AttendanceSummary"
import { fetchEventAttendance } from "actions/events"
import Loading from "@mobilemind/common/src/components/Loading"

const mapStateToProps = ({ calendar, session }) => {
  const { orgRoles, groupRoles } = session

  const allRoles = orgRoles.concat(groupRoles).join(",")

  const canCreateObservations =
    allRoles.includes("observation") || allRoles.includes("observer")

  const canSchedule =
    allRoles.includes("organization-admin") ||
    allRoles.includes("group-admin") ||
    allRoles.includes("scheduler")

  const observationsOnly = !canSchedule && canCreateObservations
  return {
    observationsOnly,
    session,
    calendar,
  }
}

function CalendarListView(props) {
  const { calendar, session, observationsOnly } = props
  const { orgRoles, groupRoles } = session

  const [activeItem, setActiveItem] = useState(null)
  const [attendance, setAttendance] = useState(null)
  const [attendanceLoading, setAttendanceLoading] = useState(false)

  const { isFetchingPrev, isFetchingLater } = calendar
  const dispatch = useAppDispatch()

  const sortedList = useAppSelector(sortEvents).filter((event) => {
    return !event.field_archive
  })

  // Find the first event that's today or after
  const initialEvent = sortedList.find((event) => {
    return moment(event.startDate).isAfter(moment().subtract(1, "day"))
  })

  const initialRef = useRef(null)
  // Then scroll to it once it's identified
  useEffect(() => {
    initialRef.current && initialRef.current.scrollIntoView()
  }, [initialEvent])

  function fetchMore(direction) {
    let dateRange = {
      min: moment(sortedList[0].startDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      max: moment(sortedList[0].startDate).format("YYYY-MM-DD"),
    }
    if (direction !== "prev") {
      dateRange = {
        min: moment(sortedList[sortedList.length - 1].startDate)
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        max: moment(sortedList[sortedList.length - 1].startDate)
          .add(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
      }
    }

    // Refetch the calendar / events list with our new date range
    props.debouncedFetchCalendar({
      dateRange,
      observationsOnly,
      isFetchingMore: true,
    })
  }
  const secondary = new Values(theme.palette.secondary.main)

  return (
    <div className="calendarList">
      {sortedList.length > 0 && (
        <ul>
          <li
            style={{
              opacity: isFetchingPrev ? 0.5 : 1,
              pointerEvents: isFetchingPrev ? "none" : "all",
            }}
            className="fetchButtonContainer"
          >
            <Button
              className={"button small"}
              onClick={() => fetchMore("prev")}
            >
              Load Earlier Events
            </Button>
          </li>

          {sortedList.map((event, index) => {
            const {
              location,
              eventRole,
              isObservation,
              isConference,
              drupal_internal__id,
            } = event
            const canSchedule =
              orgRoles.includes("organization-admin") ||
              orgRoles.includes("organization-scheduler") ||
              groupRoles.includes("group-admin") ||
              groupRoles.includes("group-scheduler") ||
              eventRole === "Manager" ||
              eventRole === "Presenter"

            let viewUrl = "/events/view/event/" + drupal_internal__id
            let editUrl = "/events/event/" + drupal_internal__id
            let eventLabel = "event"

            if (isConference) {
              viewUrl = "/events/view/conference/" + drupal_internal__id
              editUrl = "/events/conference/" + drupal_internal__id
              eventLabel = "conference"
            }

            if (isObservation) {
              viewUrl = "/events/view/observation/" + drupal_internal__id
              editUrl = "/events/observation/" + drupal_internal__id
              eventLabel = "observation"
            }

            let multiDay =
              moment(event.startDate).format("MM/DD") !==
              moment(event.endDate).format("MM/DD")
            let isFirstOfMonth =
              !sortedList[index - 1] ||
              moment(event.startDate).format("MM") !==
                moment(sortedList[index - 1].startDate).format("MM")
            let isLastofMonth =
              !sortedList[index + 1] ||
              moment(event.startDate).format("MM") !==
                moment(sortedList[index + 1].startDate).format("MM")

            if (event.isEmptyMonth) {
              isFirstOfMonth = true
            }

            let startDateDST = event.startDate
            let endDateDST = event.endDate

            let searchCheck =
              !props.calendar.searchQuery ||
              (props.calendar.searchQuery &&
                event.title &&
                event.title
                  .toLowerCase()
                  .includes(props.calendar.searchQuery.toLowerCase()))

            return (
              <>
                {isFirstOfMonth && (
                  <li className="month-header">
                    {event.startDate ? (
                      <>{moment(event.startDate).format("MMMM YYYY")}</>
                    ) : (
                      <>{moment(event.month).format("MMMM YYYY")}</>
                    )}
                  </li>
                )}

                {!event.isEmptyMonth && (
                  <>
                    {searchCheck && (
                      <li
                        style={{ borderBottomWidth: isLastofMonth ? 0 : 1 }}
                        key={index}
                        ref={
                          initialEvent &&
                          initialEvent.drupal_internal__id ===
                            event.drupal_internal__id
                            ? initialRef
                            : null
                        }
                      >
                        <Accordion
                          onClick={() => {
                            setAttendance(null)
                            setActiveItem(index !== activeItem ? index : null)

                            if (!event.isExternal && !isObservation) {
                              // If we need to fetch attendance

                              setAttendance(null)
                              !activeItem && setAttendanceLoading(true)
                              dispatch(
                                fetchEventAttendance(event.drupal_internal__id)
                              ).then((res) => {
                                if (res?.attendance_data) {
                                  setAttendance(res?.attendance_data)
                                }
                                setAttendanceLoading(false)
                              })
                            }
                          }}
                          expanded={activeItem === index}
                        >
                          <AccordionSummary>
                            <div className="flexRow">
                              <div className="dateTime">
                                <div className="weekMonth">
                                  {moment(startDateDST).format("ddd")}
                                  <br />
                                  {moment(startDateDST).format("MMM")}
                                </div>
                                <span className="date">
                                  {moment(startDateDST).format("D")}
                                </span>
                              </div>

                              <div className="flexRow responsive">
                                <div style={{ flex: 1 }}>
                                  <div className="title">
                                    <header>
                                      <span
                                        style={{
                                          backgroundColor:
                                            event.isExternal &&
                                            secondary.shade(35).hexString(),
                                        }}
                                        className={classNames(
                                          "chip",
                                          isConference && "conference",
                                          event.field_draft && "draft"
                                        )}
                                      />
                                      {he.decode(event.title)}
                                    </header>
                                  </div>

                                  <div className="time">
                                    <span className="icon calendar" />
                                    {!multiDay && (
                                      <>
                                        {!event.isAllDay ? (
                                          <>
                                            {moment(startDateDST).format(
                                              "h:mm A"
                                            )}{" "}
                                            -{" "}
                                            {moment(endDateDST).format(
                                              "h:mm A"
                                            )}
                                          </>
                                        ) : (
                                          <>All Day</>
                                        )}
                                      </>
                                    )}
                                    {multiDay && (
                                      <div className="multiDay">
                                        <div>
                                          {moment(startDateDST).format(
                                            "dddd, h:mm A"
                                          )}
                                        </div>
                                        <div>
                                          {moment(endDateDST).format(
                                            "dddd, h:mm A"
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {event.eventRole && (
                                  <div className="roleLabel">
                                    <span
                                      className={classNames(
                                        "icon",
                                        event.eventRole.toLowerCase()
                                      )}
                                    />
                                    {eventLabel} {event.eventRole}
                                  </div>
                                )}

                                <footer>
                                  {event.isExternal ? (
                                    <Button
                                      className="button small"
                                      onClick={() => {
                                        dispatch(toggleExternalEventModal(true))
                                        dispatch(openEvent(event.fullEvent))
                                      }}
                                    >
                                      <span className="icon edit"></span>
                                      Edit
                                    </Button>
                                  ) : (
                                    <Link
                                      style={{
                                        pointerEvents: canSchedule
                                          ? "all"
                                          : "none",
                                        opacity: canSchedule ? 1 : 0,
                                        marginRight: 35,
                                      }}
                                      to={editUrl}
                                    >
                                      <Button className="button small">
                                        <span className="icon edit"></span>
                                        Edit
                                      </Button>
                                    </Link>
                                  )}
                                  {!event.isExternal && (
                                    <Link to={viewUrl}>
                                      <Button className="button small">
                                        <span className="icon calendar blue"></span>
                                        View
                                      </Button>
                                    </Link>
                                  )}
                                </footer>
                              </div>
                            </div>
                          </AccordionSummary>
                          {event.description && (
                            <AccordionDetails>
                              {location && location.length > 0 && (
                                <>
                                  {location.map((loc) => {
                                    if (location?.attributes?.name) {
                                      return (
                                        <div className="flexRow meta">
                                          <span
                                            style={{ width: 25, height: 25 }}
                                            className="icon location color"
                                          />
                                          <div style={{ marginLeft: 10 }}>
                                            <strong
                                              style={{
                                                display: "block",
                                                marginRight: 5,
                                                fontSize: 14,
                                                marginBottom: -3,
                                              }}
                                            >
                                              {loc.attributes.name}
                                            </strong>
                                            <span style={{ fontSize: 12 }}>
                                              {addressToString(
                                                loc.attributes.field_address
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    }
                                    return null
                                  })}
                                </>
                              )}
                              {event.description && (
                                <SanitizedHTML
                                  html={he.decode(
                                    event.description.value ?? event.description
                                  )}
                                />
                              )}
                              {event.isObservation && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 10,
                                  }}
                                >
                                  {event.observationUser.field_first_name && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: 20,
                                      }}
                                    >
                                      <div>
                                        {event.observationUser.user_picture.includes(
                                          "default_temp"
                                        ) ||
                                        !event.observationUser.user_picture ? (
                                          <UserInitial
                                            containerStyle={{ marginRight: 10 }}
                                            fontSize={18}
                                            size={30}
                                            initials={
                                              event.observationUser.field_first_name
                                                .substring(0, 1)
                                                .toUpperCase() +
                                              event.observationUser.field_last_name
                                                .substring(0, 1)
                                                .toUpperCase()
                                            }
                                          />
                                        ) : (
                                          <span
                                            className="userImage"
                                            style={{
                                              height: 30,
                                              width: 30,
                                              marginLeft: 3,
                                              backgroundImage:
                                                "url(" +
                                                process.env.REACT_APP_API_URL +
                                                event.observationUser
                                                  .user_picture +
                                                ")",
                                            }}
                                          />
                                        )}
                                      </div>
                                      <strong
                                        style={{ fontSize: 14, marginLeft: 2 }}
                                      >
                                        {event.observationUser.field_first_name}{" "}
                                        {event.observationUser.field_last_name}
                                      </strong>
                                    </div>
                                  )}

                                  {event.isObservation &&
                                    event.field_rubric && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={RubricIcon}
                                          alt="Rubric"
                                          width={25}
                                          style={{
                                            marginRight: 10,
                                            opacity: 0.5,
                                          }}
                                        />
                                        <div>
                                          <strong
                                            style={{
                                              display: "block",
                                              fontSize: 14,
                                            }}
                                          >
                                            {event.field_rubric}
                                          </strong>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}

                              {!event.isObservation && !event.isExternal && (
                                <>
                                  {attendanceLoading && (
                                    <div className="attendanceLoading">
                                      <Loading message="Loading attendance..." />
                                    </div>
                                  )}
                                  {attendance && (
                                    <Box style={{ maxWidth: 300 }}>
                                      <AttendanceSummary
                                        alwaysShowAttended={true}
                                        attendance={{
                                          accept_events:
                                            attendance.accept_events,
                                          attended: attendance.attended,
                                          decline_events:
                                            attendance.decline_events,
                                          no_reply_events:
                                            attendance.no_reply_events,
                                          tentative_events:
                                            attendance.tentative_events,
                                          total_invited:
                                            attendance.total_invited,
                                        }}
                                      />
                                    </Box>
                                  )}
                                </>
                              )}
                            </AccordionDetails>
                          )}
                        </Accordion>
                      </li>
                    )}
                  </>
                )}
              </>
            )
          })}

          <li
            style={{
              opacity: isFetchingLater ? 0.5 : 1,
              pointerEvents: isFetchingLater ? "none" : "all",
            }}
            className="fetchButtonContainer"
          >
            <Button
              className={"button small"}
              onClick={() => fetchMore("next")}
            >
              Load More Events
            </Button>
          </li>
        </ul>
      )}
      {sortedList.length < 1 && calendar.fetched && (
        <p style={{ margin: 0, fontSize: 14, padding: 35 }}>
          {calendar.searchQuery.length ? (
            <em>No upcoming events match your search.</em>
          ) : (
            <em>No events found.</em>
          )}
        </p>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(CalendarListView)
