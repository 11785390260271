import { Tabs, alpha, styled } from "@mui/material"

export const TabsCapsule = styled(Tabs)(({ theme, centered, variant }) => ({
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
  height: 35,
  minHeight: "unset",
  borderRadius: 35,
  width: variant === "fullWidth" ? "100%" : "max-content",
  marginInline: centered ? "auto" : undefined,
  "& .MuiTabs-scroller": {
    height: 35,
    minHeight: "unset",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTab-root": {
    height: 35,
    minHeight: "unset",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: "bold",
    textTransform: "none",
    color: theme.palette.common.black,
    borderRight: "1px solid",
    borderColor: theme.palette.grey.A200,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.grey.A200,
    },
    "&.Mui-selected": {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      },
    },
    "&[disabled]": {
      opacity: theme.palette.action.disabledOpacity,
    },
    ":last-child": {
      borderRight: "none",
    },
  },
}))
