import { Box, Card } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { Link } from "react-router-dom"
import { MarketplaceItem } from "../../features/marketplace/types"
import Carousel from "react-material-ui-carousel"

type MarketPlaceProps = {
  marketplace: MarketplaceItem[]
}

const WidgetMarketplace = (props: MarketPlaceProps) => {
  const { marketplace } = props

  return (
    <Card className="dashboard-widget widget-marketplace">
      <Box
        component="header"
        sx={{ backgroundColor: theme.palette.secondary.main }}
      >
        ReadyMade Marketplace
      </Box>

      {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore */}
      <Carousel
        navButtonsAlwaysInvisible={true}
        indicators={marketplace.length > 1 ? true : false}
        animation={"slide"}
        autoPlay={true}
        interval={5000}
      >
        {marketplace.map((partnerItem: MarketplaceItem, index) => {
          return (
            <Link to={"/marketplace/" + partnerItem.id}>
              <Box
                style={{
                  height: 130,
                  marginBottom: 0,
                  backgroundImage: `url(${process.env.REACT_APP_API_URL}${partnerItem.partner_image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: 0,
                    padding: 5,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(0,0,0,.45)",
                  }}
                >
                  <strong
                    style={{
                      fontSize: 16,
                      color: "white",
                      textShadow: "1px 2px 1px #000",
                    }}
                  >
                    {partnerItem.name}
                  </strong>
                </Box>
              </Box>
            </Link>
          )
        })}
      </Carousel>

      <Box
        component="p"
        sx={{ fontSize: 14, marginTop: -2, padding: "20px 20px 0 20px" }}
      >
        Check out add-on content from our partners to boost your learners'
        achievements!
      </Box>
      <footer
        style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
      >
        <Link to="/marketplace">
          <ButtonSmall>
            <span
              className="icon puzzle-pieces"
              style={{ width: 25, height: 25 }}
            />

            <span>Browse Marketplace</span>
          </ButtonSmall>
        </Link>
      </footer>
    </Card>
  )
}

export default WidgetMarketplace
