import { FormControl, TextField } from "@mui/material"
import { useState } from "react"

import classNames from "classnames"
//import { getCertificate, uploadCertificateImage } from '../actions'
import ButtonSmall from "../../ButtonSmall"

import Facebook from "../../../icons/Facebook"
import LinkedIn from "../../../icons/LinkedIn"
import Twitter from "../../../icons/Twitter"
import { Badge, LearningPath } from "../../../types/explore"
import { UserEventsLearner } from "../../../types/events"

type ShareProps = {
  badge?: Badge
  learningPath?: LearningPath
  event?: UserEventsLearner
  shareUrl: string
}

export default function SocialShare(props: ShareProps) {
  const [isCopying, setIsCopying] = useState(false)
  const { shareUrl, badge, learningPath } = props

  function shareToSocial(type: string) {
    const windowParams =
      "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    let text = ""

    const socialTag = type === "twitter" ? "@MobileMindEDU" : "@MobileMind"

    if (badge) {
      text =
        "I earned a new badge with " +
        socialTag +
        "! Check out the micro-courses I mastered and learn more about MM’s courses below. #mobilemindedu #gsuiteedu"
    } else if (learningPath) {
      text =
        "I completed a " +
        socialTag +
        " learning path! Check out the micro-courses I mastered and learn more about MM’s courses below. #mobilemindedu #gsuiteedu"
    } else if (event) {
      text = "I attended a " + socialTag + " event! #mobilemindedu #gsuiteedu"
    }

    if (type === "facebook") {
      window.open(
        "http://facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(shareUrl) +
          "&quote=" +
          encodeURIComponent(text),
        "",
        windowParams
      )
    } else if (type === "twitter") {
      window.open(
        "http://twitter.com/share?url=" +
          encodeURIComponent("https://mobilemind.io") +
          "&text=" +
          encodeURIComponent(text),
        "",
        windowParams
      )
    } else if (type === "linkedIn") {
      window.open(
        "http://www.linkedin.com/shareArticle?mini=true&url=" +
          encodeURIComponent(shareUrl),
        "sharer",
        windowParams
      )
    }
  }

  // eslint-disable-next-line
  function handleFocus(event: any) {
    event.target.select()
    document.execCommand("copy")

    setIsCopying(true)

    setTimeout(() => {
      setIsCopying(false)
    }, 2000)
  }

  return (
    <>
      <FormControl variant="standard">
        <TextField
          variant="standard"
          onFocus={(event) => {
            handleFocus(event)
          }}
          className={classNames("shareUrl", isCopying && "showHelperText")}
          helperText={"Link copied to clipboard!"}
          value={shareUrl}
        />
      </FormControl>

      <div className="flexRow">
        <ButtonSmall
          onClick={() => {
            shareToSocial("facebook")
          }}
        >
          <Facebook />
          <span style={{ marginLeft: 10 }}>Facebook</span>
        </ButtonSmall>
        <ButtonSmall
          onClick={() => {
            shareToSocial("twitter")
          }}
        >
          <Twitter />
          <span style={{ marginLeft: 10 }}>Twitter</span>
        </ButtonSmall>
        <ButtonSmall
          onClick={() => {
            shareToSocial("linkedIn")
          }}
        >
          <LinkedIn />
          <span style={{ marginLeft: 10 }}>LinkedIn</span>
        </ButtonSmall>
      </div>
    </>
  )
}
