import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import { useFormikContext } from "formik"
import { debounce, isArray } from "lodash"
import { useCallback, useContext, useState } from "react"
import "../../../styles/index.scss"
import { useGetCommunityCourseQuery } from "../common/communityCourseApi"
import { CreateCourseGenieStep } from "../common/types"
import { CreateCourseGenieContext } from "./CreateCourseGenieContext"
import { CreateCourseGenieSearchFormValues } from "./CreateCourseGenieSearch"

type CommunityCourseSearchResultsProps = {
  search: string
}

export const CommunityCourseSearchResults = (
  props: CommunityCourseSearchResultsProps
) => {
  const { setCurrentStep } = useContext(CreateCourseGenieContext)
  const { isSubmitting, values } =
    useFormikContext<CreateCourseGenieSearchFormValues>()
  const [searchValue, setSearchValue] = useState<string | null>(null)

  // Debounce the changes to the search query parameter.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchValue = useCallback(
    debounce((value) => {
      if (value.trim().length > 2) {
        setSearchValue(value.trim())
      } else {
        setSearchValue(null)
      }
    }, 200),
    []
  )
  updateSearchValue(values.search)

  const { data, error, isFetching } = useGetCommunityCourseQuery(
    searchValue ?? skipToken
  )

  if (!searchValue) {
    return null
  }
  if (error) {
    return null
  }
  return (
    <Box>
      <Typography variant="h3" gutterBottom={true}>
        Community Course Suggestions
      </Typography>
      <List
        sx={{
          position: "relative",
          mt: 2,
          mx: -5,
          mb: -4,
          borderTop: 1,
          borderColor: "divider",
        }}
        disablePadding={true}
      >
        {isFetching && (
          <LinearProgress
            sx={{ position: "absolute", top: 0, left: 0, right: 0 }}
          />
        )}
        {isArray(data?.data) ? (
          data?.data.slice(0, 5).map((course) => (
            <ListItem
              key={course.id}
              disableGutters={true}
              disablePadding={true}
            >
              <ListItemButton
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: 2,
                  py: 4,
                  px: 5,
                }}
                disabled={isSubmitting}
                divider={true}
                onClick={() => {
                  setCurrentStep({
                    step: CreateCourseGenieStep.CommunityCourseReview,
                    courseId: course.id,
                  })
                }}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <Box
                    component="img"
                    alt={course.cat_name}
                    className="entityImage course"
                    src={process.env.REACT_APP_API_URL + course.category_image}
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      boxShadow: 4,
                      borderRadius: "100%",
                      border: "2px solid white",
                    }}
                  />
                  <Typography variant="h3" noWrap={true}>
                    {course.name}
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {course.course_content}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <Typography textAlign="center" fontStyle="italic" sx={{ padding: 4 }}>
            No community courses match your search
          </Typography>
        )}
      </List>
    </Box>
  )
}
