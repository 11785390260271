import theme from "../../theme/theme"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import he from "he"
import "./styles/backpack.scss"

import TimeCapsule from "../../components/TimeCapsule"
import moment from "moment"
import { SlideItem } from "./ContentRow"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import { ExternalUserEventsLearner } from "../../types/events"
import BackpackProps from "../../types/backpack"

type modalParams = {
  open: boolean
  event: ExternalUserEventsLearner
}

interface RowProps {
  backpack: BackpackProps
  data: ExternalUserEventsLearner[]
  userId?: number
  setXPDCarousel?: (arg: number) => void
  increaseXPDPage?: (arg: number) => void
  setExternalModal?: (params: modalParams) => void
}

const ExternalRow: React.FC<RowProps> = ({
  data,
  userId,
  backpack,
  setExternalModal,
  setXPDCarousel,
  increaseXPDPage,
}) => {
  const [chunkSize, setChunkSize] = useState(5)
  const dispatch = useDispatch()

  function onResize() {
    if (window.innerWidth < 1600) {
      setChunkSize(3)
    } else {
      setChunkSize(5)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  function nextSlideChanged(event: SlideItem) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setXPDCarousel(event.item > backpack.external.activeIndex ? 1 : -1)
    )
  }

  function nextSlide(event: SlideItem) {
    if (event.itemsInSlide - 2 === event.item) {
      if (backpack.external.currentPage < backpack.external.totalPages - 1) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          increaseXPDPage(event.item)
        )
      }
    }
  }

  const chunkedData = []

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize)
    chunkedData.push(chunk)
  }

  const items = chunkedData.map(
    (chunk: ExternalUserEventsLearner[], index: number) => {
      return (
        <div key={index} className="chunk">
          {chunk.map((event: ExternalUserEventsLearner, index: number) => {
            const totalMinutes = Number(
              event.field_total_time_value.replace("min", "")
            )
            const hours =
              totalMinutes >= 60
                ? Math.floor(totalMinutes / 60).toLocaleString()
                : 0
            const minutes = totalMinutes % 60

            type styleProps = {
              backgroundColor: string
              backgroundImage?: string
              backgroundSize?: string
              backgroundPosition?: string
            }

            const style: styleProps = {
              backgroundColor: theme.palette.secondary.main,
            }

            // replace with Recommended (if at all) image
            if (event.rec_ext_event_image) {
              style.backgroundImage = "url(" + event.rec_ext_event_image + ")"
              style.backgroundSize = "cover"
              style.backgroundPosition = "center"
            }

            return (
              <div
                onClick={() => {
                  if (!userId && setExternalModal) {
                    dispatch(
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setExternalModal({ open: true, event })
                    )
                  }
                }}
                key={index}
                className="item event-item"
              >
                <div className="event-background external" style={style} />
                <div className="capsule-wrapper">
                  <TimeCapsule hours={Number(hours)} minutes={minutes} />
                </div>
                <div style={{ marginTop: 15, textAlign: "center" }}>
                  <span style={{ fontSize: 12, opacity: 0.6 }}>
                    {moment(event.field_start_date_value).format(
                      "MMMM Do, YYYY"
                    )}
                  </span>
                  <strong
                    style={{
                      display: "block",
                      fontSize: 14,
                      lineHeight: 1.2,
                    }}
                  >
                    {he.decode(event.title)}
                  </strong>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  )

  return (
    <div className="inner inner-carousel">
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AliceCarousel
          infinite={true}
          activeIndex={backpack.external.activeIndex}
          disableDotsControls={chunkedData.length === 1}
          paddingRight={25}
          paddingLeft={25}
          autoWidth={true}
          items={items}
          onSlideChange={(event) => nextSlide(event)}
          onSlideChanged={(event) => nextSlideChanged(event)}
        />
      }
    </div>
  )
}

export default ExternalRow
