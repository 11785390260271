import TimeCapsule from "../../components/TimeCapsule"
import moment from "moment"
import React from "react"
import { LearningPath } from "../../types/explore"

interface RowProps {
  data: LearningPath[]
}

const LPRow: React.FC<RowProps> = ({ data }) => {
  return (
    <div className="print-section">
      {data.map((learningPath: LearningPath, index: number) => {
        const totalMinutes = Number(learningPath.field_user_lp_total_time_spent)
        const hours =
          totalMinutes >= 60
            ? Math.floor(totalMinutes / 60).toLocaleString()
            : 0
        const minutes = totalMinutes % 60

        return (
          <div key={index} className="item learning-path-item">
            <img
              className="icon learning-path"
              src={
                process.env.REACT_APP_TEACHER_URL +
                "/static/media/learning-path-black.b042d3dc0447b57206af.svg"
              }
              style={{}}
            />

            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <strong className="title">{learningPath.name}</strong>
              <span style={{ opacity: 0.6, fontSize: 10 }}>
                {moment(learningPath.field_user_lp_updated).format(
                  "MMMM Do, YYYY"
                )}
              </span>
            </div>
            <TimeCapsule hours={Number(hours)} minutes={minutes} />
          </div>
        )
      })}
    </div>
  )
}

export default LPRow
