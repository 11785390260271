import { alpha, createTheme } from "@mui/material/styles"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CSSProperties } from "@mui/styles"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../img/logo.png"
import backgrounds from "./backgrounds"
import themeOverrides from "./themeOverrides"

interface ImagesMixin {
  background: string
  hqBackground?: string
  logo: string
}

interface IntroCourse {
  development: number
  production: number
}

declare module "@mui/material/styles" {
  interface Theme {
    title?: string
    accountHref?: string
    introCourse: IntroCourse
  }
  interface ThemeOptions {
    title?: string
    accountHref?: string
    introCourse?: IntroCourse
  }
  interface Mixins {
    images: ImagesMixin
    backdropBlur: CSSProperties
  }
  interface MixinsOptions {
    images?: Partial<ImagesMixin>
    backdropBlur?: Partial<CSSProperties>
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true
  }
}

// If there's a REACT_APP_DOMAIN key coming from the extension .env, use that, otherwise use hostname
const domain = process.env.REACT_APP_DOMAIN ?? window.location.hostname

function selectBackground() {
  return Math.floor(Math.random() * 18)
}

const background = backgrounds[selectBackground()]

/**
 * Creates a theme object for the MobileMind app.
 * @returns {Object} The theme object.
 */

const theme = createTheme(
  {
    title: "MobileMind",
    palette: {
      primary: {
        main: "#59C3E5",
      },
      secondary: {
        main: "#24DC91",
        light: "#BEF5DE",
      },
      grey: {
        A200: "#EBEBEB",
        A700: "#575757",
      },
    },
    typography: (palette) => {
      return {
        fontFamily: "Nunito, sans-serif",
        h1: {
          fontSize: 22,
          fontWeight: "bold",
        },
        h3: {
          color: "black",
          fontSize: 16,
          textTransform: "none",
          fontWeight: "bold",
        },
        h4: {
          color: alpha(palette.common.black, 0.5),
          fontSize: 12,
          textTransform: "uppercase",
        },
      }
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableRipple: true,
          disableFocusRipple: true,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.common.black,
            "&:hover, &:focus": {
              backgroundColor: alpha(theme.palette.common.white, 0.3),
            },
          }),
          contained: ({ theme }) => ({
            color: theme.palette.secondary.contrastText,
            fontWeight: "bold",
            borderRadius: 40 / 2,
            paddingInline: theme.spacing(5),
            textTransform: "none",
          }),
          containedPrimary: ({ theme }) => ({
            backgroundColor: theme.palette.secondary.main,
            "&:hover, &:focus": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.8),
            },
          }),
          containedSecondary: ({ theme }) => ({
            backgroundColor: theme.palette.grey.A200,
            "&:hover, &:focus": {
              backgroundColor: alpha(theme.palette.grey.A200, 0.8),
            },
          }),
          textSizeSmall: ({ theme }) => ({
            fontSize: 12,
            fontWeight: 700,
            color: theme.palette.primary.main,
            paddingInline: theme.spacing(3),
            paddingBlock: theme.spacing(2),
            textAlign: "left",
            height: 35,
            borderRadius: 35 / 2,
            transition: theme.transitions.create("background-color", {
              duration: theme.transitions.duration.short,
            }),
            "& .MuiButton-startIcon": {
              width: 20,
              height: 20,
            },
            "& .MuiLoadingButton-loadingIndicatorStart": {
              position: "relative",
              left: "unset",
              marginRight: theme.spacing(2),
            },
            "&.MuiLoadingButton-loading": {
              // Hide the startIcon when the loadingIndicator is shown
              "& .MuiButton-startIcon": {
                display: "none",
              },
            },
            "&:hover, &:focus, &.Mui-focusVisible": {
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            },
          }),
        },
      },

      MuiDialogContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(4, 5),
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(5),
            justifyContent: "center",
            gap: theme.spacing(3),
            // Reduce padding on DatePicker action bar
            "&.MuiPickersLayout-actionBar": {
              padding: theme.spacing(2),
            },
          }),
        },
      },

      MuiDivider: {
        styleOverrides: {
          root: {
            textTransform: "uppercase",
            opacity: 0.7,
            fontSize: 12,
            paddingInline: 4,
          },
        },
      },

      MuiFormControl: {
        defaultProps: {
          fullWidth: true,
          margin: "none",
        },
        styleOverrides: {
          marginNormal: ({ theme }) => ({
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(5),
          }),
        },
      },
      MuiStepIcon: {
        defaultProps: {
          color: "secondary",
        },
        styleOverrides: {
          root: ({ theme }) => ({
            fontWeight: "bold",
            "&.Mui-active": {
              color: theme.palette.secondary.main,
            },
          }),
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: ({ theme }) => ({
            fontWeight: "bold!important",
            "&.MuiStepLabel-alternativeLabel": {
              marginTop: theme.spacing(2),
            },
          }),
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
        },
        variants: [
          {
            props: { size: "large" },
            style: ({ theme }) => {
              return {
                marginBottom: theme.spacing(7),
                "& .MuiInput-input": {
                  fontSize: "28px",
                  fontWeight: 600,
                },
                "& .MuiInputLabel-formControl[data-shrink=false]": {
                  transform: "translate(0, 35px) scale(1)",
                },
              }
            },
          },
          {
            props: { size: "small" },
            style: () => {
              return {
                "& .MuiInput-input": {
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: 700,
                },
              }
            },
          },
          {
            props: { size: "medium" },
            style: () => {
              return {
                "& .MuiInput-input": {
                  fontSize: "18px",
                  fontWeight: 700,
                },
              }
            },
          },
        ],
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: ({ theme }) => ({
            color: theme.palette.common.white,
          }),
          tooltip: ({ theme }) => ({
            color: "unset",
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(3),
          }),
        },
      },
    },
    mixins: {
      images: {
        background,
        hqBackground:
          "https://images.pexels.com/photos/12932614/pexels-photo-12932614.jpeg?cs=srgb&dl=pexels-oleksandr-p-12932614.jpg&fm=jpg&w=1920&h=2880",
        logo,
      },
      backdropBlur: {
        backdropFilter: `blur(2px)`,
      },
    },
    shape: {
      borderRadius: 12,
    },
    // Sets the default spacing for components to multiples of 5px
    spacing: 5,
  },
  themeOverrides[domain]
)

export default theme
