import { Component } from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import { Menu, Button } from "@mui/material"
import PropTypes from "prop-types"

import { getAssessment } from "../actions/course"
import {
  deleteNotification,
  setNotificationsFetched,
  getNotifications,
} from "../actions/notifications"

import NotificationPopup from "./NotificationPopup"
import NotificationMenuItem from "./NotificationMenuItem"

const mapDispatchToProps = {
  getNotifications,
  setNotificationsFetched,
  getAssessment,
  deleteNotification,
}

class NotificationsMenu extends Component {
  state = {
    anchorEl: null,
    hasOpened: false,
    seen: [],
    removed: [],
  }

  componentDidMount = () => {
    this.props.getNotifications(this.props.session.user)
    setInterval(() => {
      // Fetch notifications every 5 minutes
      this.props.getNotifications(this.props.session.user)
    }, 300000)
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, hasOpened: true })
    this.props.getNotifications(this.props.session.user)
  }

  handleClose = (badge) => {
    this.setState({ anchorEl: null })

    // Mark notifications as seen
    this.props.setNotificationsFetched(this.props.session.user)

    this.props.notifications.forEach((notification) => {
      setTimeout(() => {
        let seen = [...this.state.seen]
        seen.push(notification.id)
        this.setState({ seen })
      }, 750)
    })
    if (badge && !this.props.isHQ) {
      this.props.setSidebar("achievements", "badgesEarned")
    }
  }

  delete = (notification) => {
    this.props.deleteNotification(notification)
    let removed = [...this.state.removed]
    removed.push(notification.id)
    if (removed.length === this.props.notifications.length) {
      this.setState({ anchorEl: null })
    }
    this.setState({ removed })
  }

  deleteAll = () => {
    let removed = [...this.state.removed]
    this.setState({ anchorEl: null, allRemoved: true })

    setTimeout(() => {
      this.props.notifications.forEach((notification) => {
        removed.push(notification.id)
        this.props.deleteNotification(notification)
      })
      this.setState({ removed })
    }, 1200)
  }

  render() {
    const { notifications, session, onClick } = this.props
    const { anchorEl, removed, seen, allRemoved } = this.state

    let lastFetched = session.user.attributes.field_notifications_last_fetched
    let unseenNotifications = notifications.filter(
      (notification) => !lastFetched || notification.seen !== "1"
    )
    let unseenCount = unseenNotifications.length

    let popUpNotification = unseenNotifications.length
      ? { ...unseenNotifications[0] }
      : { ...notifications[0] }

    let isEmpty = !this.props.notifications.length || allRemoved
    let notificationsReady = unseenCount > 0

    return (
      <div>
        {(notificationsReady || this.props.isHQ) &&
          popUpNotification.notification_id && (
            <NotificationPopup
              isHQ={this.props.isHQ}
              session={session}
              onClick={() => {
                onClick(popUpNotification)
              }}
              notification={popUpNotification}
              getUserGoals={this.props.getUserGoals}
              setSidebar={this.props.setSidebar}
              getLearningPaths={this.props.getLearningPaths}
            />
          )}

        <div
          onClick={this.handleClick}
          className={classNames("notifications", unseenCount > 0 && "swing")}
        >
          {unseenCount > 0 && (
            <div
              className={classNames("count", this.state.hasOpened && "fade")}
            >
              {unseenCount}
            </div>
          )}
        </div>

        <Menu
          id="notification-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <ul className={classNames("notifications-list", { empty: isEmpty })}>
            {notifications.map((notification) => {
              return (
                <NotificationMenuItem
                  {...this.props}
                  deleteNotification={() => {
                    this.delete(notification)
                  }}
                  removed={removed}
                  isHQ={this.props.isHQ}
                  seen={seen}
                  handleClose={this.handleClose}
                  onClick={() => onClick(notification)}
                  key={notification.notification_uuid}
                  notification={notification}
                />
              )
            })}
          </ul>
          {isEmpty ? (
            <div className="message-empty">You have no new notifications.</div>
          ) : (
            <footer>
              <Button onClick={this.deleteAll} className="button small">
                Clear All Notifications
              </Button>
            </footer>
          )}
        </Menu>
      </div>
    )
  }
}

NotificationsMenu.propTypes = {
  assessments: PropTypes.object,
  badges: PropTypes.array,
  courses: PropTypes.array,
  learningPaths: PropTypes.array,
  notifications: PropTypes.array,
  session: PropTypes.object,
}

export default connect(null, mapDispatchToProps)(NotificationsMenu)
