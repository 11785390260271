import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import type { RootState } from "../types"
import _ from "lodash"
import {
  EventFeedbackFormEntity,
  FeedbackFormQuestion,
} from "features/feedbackForms/types"
import qs from "qs"

export const getFeedbackForms = createAsyncThunk<
  Array<Array<EventFeedbackFormEntity>>,
  void,
  { state: RootState }
>(
  "feedbackForms/getFeedbackForms",
  async (args, thunkAPI): Promise<EventFeedbackFormEntity[][]> => {
    const query = {
      include: "field_questions",
      filter: {
        "field_organization.id":
          thunkAPI.getState().session.group.uuid[0].value,
      },
    }

    const response = await fetchWrapper.get(
      "/api/mm_form/event_feedback?" + qs.stringify(query)
    )
    if (response.ok) {
      const data = await response.json()
      data.data.forEach((form: EventFeedbackFormEntity) => {
        form.attributes.field_scale_max = form.attributes.field_scale_max ?? 10
        form.attributes.field_scale_min = form.attributes.field_scale_min ?? 1

        const questions: FeedbackFormQuestion[] = data.included?.filter(
          (included: any) =>
            included.type === "paragraph--likert_scale" ||
            included.type === "paragraph--open_ended_questions"
        )
        if (form.relationships) {
          const formQuestionIds = form.relationships.field_questions.data.map(
            (question: any) => question.id
          )
          form.questions = questions
            ? questions.filter((question: FeedbackFormQuestion) =>
                formQuestionIds.includes(question.id)
              )
            : []
        }
      })

      return _.orderBy(data.data, "attributes.label", "desc")
    }
    return []
  }
)

export const saveFeedbackForm = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("feedbackForms/saveFeedbackForm", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const { editingForm, values } = args

  const formBody: { data: EventFeedbackFormEntity } = {
    data: {
      type: "mm_form--event_feedback",
      attributes: {
        label: values.label,
        field_scale_min: values.field_scale_min,
        field_scale_max: values.field_scale_max,
      },
      relationships: {
        field_questions: {
          data: [],
        },
        field_organization: {
          data: {
            type: "group--organization",
            id: session.group.uuid[0].value,
          },
        },
      },
    },
  }

  let initialFormResponse
  if (editingForm.id) {
    formBody.data.id = editingForm.id
    initialFormResponse = await fetchWrapper.patch(
      "/api/mm_form/event_feedback/" + editingForm.id,
      session.token,
      JSON.stringify(formBody)
    )
  } else {
    initialFormResponse = await fetchWrapper.post(
      "/api/mm_form/event_feedback/",
      session.token,
      JSON.stringify(formBody)
    )
  }

  const initialFormData = await initialFormResponse.json()

  const numQuestions = values.questions.length
  let i = 0
  const field_questions_data = []

  while (i < numQuestions) {
    const activeQuestion = values.questions[i]
    const type = activeQuestion.type.replace("--", "/")

    const {
      field_question_name,
      field_minimum_label,
      field_maximum_label,
      field_required,
    } = activeQuestion.attributes

    const questionBody: { data: FeedbackFormQuestion } = {
      data: {
        type: type.replace("/", "--"),
        attributes: {
          field_question_name,
          field_minimum_label,
          field_maximum_label,
          field_required,
          parent_field_name: "field_questions",
          parent_type: "mm_form",
          parent_id: initialFormData.data.attributes.drupal_internal__id,
        },
      },
    }

    let url = "/api/" + type
    let response
    if (activeQuestion.id) {
      questionBody.data.id = activeQuestion.id
      url += "/" + activeQuestion.id

      response = await fetchWrapper.patch(
        url,
        session.token,
        JSON.stringify(questionBody)
      )
    } else {
      response = await fetchWrapper.post(
        url,
        session.token,
        JSON.stringify(questionBody)
      )
    }

    if (response.ok) {
      let data = await response.json()
      field_questions_data.push({
        id: data.data.id,
        type: data.data.type,
        meta: {
          drupal_internal__target_id: data.data.attributes.drupal_internal__id,
          target_revision_id: data.data.attributes.drupal_internal__revision_id,
        },
      })
    }
    i++
  }

  if (formBody.data.relationships) {
    formBody.data.relationships.field_questions.data = field_questions_data
  }
  if (!editingForm.id) {
    formBody.data.id = initialFormData.data.id
  }

  const formResponse = await fetchWrapper.patch(
    "/api/mm_form/event_feedback/" + initialFormData.data.id,
    session.token,
    JSON.stringify(formBody)
  )

  if (formResponse.ok) {
    await thunkAPI.dispatch(getFeedbackForms())
  }
})

type InitialState = {
  isOpen: boolean
  fetched: boolean
  data: EventFeedbackFormEntity[]
}

const initialState: InitialState = {
  isOpen: false,
  fetched: false,
  data: [],
}

export const feedbackForms = createSlice({
  name: "feedbackForms",
  initialState,
  reducers: {
    setFeedbackFormsModalOpen: (state, action) => {
      state.isOpen = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeedbackForms.fulfilled, (state, action: any) => {
      state.fetched = true
      state.data = action.payload
    })
  },
})

export const { setFeedbackFormsModalOpen } = feedbackForms.actions

export default feedbackForms.reducer
