import classNames from "classnames"
import theme from "../theme/theme"

type ProgressBarProps = {
  total?: number
  completed?: number
  width?: number
}

const ProgressBar = ({ total, completed, width }: ProgressBarProps) => {
  const lengthArray = new Array(total)
  const completedArray = new Array(completed)
  return (
    <div
      className="progressBarWrapper"
      style={
        width || width === 0 ? { borderRadius: 12, height: 12 } : { height: 12 }
      }
    >
      {width || width === 0 ? (
        <div className="progressBar">
          <div
            className="progressBarInner"
            style={{
              transition: ".3s",
              maxWidth: width + "%",
              backgroundColor: theme ? theme.palette.secondary.main : "",
            }}
          />
        </div>
      ) : (
        <>
          {lengthArray.length > 0 &&
            lengthArray.map((item, index) => {
              const isCorrect =
                completedArray.length > 0 && completedArray[index]
              const backgroundColor = isCorrect && theme.palette.secondary.main

              return (
                <div
                  key={index}
                  style={{
                    animationDelay: index * 0.14 + "s",
                    backgroundColor,
                  }}
                  className={classNames("progressItem", !isCorrect && "fail")}
                />
              )
            })}
        </>
      )}
    </div>
  )
}

export default ProgressBar
