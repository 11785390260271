import sanitizeHtml from "sanitize-html"
import PropTypes from "prop-types"
import linkifyHtml from "linkifyjs/html"

function SanitizedHTML(props: { html: string; quote?: string }) {
  const html = props.quote ? '"' + props.html + '"' : props.html

  // Fix for poorly added links
  return (
    <>
      {html ? (
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(
              linkifyHtml(
                html
                  .replaceAll('href="', 'href="https://')
                  .replaceAll("https://https://", "https://")
              )
            ),
          }}
        ></p>
      ) : (
        <p></p>
      )}
    </>
  )
}

SanitizedHTML.propTypes = {
  html: PropTypes.node,
}

export default SanitizedHTML
