import { Box, FormHelperText, Switch, styled } from "@mui/material"

type SettingSwitchProps = {
  className?: string
  onChange: (value: boolean) => void
  checked: boolean
  label: string
  name?: string
  helperText?: string
  disabled?: boolean
}

export const SettingSwitch = styled((props: SettingSwitchProps) => {
  const { className, onChange, checked, label, name, disabled, helperText } =
    props

  return (
    <div className={className}>
      <Box sx={{ flex: 1, fontSize: 14 }}>
        <strong>{label}</strong>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
      <Switch
        disabled={disabled}
        checked={checked}
        color="secondary"
        name={name}
        onClick={() => {
          onChange(!checked)
        }}
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </div>
  )
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}))
