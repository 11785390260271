import { Component } from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import moment from "moment"
import { MenuItem } from "@mui/material"
import PropTypes from "prop-types"
import { generateTimestamp } from "../functions"
import theme from "../theme/theme"

const mapStateToProps = ({ session }, ownProps) => {
  let notification = ownProps.notification
  let type = notification.type

  let isExternalURL, path, content

  if (type === "reviewer") {
    path = process.env.REACT_APP_HQ_URL + "/review"
    content = (
      <>
        You&apos;ve been added as a reviewer for{" "}
        <strong>{notification.course_name}</strong>.
      </>
    )
  }

  if (type === "ulp_complete") {
    content = (
      <>
        You completed <strong>{notification.learning_path_name}</strong>!
      </>
    )
    path = process.env.REACT_APP_TEACHER_URL + "/achievements"
  }

  if (type === "user_goal_complete") {
    content = (
      <>
        You finished <strong>{notification.learning_path_name}</strong> and
        achieved your goal!
      </>
    )
  }

  if (type === "assessment_complete" || type === "assessment_feedback") {
    if (type === "assessment_complete") {
      content = (
        <>
          You completed <strong>{notification.course_name}</strong>!
        </>
      )
      path = process.env.REACT_APP_TEACHER_URL + "/achievements"
    }
    if (type === "assessment_feedback") {
      content =
        notification.course_submission_type === "Quiz" ? (
          <>
            You didn&apos;t pass the quiz for{" "}
            <strong>{notification.course_name}</strong>.
          </>
        ) : (
          <>
            Feedback available for <strong>{notification.course_name}</strong>.
          </>
        )
      path = process.env.REACT_APP_TEACHER_URL + "/in-progress"
    }
  }

  if (type === "user_badge_created") {
    content = (
      <>
        You earned the <strong>{notification.badge_name}</strong> badge!
      </>
    )
    path = process.env.REACT_APP_TEACHER_URL + "/achievements"
  }

  if (type === "event_personnel") {
    content = <>You&apos;ve been added as personnel to an event.</>
    path = process.env.REACT_APP_TEACHER_URL + "/sync/"
  }

  return {
    session,
    path,
    isExternalURL,
    content,
  }
}

function NotificationMenuItem(props) {
  return <NotificationItem {...props} theme={theme} />
}

class NotificationItem extends Component {
  handleClick = () => {
    const { notification } = this.props

    this.setState({ hasOpened: true })
    this.props.handleClose(notification.type === "user_badge_created")
  }

  delete = (notification) => {
    this.props.deleteNotification(notification)
  }

  render() {
    const { notification, session, isExternalURL } = this.props
    const { removed, path, content, seen } = this.props

    let lastFetched = session.user.attributes.field_notifications_last_fetched

    let isSeen =
      moment(lastFetched).isAfter(notification.created) ||
      seen.includes(notification.id)

    let innerContent = (
      <>
        {content && (
          <>
            <div
              className={classNames(
                "icon",
                notification.type,
                notification.type === "assessment_feedback" &&
                  notification.course_submission_type === "Quiz" &&
                  "quiz"
              )}
            />
            <div className="content">
              <div>{content}</div>
              <div className="timestamp">
                {generateTimestamp(notification.created)}
              </div>
            </div>
          </>
        )}
      </>
    )

    return (
      <>
        <MenuItem key={notification.id}>
          {isExternalURL ? (
            <a
              style={{
                borderLeft: !isSeen
                  ? "5px solid " + theme.palette.secondary.main
                  : "none",
              }}
              className={classNames("notification-item", {
                removed: removed.includes(notification.id),
              })}
              href={path}
            >
              {innerContent}
            </a>
          ) : (
            <div
              onClick={() => {
                this.handleClick(path)
              }}
              className={classNames(
                "notification-item",
                { new: !isSeen },
                { removed: removed.includes(notification.id) }
              )}
            >
              {innerContent}
            </div>
          )}
          <div
            onClick={() => {
              this.delete(notification)
            }}
            className="icon remove"
          />
        </MenuItem>
      </>
    )
  }
}

NotificationMenuItem.propTypes = {
  assessments: PropTypes.object,
  badges: PropTypes.object,
  learningPaths: PropTypes.array,
  notification: PropTypes.object,
  courses: PropTypes.array,
}

export default connect(mapStateToProps)(NotificationMenuItem)
