import pluralize from "pluralize"

import EventsRow from "./EventsRow"
import EventsPrintRow from "./EventsPrintRow"

import ExternalRow from "./ExternalRow"
import ExternalPrintRow from "./ExternalPrintRow"

import CoursesRow from "./CoursesRow"
import CoursesPrintRow from "./CoursesPrintRow"

import BadgesRow from "./BadgesRow"
import BadgesPrintRow from "./BadgesPrintRow"

import LPRow from "./LPRow"
import LPPrintRow from "./LPPrintRow"

import UserRubricRow from "./UserRubricRow"

import ButtonSmall from "../../components/ButtonSmall"
import "./styles/backpack.scss"
import BackpackProps from "../../types/backpack"
import { Categories } from "../../types/taxonomy/category"
import { Badge, Course, LearningPath } from "../../types/explore"
import {
  ExternalUserEventsLearner,
  UserEventsLearner,
} from "../../types/events"
import UserRubricPrintRow from "./UserRubricPrintRow"
import { MandatedTrainingHistory } from "../../types/mandatedTraining"
import { MandatedTrainingRow } from "./MandatedTrainingRow"
import { MandatedTrainingPrintRow } from "./MandatedTrainingPrintRow"

interface BaseContentRowProps {
  animationDelay: number
  backpack: BackpackProps
  categories: Categories
  changeNavbar?: (tab: string) => void
  goTo?: (location: string) => void
  print: boolean
  userId?: number
  total: number
  // eslint-disable-next-line
  session: any

  setEventsCarousel: (index: number) => void
  increaseEventsPage: (item: number) => void

  setMandatedTrainingCarousel: (index: number) => void
  increaseMandatedTrainingPage: (item: number) => void

  setXPDCarousel?: (index: number) => void
  increaseXPDPage?: (item: number) => void

  setBadgeCarousel: (index: number) => void
  increaseBadgePage: (item: number) => void

  setCourseCarousel: (index: number) => void
  increaseCoursePage: (item: number) => void

  setLPCarousel: (index: number) => void
  increaseLPPage: (item: number) => void

  setRubricCarousel: (item: number) => void
  increaseRubricPage: (item: number) => void
  fetchSingleUserRubric?: (userRubricId: string) => void

  setDateMax: (param: string) => void
  setDateMin: (param: string) => void
  // eslint-disable-next-line
  setExternalModal: any
  setModalConferenceId?: (id: string) => void
}

interface EventsContentRowProps extends BaseContentRowProps {
  contentType: "events"
  data: UserEventsLearner[]
}
interface MandatedTrainingContentRowProps extends BaseContentRowProps {
  contentType: "mandatedTraining"
  data: MandatedTrainingHistory[]
}

interface ExternalContentRowProps extends BaseContentRowProps {
  contentType: "external"
  data: ExternalUserEventsLearner[]
}

interface BadgeContentRowProps extends BaseContentRowProps {
  contentType: "badges"
  data: Badge[]
}

interface CourseContentRowProps extends BaseContentRowProps {
  contentType: "courses"
  data: Course[]
}

interface LearningPathContentRowProps extends BaseContentRowProps {
  contentType: "learningPaths"
  data: LearningPath[]
}

interface UserRubricContentRowProps extends BaseContentRowProps {
  contentType: "userRubrics"
  // fix this once the view comes in
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
}

type ContentRowProps =
  | EventsContentRowProps
  | MandatedTrainingContentRowProps
  | ExternalContentRowProps
  | CourseContentRowProps
  | BadgeContentRowProps
  | LearningPathContentRowProps
  | UserRubricContentRowProps

export type SlideItem = {
  isNextSlideDisabled: boolean
  isPrevSlideDisabled: boolean
  item: number
  itemsInSlide: number
  slide: number
  type: string
}

const ContentRow = (props: ContentRowProps) => {
  const { total, contentType, print, goTo } = props

  let href: string = contentType
  if (contentType === "learningPaths") {
    href = "learning-paths"
  }

  return (
    <div
      className="content-row"
      style={{ animationDelay: props.animationDelay * 0.2 + "s" }}
    >
      <header>
        <div className="count">
          {contentType === "events" && (
            <>
              {print ? (
                <img
                  className="icon calendar color"
                  src={
                    process.env.REACT_APP_TEACHER_URL +
                    "/static/media/calendar-color.116f406d011fba360bc7.svg"
                  }
                />
              ) : (
                <span className="icon calendar color" />
              )}
            </>
          )}
          {contentType === "external" && (
            <>
              {print ? (
                <img
                  className="icon external-pd color"
                  src={
                    process.env.REACT_APP_TEACHER_URL +
                    "/static/media/external-pd-color.73e4e3c22f8129c2d048.svg"
                  }
                />
              ) : (
                <span className="icon external-pd color" />
              )}
            </>
          )}
          {contentType === "courses" && (
            <>
              {print ? (
                <img
                  className="icon laptop color"
                  src={
                    process.env.REACT_APP_TEACHER_URL +
                    "/static/media/laptop-color.17d495c1fa57b1221508.svg"
                  }
                />
              ) : (
                <span className="icon laptop color" />
              )}
            </>
          )}
          {contentType === "badges" && (
            <>
              {print ? (
                <img
                  className="icon badge color"
                  src={
                    process.env.REACT_APP_TEACHER_URL +
                    "/static/media/i-badge.bd407cc7b8d0c1555981.svg"
                  }
                />
              ) : (
                <span className="icon badge color" />
              )}
            </>
          )}
          {contentType === "learningPaths" && (
            <>
              {print ? (
                <img
                  className="icon learningPath color"
                  src={
                    process.env.REACT_APP_TEACHER_URL +
                    "/static/media/learning-path.b519d1674f57d53b8cb5.svg"
                  }
                />
              ) : (
                <span className="icon learningPath color" />
              )}
            </>
          )}
          {contentType === "userRubrics" && (
            <>
              {print ? (
                <img
                  className="icon rubric-report color"
                  src={
                    process.env.REACT_APP_TEACHER_URL +
                    "/static/media/pencil-ruler-color.36e531f2dc403e33a247.svg"
                  }
                />
              ) : (
                <span className="icon rubric-report color" />
              )}
            </>
          )}
          {contentType === "mandatedTraining" && (
            <>
              {print ? (
                <img
                  className="icon mandated color"
                  src={
                    process.env.REACT_APP_TEACHER_URL +
                    "/static/media/mandatory.2cfde86c59df433d1edb.svg"
                  }
                />
              ) : (
                <span className="icon mandated color" />
              )}
            </>
          )}

          <strong>{total}</strong>

          {contentType === "events" && (
            <span>{pluralize("Event", total)} Attended</span>
          )}
          {contentType === "external" && (
            <span>External {pluralize("Approval", total)}</span>
          )}
          {contentType === "courses" && (
            <span>{pluralize("Course", total)} Completed</span>
          )}
          {contentType === "badges" && (
            <span>{pluralize("Badge", total)} Earned</span>
          )}
          {contentType === "learningPaths" && (
            <span>Learning {pluralize("Path", total)} Completed</span>
          )}
          {contentType === "userRubrics" && (
            <span>{pluralize("Rubric", total)} Completed</span>
          )}
          {contentType === "mandatedTraining" && (
            <span>{pluralize("Mandated Training", total)} Completed</span>
          )}
        </div>

        {!props.userId &&
          goTo &&
          contentType !== "userRubrics" &&
          contentType !== "mandatedTraining" && (
            <ButtonSmall onClick={() => goTo("/backpack/" + href)}>
              View All
            </ButtonSmall>
          )}
      </header>

      {contentType === "events" && (
        <>{print ? <EventsPrintRow {...props} /> : <EventsRow {...props} />}</>
      )}
      {contentType === "external" && (
        <>
          {print ? <ExternalPrintRow {...props} /> : <ExternalRow {...props} />}
        </>
      )}
      {contentType === "courses" && (
        <>
          {print ? <CoursesPrintRow {...props} /> : <CoursesRow {...props} />}
        </>
      )}
      {contentType === "badges" && (
        <>{print ? <BadgesPrintRow {...props} /> : <BadgesRow {...props} />}</>
      )}

      {contentType === "learningPaths" && (
        <>{print ? <LPPrintRow {...props} /> : <LPRow {...props} />}</>
      )}

      {contentType === "userRubrics" && (
        <>
          {print ? (
            <UserRubricPrintRow {...props} />
          ) : (
            <UserRubricRow {...props} />
          )}
        </>
      )}
      {contentType === "mandatedTraining" && (
        <>
          {print ? (
            <MandatedTrainingPrintRow {...props} />
          ) : (
            <MandatedTrainingRow {...props} />
          )}
        </>
      )}
    </div>
  )
}

export default ContentRow
