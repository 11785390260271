import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import type { RootState } from "../types"
import qs from "qs"
import moment from "moment"
import { getOrgRubricById } from "@mobilemind/common/src/api/rubricApi"
import { OrgRubric } from "@mobilemind/common/src/types/rubrics"

export const getSingleRubric = createAsyncThunk<
  { rubric: OrgRubric; user: unknown },
  { rubricId: string; userId: string }
>("rubrics/getSingleRubric", async (args, thunkAPI) => {
  const { rubricId, userId } = args
  // @ts-ignore
  const session = thunkAPI.getState().session

  let user
  let query = {
    uid: userId,
    role: "all",
  }

  let userResponse = await fetchWrapper.get(
    "/api/organization-members/" +
      session.group.id[0].value +
      "?" +
      qs.stringify(query)
  )

  if (userResponse.ok) {
    let userData = await userResponse.json()

    user = {
      firstName: userData.rows[0]?.field_first_name,
      lastName: userData.rows[0]?.field_last_name,
      userPicture:
        process.env.REACT_APP_API_URL + userData.rows[0]?.user_picture,
      group: userData.rows[0]?.field_subgroup,
      uuid: userData.rows[0]?.uuid,
    }
  }

  const rubric = await thunkAPI
    .dispatch(getOrgRubricById.initiate(rubricId))
    .unwrap()
  return { rubric, user }
})

export const submitRubric = createAsyncThunk<any, any, { state: RootState }>(
  "rubrics/submitRubric",
  async (args, thunkAPI) => {
    const { activeRubric, userData } = thunkAPI.getState().rubrics
    const { token, user } = thunkAPI.getState().session
    const { userEventUUID, firstName, lastName, userUUID } = args

    // Post up the user rubric first
    let body = {
      data: {
        type: "mm_user_rubric--mm_user_rubric",
        attributes: {
          title: activeRubric.data.name + " for " + firstName + " " + lastName,
          field_completed_date: moment().format(),
          field_total_score: activeRubric.totalScore,
        },
        relationships: {
          field_learner: {
            data: {
              type: "user--user",
              id: userData.uuid ?? userUUID,
            },
          },
          field_reviewer: {
            data: {
              type: "user--user",
              id: user.id,
            },
          },
          field_rubric: {
            data: {
              type: "mobilemind_rubric--mobilemind_rubric",
              id: activeRubric.data.uuid,
            },
          },
        },
      },
    }

    if (userEventUUID) {
      // @ts-ignore
      body.data.relationships.field_user_event = {
        data: {
          type: "mobile_mind_user_event--observation",
          id: userEventUUID,
        },
      }
    }

    let initial = await fetchWrapper.post(
      "/api/mm_user_rubric/mm_user_rubric",
      token,
      JSON.stringify(body)
    )

    if (initial.ok) {
      const userRubricData = await initial.json()

      if (userEventUUID) {
        const userEventPatch = {
          data: {
            type: "mobile_mind_user_event--observation",
            id: userEventUUID,
            relationships: {
              field_user_rubric: {
                data: {
                  type: "mm_user_rubric--mm_user_rubric",
                  id: userRubricData.data.id,
                },
              },
            },
          },
        }

        // Stick the user rubric ID on the user event
        await fetchWrapper.patch(
          "/api/mobile_mind_user_event/observation/" + userEventUUID,
          token,
          JSON.stringify(userEventPatch)
        )
      }

      // Then post all of our rubric criteria paragraphs
      const rubricCriteria: any[] = []
      activeRubric.data.domain.forEach((domain: any) => {
        domain.criteria.forEach((criteria: any) => {
          rubricCriteria.push(criteria)
        })
      })

      let i = 0
      const postedCriteria: string[] = []

      while (i < rubricCriteria.length) {
        let targetCriteria = rubricCriteria[i]

        let criteriaBody = JSON.stringify({
          data: {
            type: "paragraph--rubric_criteria",
            attributes: {
              parent_id: userRubricData.data.attributes.drupal_internal__id,
              parent_type: "mm_user_rubric",
              parent_field_name: "field_criteria",
              field_points:
                targetCriteria.score !== "unselected"
                  ? targetCriteria.score
                  : null,
              field_weight: Number(targetCriteria.weight),
              field_comment: targetCriteria.comments,
            },
            relationships: {
              field_criteria: {
                data: {
                  type: "mm_rubric_criteria--mm_rubric_criteria",
                  id: targetCriteria.uuid,
                },
              },
            },
          },
        })

        let criteriaResponse = await fetchWrapper.post(
          "/api/paragraph/rubric_criteria",
          token,
          criteriaBody
        )

        if (criteriaResponse.ok) {
          let data = await criteriaResponse.json()
          postedCriteria.push(data.data)
        }
        i++
      }

      // Then stick all of those IDs on the user rubric
      const criteriaPatch = JSON.stringify({
        data: {
          type: "mm_user_rubric--mm_user_rubric",
          id: userRubricData.data.id,
          relationships: {
            field_criteria: {
              data: postedCriteria.map((criteria: any) => {
                return {
                  type: "paragraph--rubric_criteria",
                  id: criteria.id,
                  meta: {
                    target_revision_id:
                      criteria.attributes.drupal_internal__revision_id,
                    drupal_internal__target_id:
                      criteria.attributes.drupal_internal__id,
                  },
                }
              }),
            },
          },
        },
      })

      await fetchWrapper.patch(
        "/api/mm_user_rubric/mm_user_rubric/" + userRubricData.data.id,
        token,
        criteriaPatch
      )
    }
  }
)

type InitialState = {
  fetched: boolean
  data: any[]
  userData: {
    firstName: string
    lastName: string
    userPicture: string
    group: string
    uuid: string
  }
  activeRubric: {
    submitting: boolean
    fetched: boolean
    totalScore: number
    // Set this up later
    data: any
  }
}

const initialState: InitialState = {
  fetched: false,
  data: [],
  userData: {
    firstName: "",
    lastName: "",
    userPicture: "",
    group: "",
    uuid: "",
  },
  activeRubric: {
    submitting: false,
    fetched: false,
    totalScore: 0,
    data: {},
  },
}

export const rubrics = createSlice({
  name: "rubrics",
  initialState,
  reducers: {
    updateCriteriaScore: (state, action) => {
      const { domainIndex, criteriaIndex, points } = action.payload
      state.activeRubric.data.domain[domainIndex].criteria[
        criteriaIndex
      ].score = Number(points)
    },
    clearActiveRubric: (state) => {
      state.activeRubric = initialState.activeRubric
      state.userData = initialState.userData
    },
    setCriteriaNA: (state, action) => {
      const { domainIndex, criteriaIndex } = action.payload
      let targetCriteria =
        state.activeRubric.data.domain[domainIndex].criteria[criteriaIndex]

      targetCriteria.isNA = !targetCriteria.isNA
      targetCriteria.score = "unselected"
    },
    setCriteriaCommentsActive: (state, action) => {
      const { domainIndex, criteriaIndex } = action.payload
      let targetCriteria =
        state.activeRubric.data.domain[domainIndex].criteria[criteriaIndex]
      if (targetCriteria.commentsActive) {
        targetCriteria.comments = ""
      }
      targetCriteria.commentsActive = !targetCriteria.commentsActive
    },
    setCriteriaComments: (state, action) => {
      const { domainIndex, criteriaIndex, value } = action.payload
      let targetCriteria =
        state.activeRubric.data.domain[domainIndex].criteria[criteriaIndex]
      targetCriteria.comments = value
    },
    updateTotalScore: (state: any) => {
      let total = 0
      state.activeRubric.data.domain.forEach((domain: any) => {
        domain.criteria.forEach((criteria: any) => {
          if (Number(criteria.score)) {
            const weight = criteria.weight.length ? Number(criteria.weight) : 1
            total += criteria.score * weight
          }
        })
      })
      state.activeRubric.totalScore = total
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitRubric.pending, (state) => {
      state.activeRubric.submitting = true
    })
    builder.addCase(submitRubric.fulfilled, (state) => {
      state.activeRubric = initialState.activeRubric
      state.userData = initialState.userData
    })

    builder.addCase(getSingleRubric.pending, (state) => {
      state.activeRubric.fetched = false
    })
    builder.addCase(getSingleRubric.fulfilled, (state, action: any) => {
      const { rubric, user } = action.payload

      state.activeRubric.fetched = true
      state.userData = user

      state.activeRubric.data = rubric
    })
  },
})

export const {
  clearActiveRubric,
  setCriteriaComments,
  setCriteriaCommentsActive,
  setCriteriaNA,
  updateCriteriaScore,
  updateTotalScore,
} = rubrics.actions

export default rubrics.reducer
