import React, { Component } from "react"
import { connect } from "react-redux"

import { changeTab } from "../../../store/reducers/navbar"

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"

import { Link } from "react-router-dom"
import { getOrgBadges } from "../../../actions/badges"

import UserInitial from "@mobilemind/common/src/components/UserInitial"
import classNames from "classnames"
import he from "he"

import {
  fetchActiveEvent,
  getRooms,
  updateField,
  getUserEvents,
  changeEventTab,
  fetchEventMessages,
  fetchRoles,
  updateEventSortBy,
  updateEventSortOrder,
  fetchFeedbackSummary,
} from "./activeEventSlice"

import { SidebarLocationMap } from "@mobilemind/common/src/components/events/SidebarLocationMap"
import { SidebarLocationsAccordion } from "@mobilemind/common/src/components/events/SidebarLocationsAccordion"

import {
  updateConferenceSortBy,
  updateConferenceSortOrder,
  fetchConferenceFeedbackSummary,
  getRoomsForConferenceLocation,
} from "../conference/activeConferenceSlice"

import {
  createEventMessage,
  deleteEventMessage,
  updatePDCredit,
} from "../../../actions/events"

import { getCategories } from "../../../store/reducers/categories"
import moment from "moment"
import pluralize from "pluralize"

import { getSubGroups, getJobTitles } from "../../../store/reducers/session"
import Loading from "@mobilemind/common/src/components/Loading"
import SanitizeHTML from "@mobilemind/common/src/components/SanitizedHTML"

import {
  changeConferenceTab,
  editConferenceEvent,
  fetchConferenceMessages,
  updateConferenceInviteeSearch,
  updateConferenceField,
  fetchActiveConference,
  fetchSessionsForConference,
  getConferenceUserEvents,
} from "../conference/activeConferenceSlice"

import { convertEstimatedTime } from "@mobilemind/common/src/functions"

import "../../../styles/index.scss"
import "../../../styles/event-live-view.scss"

import Rating from "@mobilemind/common/src/components/Rating"
import ReactQuill from "react-quill"

import ConferenceSchedule from "../conference/ConferenceSchedule"

import "react-quill/dist/quill.snow.css"

import ErrorBoundary from "../../../components/ErrorBoundary"

import { withRouter } from "react-router-dom"
import EventParticipants from "./EventParticipants"
import ConferenceAttendanceSummary from "../conference/ConferenceAttendanceSummary"
import { useAppDispatch } from "store/hooks"
import EventFeedbackSummary from "./EventFeedbackSummary"
import { formats, modules } from "../../../constants/reactQuillConfig"
import RubricLayout from "features/rubrics/RubricLayout"
import { EventRubricCompleted } from "./EventRubricCompleted"

const mapStateToProps = (
  {
    categories,
    calendar,
    entityList,
    courses,
    session,
    activeEvent,
    activeConference,
    jobTitles,
  },
  ownProps
) => {
  const isConference = ownProps.isConference
  const isSession =
    activeEvent.isSession || ownProps.match.path.includes("session")
  const isObservation =
    activeEvent.isObservation || ownProps.match.path.includes("observation")

  let editUrl
  if (isConference) {
    editUrl = "/events/conference/" + activeConference.drupal_internal__id
  } else if (isSession) {
    editUrl = "/events/view/conference/" + activeConference.drupal_internal__id
    if (ownProps.match.path.includes("session")) {
      editUrl = "/events/session/" + activeEvent.drupal_internal__id
    }
  } else if (isObservation) {
    editUrl = "/events/observation/" + activeEvent.drupal_internal__id
  } else {
    editUrl = "/events/event/" + activeEvent.drupal_internal__id
  }

  const { orgRoles, groupRoles } = session
  const allRoles = orgRoles.concat(groupRoles).join(",")
  const eventRef = isConference ? activeConference : activeEvent

  let targetPersonnel =
    eventRef.personnel &&
    eventRef.personnel.find((user) => user.id === session.user.id)
  let eventRole = targetPersonnel && targetPersonnel.field_event_role_name

  let targetConferencePersonnel =
    isSession &&
    activeConference.personnel &&
    activeConference.personnel.find((user) => user.id === session.user.id)
  let conferenceRole =
    targetConferencePersonnel && targetConferencePersonnel.field_event_role_name

  let eventId = ownProps.match.params.id

  let canSchedule =
    allRoles.includes("admin") ||
    allRoles.includes("observer") ||
    allRoles.includes("scheduler") ||
    eventRole === "Manager" ||
    eventRole === "Presenter" ||
    conferenceRole === "Manager" ||
    conferenceRole === "Presenter"

  return {
    returnTab: ownProps.match.params.returnTab,
    canViewTabs: canSchedule || eventRole ? true : false,
    targetPersonnel,
    eventRole,
    canSchedule,
    categories,
    calendar,
    editUrl,
    isConference,
    isSession,
    isObservation,
    eventId,
    orgBadges: entityList.orgBadges,
    courses,
    session,
    activeConference,
    activeEvent: ownProps.isConference ? activeConference : activeEvent,
    jobTitles,
  }
}

const mapDispatchToProps = {
  changeTab,
  changeConferenceTab,
  fetchActiveEvent,
  fetchActiveConference,
  fetchSessionsForConference,
  getConferenceUserEvents,
  getCategories,
  changeEventTab,
  getRoomsForConferenceLocation,
  getOrgBadges,
  getRooms,
  getSubGroups,
  fetchEventMessages,
  fetchConferenceMessages,
  getJobTitles,
  updateConferenceInviteeSearch,
  editConferenceEvent,
  updateField,
  updateConferenceField,
  getUserEvents,
  updatePDCredit,
  fetchFeedbackSummary,
  fetchConferenceFeedbackSummary,
  createEventMessage,
  updateConferenceSortBy,
  updateConferenceSortOrder,
  updateEventSortBy,
  updateEventSortOrder,
  deleteEventMessage,
  fetchRoles,
}

function SidebarEvent(props) {
  const {
    activeEvent,
    isSession,
    targetPersonnel,
    canViewTabs,
    isConference,
    canSchedule,
    editUrl,
    canViewEditLinks,
  } = props

  const dispatch = useAppDispatch()

  let pdMinutes =
    Number(activeEvent.pdCredit.hours * 60) +
    Number(activeEvent.pdCredit.minutes)

  function changeTab(tab) {
    window.scrollTo(0, 0)
    if (props.isConference) {
      dispatch(changeConferenceTab(tab))
    } else {
      dispatch(changeEventTab(tab))
    }
  }

  function editEvent(tab, event) {
    if (isSession) {
      dispatch(editConferenceEvent(activeEvent))
    }
    dispatch(updateField({ field: "currentTab", value: tab }))
  }

  let availableRooms = activeEvent.availableRooms
    ? activeEvent.availableRooms
    : []
  availableRooms = isConference
    ? props.activeConference.availableRooms
    : availableRooms

  const currentRoom =
    availableRooms &&
    availableRooms.find((room) => room.id === activeEvent.eventRoom)

  let formattedDateSecond

  let { startDate, endDate } = activeEvent

  let formattedDate = moment(startDate).format("dddd, MMMM Do, YYYY")

  // If this is not a multi day event
  if (formattedDate === moment(endDate).format("dddd, MMMM Do, YYYY")) {
    // And all day is not checked
    if (!activeEvent.allDay) {
      formattedDate +=
        " " +
        moment(startDate).format("h:mma") +
        " - " +
        moment(endDate).format("h:mma")
    }
  }
  // Otherwise if this event spans more than one day
  else {
    formattedDate += " " + moment(startDate).format("h:mma")
    formattedDateSecond = moment(endDate).format("dddd, MMMM Do, YYYY")
    formattedDateSecond += " " + moment(endDate).add().format("h:mma")
  }

  const isSessionFullView =
    window.location.href.includes("session") &&
    !props.activeConference.id &&
    (!targetPersonnel || !targetPersonnel.parentConference)

  const eventRef = isConference ? props.activeConference : props.activeEvent
  const showFeedbackTab = eventRef.feedbackFormId

  return (
    <div
      id="sidebar"
      className={classNames(
        "sidebar sidebar-event-view",
        isConference && "isConference",
        isSessionFullView && "sessionFull",
        isSession && "isSession",
        !activeEvent.fetched && "disabled"
      )}
    >
      {((isSession && props.activeConference.id) ||
        (targetPersonnel && targetPersonnel.parentConference)) && (
        <section className="conferenceLabel">
          {props.activeConference.drupal_internal__id ? (
            <Link
              onClick={() => {
                if (props.returnTab === "summary") {
                  setTimeout(() => {
                    dispatch(changeConferenceTab("conference-summary"))
                  }, 300)
                }
              }}
              to={
                "/events/view/conference/" +
                props.activeConference.drupal_internal__id
              }
            >
              <span className="icon" />
              {props.activeConference.name}
            </Link>
          ) : (
            <p>
              <div className="flexRow">
                <span className="conference-white" />
                {targetPersonnel.parentConference}
              </div>
            </p>
          )}
        </section>
      )}

      {activeEvent.eventImage && activeEvent.eventImage.file && (
        <Link
          onClick={() => {
            changeTab("details")
          }}
          className={classNames(
            "editLink",
            (!canSchedule || !canViewEditLinks) && "disabled"
          )}
          to={editUrl}
        >
          <span style={{ marginTop: 10 }} className="editLabel">
            <span className="icon edit" />
            Edit
          </span>
          <div
            className="eventImageWrapper"
            style={{
              backgroundSize: "cover",
              backgroundImage:
                "url(" +
                process.env.REACT_APP_API_URL +
                activeEvent.eventImage.file.attributes.uri.url +
                ")",
            }}
          />
        </Link>
      )}

      <ul className="sidebar-tabs">
        {props.isConference && (
          <>
            <li
              onClick={() => {
                changeTab("schedule")
              }}
              className={classNames(
                activeEvent.currentTab === "schedule" && "active"
              )}
            >
              <span className="icon sessions" /> Sessions
            </li>
            <li
              onClick={() => {
                changeTab("conference-summary")
              }}
              className={classNames(
                activeEvent.currentTab === "conference-summary" && "active"
              )}
            >
              <span className="icon conference-summary" /> Summary
            </li>
          </>
        )}
        {canViewTabs && (
          <>
            <li
              onClick={() => {
                changeTab("details")
              }}
              className={classNames(
                activeEvent.currentTab === "details" && "active"
              )}
            >
              <span className="icon details-info" /> Details
            </li>
            <li
              onClick={() => {
                changeTab("updates")
              }}
              className={classNames(
                activeEvent.currentTab === "updates" && "active"
              )}
            >
              <span className="icon message" /> Updates
            </li>
            {activeEvent.personnel.length > 0 && (
              <li
                onClick={() => {
                  changeTab("personnel")
                }}
                className={classNames(
                  activeEvent.currentTab === "personnel" && "active"
                )}
              >
                <span className="icon personnel" /> Personnel
              </li>
            )}
            <li
              onClick={() => {
                changeTab("participants")
              }}
              className={classNames(
                activeEvent.currentTab === "participants" && "active"
              )}
            >
              <span className="icon attendees" /> Participant
              {!props.isObservation && "s"}
            </li>

            {props.isObservation && props.activeEvent.observationRubricUUID && (
              <li
                onClick={() => {
                  changeTab("rubric")
                }}
                className={classNames(
                  activeEvent.currentTab === "rubric" && "active"
                )}
              >
                <span className="icon rubric" /> Rubric
              </li>
            )}

            {showFeedbackTab && (
              <li
                onClick={() => {
                  changeTab("feedback")
                }}
                className={classNames(
                  activeEvent.currentTab === "feedback" && "active"
                )}
              >
                <span className="icon feedback-form" /> Feedback
              </li>
            )}
          </>
        )}
      </ul>

      {activeEvent.fetched && activeEvent.name && (
        <div>
          <section
            className="dateTime"
            style={{ paddingTop: canViewTabs ? 0 : 20 }}
          >
            <Link
              onClick={() => {
                editEvent("details", activeEvent)
              }}
              className={classNames(
                "editLink",
                (!canSchedule || !canViewEditLinks) && "disabled"
              )}
              to={editUrl}
            >
              <span className="editLabel">
                <span className="icon edit" />
                Edit
              </span>
              <span className="icon calendar" />
              <div style={{ fontWeight: "normal" }}>
                {formattedDateSecond && (
                  <>
                    <strong>Starts</strong>
                  </>
                )}
                {formattedDate}
                {formattedDateSecond && (
                  <>
                    <strong style={{ marginTop: 10 }}>Ends</strong>{" "}
                    {formattedDateSecond}
                  </>
                )}
              </div>
            </Link>
          </section>

          {activeEvent.meetingLink && (
            <>
              <section className="locationWrapper">
                <Link
                  onClick={() => {
                    editEvent("location", activeEvent)
                  }}
                  className={classNames(
                    "editLink",
                    (!canSchedule || !canViewEditLinks) && "disabled"
                  )}
                  to={editUrl}
                >
                  <span className="editLabel">
                    <span className="icon edit" />
                    Edit
                  </span>
                  <span className="icon video-conference" />
                  <div>
                    <strong>Meeting Link</strong>
                    <span className="link">
                      {activeEvent.meetingLink.substring(0, 34)}...
                    </span>
                  </div>
                </Link>
              </section>
            </>
          )}

          {((isConference && activeEvent.locations?.length === 1) ||
            (!isConference && activeEvent.currentLocation)) && (
            <SidebarLocationMap
              location={
                activeEvent.locations
                  ? activeEvent.locations[0]
                  : activeEvent.currentLocation
              }
              eventRoom={currentRoom}
            />
          )}

          {activeEvent.locations?.length > 1 && (
            <SidebarLocationsAccordion
              locationId={null}
              locations={activeEvent.locations}
            />
          )}

          {activeEvent.tags.length > 0 && (
            <div className="flexRow" style={{ flexWrap: "wrap" }}>
              {activeEvent.tags.map((tag) => {
                return <div className="tag-wrapper">{tag.text}</div>
              })}
            </div>
          )}

          {activeEvent.difficulty !== "N/A" && (
            <section>
              <Link
                onClick={() => {
                  editEvent("resources", activeEvent)
                }}
                className={classNames(
                  "editLink",
                  (!canSchedule || !canViewEditLinks) && "disabled"
                )}
                to={editUrl}
              >
                <span className="editLabel">
                  <span className="icon edit" />
                  Edit
                </span>
                <h3>Skill Level</h3>
                <Rating level={activeEvent.difficulty.toLowerCase()} />
              </Link>
            </section>
          )}

          {pdMinutes > 0 && (
            <section>
              <Link
                onClick={() => {
                  editEvent("resources", activeEvent)
                }}
                className={classNames(
                  "editLink",
                  (!canSchedule || !canViewEditLinks) && "disabled"
                )}
                to={editUrl}
              >
                <span className="editLabel">
                  <span className="icon edit" />
                  Edit
                </span>
                <h3>PD Credit</h3>
                <div className="flexRow">
                  <span className="icon clock" />
                  <span className="count">
                    {convertEstimatedTime(pdMinutes)}
                  </span>
                </div>
              </Link>
            </section>
          )}
        </div>
      )}
    </div>
  )
}

function CourseList(props) {
  const dispatch = useAppDispatch()

  const { editUrl, canSchedule } = props

  function editEvent(tab) {
    if (props.isSession) {
      dispatch(editConferenceEvent(props.activeEvent))
    }
    dispatch(updateField({ field: "currentTab", value: tab }))
  }

  return (
    <div className="eventCoursesWrapper">
      <Link
        onClick={() => {
          editEvent("resources", props.activeEvent)
        }}
        style={{ position: "relative" }}
        className={classNames("editLink", !canSchedule && "disabled")}
        to={editUrl}
      >
        <h3>
          {props.title}
          <span
            style={{ backgroundColor: "rgba(0,0,0,0)", top: -1, left: 225 }}
            className="editLabel"
          >
            <span className="icon edit" />
            Edit
          </span>
        </h3>
        {props.title === "Prerequisite Courses" && (
          <p>
            The event organizer has requested you complete the following{" "}
            {pluralize("course", props.courses)} before attending this event.
          </p>
        )}
        {props.title === "Related Courses" && (
          <p>
            The organizer recommends the following related{" "}
            {pluralize("course", props.courses)}.
          </p>
        )}
        {props.courses.map((course) => {
          const category = props.categories.data.find(
            (cat) =>
              course.relationships.field_category.data &&
              cat.id === course.relationships.field_category.data.id
          )

          return (
            <div key={course.id} className="course">
              {category && (
                <span
                  className="category-icon"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_API_URL +
                      category.image.attributes.uri.url +
                      ")",
                  }}
                />
              )}
              <span className="title">{course.attributes.name}</span>
            </div>
          )
        })}
      </Link>
    </div>
  )
}

class EventMessages extends Component {
  state = {
    confirmDelete: null,
  }

  componentDidMount() {
    if (this.props.isConference) {
      this.props.fetchConferenceMessages()
    } else {
      this.props.fetchEventMessages()
    }
  }

  handleNewMessageChange = (newValue) => {
    if (this.props.isConference) {
      this.props.updateConferenceField({
        field: "newMessage",
        value: newValue,
      })
    } else {
      this.props.updateField({ field: "newMessage", value: newValue })
    }
  }

  render() {
    const { isConference, canSchedule, eventRole } = this.props
    let isSession = this.props.activeConference.id
    let activeEvent = isConference
      ? this.props.activeConference
      : this.props.activeEvent

    const enableSend = activeEvent.newMessage
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll("<strong>", "")
      .replaceAll("</strong>", "")
      .replaceAll("<em>", "")
      .replaceAll("</em>", "")
      .replaceAll("<br>", "").length
    const { orgRoles, groupRoles } = this.props.session
    const allRoles = orgRoles.concat(groupRoles).join()

    const canUpdateAll =
      allRoles.includes("admin") ||
      allRoles.includes("observer") ||
      allRoles.includes("scheduler")

    const allowUpdateAll = eventRole !== "Presenter" || canUpdateAll

    return (
      <div className="event-updates">
        <section>
          {(canSchedule || eventRole === "Secretary") && (
            <FormControl variant="standard" className="newMessage">
              <ReactQuill
                theme={"snow"}
                onChange={this.handleNewMessageChange}
                value={activeEvent.newMessage}
                modules={modules}
                formats={formats}
                placeholder={"Post a new update..."}
              />
              <footer>
                {!this.state.isEmailSending ? (
                  <FormControl
                    variant="standard"
                    className="inputSelect status"
                  >
                    <InputLabel id="label-status-select">
                      Send Email To
                    </InputLabel>
                    <Select
                      disabled={!allowUpdateAll}
                      variant="standard"
                      labelId="label-status-select"
                      id="status-select"
                      value={
                        activeEvent.newMessageEmailTarget
                          ? activeEvent.newMessageEmailTarget
                          : "all"
                      }
                      onChange={(event) => {
                        if (isConference) {
                          this.props.updateConferenceField({
                            field: "newMessageEmailTarget",
                            value: event.target.value,
                          })
                        } else {
                          this.props.updateField({
                            field: "newMessageEmailTarget",
                            value: event.target.value,
                          })
                        }
                      }}
                    >
                      {allowUpdateAll && (
                        <MenuItem value={"all"}>
                          All Invited Participants
                        </MenuItem>
                      )}
                      <MenuItem value={"rsvp_only"}>
                        Only Those Attending
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <Loading message="Posting message..." />
                )}
                {!this.state.isEmailSending && (
                  <Button
                    style={{
                      opacity: enableSend > 0 ? 1 : 0.5,
                      pointerEvents: enableSend > 0 ? "all" : "none",
                    }}
                    onClick={() => {
                      this.setState({ isEmailSending: true })
                      this.props
                        .createEventMessage({
                          event: activeEvent,
                          isConference,
                          isSession,
                        })
                        .then(() => {
                          this.setState({ isEmailSending: false })
                        })
                    }}
                    className="button small"
                  >
                    Send
                  </Button>
                )}
              </footer>
            </FormControl>
          )}
          {activeEvent.messages.fetched ? (
            <>
              {activeEvent.messages.data.length > 0 && (
                <>
                  <h3>Event Updates</h3>

                  <ul>
                    {activeEvent.messages.data.map((message) => {
                      const user = message.user
                        ? message.user
                        : this.props.session.user
                      return (
                        <li key={message.id}>
                          <header>
                            <div className="userPictureWrapper">
                              {message.attributes.field_user_image_path && (
                                <div
                                  className="userPicture"
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      process.env.REACT_APP_API_URL +
                                      message.attributes.field_user_image_path +
                                      ")",
                                  }}
                                />
                              )}
                            </div>

                            <strong style={{ textTransform: "capitalize" }}>
                              {user.attributes.display_name
                                .toLowerCase()
                                .replace(".", " ")}
                            </strong>
                            <div className="timestamp">
                              Posted{" "}
                              {moment(message.attributes.created).fromNow()}
                            </div>
                          </header>
                          <SanitizeHTML
                            html={message.attributes.field_message.value.replaceAll(
                              "<br>",
                              ""
                            )}
                          />

                          <footer>
                            {(canSchedule || eventRole === "Secretary") && (
                              <>
                                {this.state.confirmDelete === message.id && (
                                  <em>
                                    Are you sure you want to delete this
                                    message?
                                  </em>
                                )}
                                {this.state.confirmDelete === message.id ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        this.setState({ confirmDelete: null })
                                      }}
                                      className="button small cancel"
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        this.props.deleteEventMessage(message)
                                      }}
                                      className="button small delete"
                                    >
                                      Yes, Delete
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        confirmDelete: message.id,
                                      })
                                    }}
                                    className="button small delete"
                                  >
                                    Delete
                                  </Button>
                                )}
                              </>
                            )}
                          </footer>
                        </li>
                      )
                    })}
                  </ul>
                </>
              )}
            </>
          ) : (
            <Loading message="Getting posts..." />
          )}
        </section>
      </div>
    )
  }
}

class EventDetails extends Component {
  componentDidMount() {
    const { eventId } = this.props
    if (this.props.isConference) {
      this.props.getConferenceUserEvents({ eventId })
    } else {
      this.props.getUserEvents({ eventId })
    }
    this.props.fetchRoles()
  }

  editEvent = (tab, event) => {
    if (this.props.isSession) {
      this.props.editConferenceEvent(this.props.activeEvent)
    } else {
      this.changeTab(tab)
    }
  }

  changeTab(tab) {
    window.scrollTo(0, 0)
    if (this.props.isConference) {
      this.props.changeConferenceTab(tab)
    } else {
      this.props.changeEventTab(tab)
    }
  }

  render() {
    const {
      activeEvent,
      canSchedule,
      isConference,
      categories,
      editUrl,
      canViewEditLinks,
    } = this.props

    return (
      <div className="event-details">
        <section>
          <Link
            onClick={() => {
              this.editEvent("details", activeEvent)
            }}
            style={{ position: "relative" }}
            className={classNames(
              "editLink",
              (!canSchedule || !canViewEditLinks) && "disabled"
            )}
            to={editUrl}
          >
            <h3>
              Description
              <span
                style={{ backgroundColor: "rgba(0,0,0,0)", top: -1, left: 125 }}
                className="editLabel"
              >
                <span className="icon edit" />
                Edit
              </span>
            </h3>
          </Link>

          <SanitizeHTML html={activeEvent.description} />
        </section>

        {activeEvent.personnel.find(
          (user) =>
            user.field_event_role_name &&
            user.field_event_role_name === "Presenter"
        ) && (
          <section>
            <Link
              onClick={() => {
                this.editEvent("personnel", activeEvent)
              }}
              style={{ position: "relative" }}
              className={classNames(
                "editLink",
                (!canSchedule || !canViewEditLinks) && "disabled"
              )}
              to={editUrl}
            >
              <h3>
                Presenters
                <span
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    top: -1,
                    left: 125,
                  }}
                  className="editLabel"
                >
                  <span className="icon edit" />
                  Edit
                </span>
              </h3>

              <ul className={"personnel-list presenter"}>
                {activeEvent.personnel.map((user) => {
                  let userImage =
                    process.env.REACT_APP_API_URL + user.field_user_image_path

                  if (user.field_event_role_name === "Presenter") {
                    return (
                      <li key={user.id}>
                        <div className="flexRow">
                          {userImage.includes("default_temp") ||
                          !user.field_user_image_path ? (
                            <UserInitial
                              containerStyle={{ marginRight: 10 }}
                              fontSize={18}
                              size={50}
                              initials={
                                user.field_first_name
                                  .substring(0, 1)
                                  .toUpperCase() +
                                user.field_last_name
                                  .substring(0, 1)
                                  .toUpperCase()
                              }
                            />
                          ) : (
                            <span
                              className="userImage"
                              style={{
                                height: 50,
                                width: 50,
                                backgroundImage: "url(" + userImage + ")",
                              }}
                            />
                          )}
                          <strong
                            style={{ flex: 1 }}
                            className="presenter-name"
                          >
                            {user.field_first_name} {user.field_last_name}
                          </strong>
                        </div>
                        <p>{user.field_bio}</p>
                      </li>
                    )
                  }
                  return null
                })}
              </ul>
            </Link>
          </section>
        )}

        {(activeEvent.preRequisiteCourses.length > 0 ||
          activeEvent.replacementCourses.length > 0) && (
          <section className="flexRow" style={{ alignItems: "flex-start" }}>
            {activeEvent.preRequisiteCourses.length > 0 && (
              <CourseList
                editUrl={editUrl}
                canSchedule={canSchedule}
                changeEventTab={this.props.changeEventTab}
                categories={categories}
                activeEvent={activeEvent}
                title={"Prerequisite Courses"}
                courses={activeEvent.preRequisiteCourses}
              />
            )}

            {activeEvent.replacementCourses.length > 0 && (
              <CourseList
                editUrl={editUrl}
                changeEventTab={this.props.changeEventTab}
                categories={categories}
                activeEvent={activeEvent}
                title={"Related Courses"}
                courses={activeEvent.replacementCourses}
              />
            )}
          </section>
        )}

        {activeEvent.relatedLP && activeEvent.relatedLP && (
          <section>
            <h3>Prerequisite Learning Path</h3>
            <p>
              The event organizer has requested you complete the following
              learning path before attending this event.
            </p>

            <div style={{ padding: 15 }}>
              <div className="flexRow">
                <span
                  style={{ width: 25, height: 25, marginRight: 10 }}
                  className="icon learningPath color"
                />
                <strong>{activeEvent.relatedLP.name}</strong>
              </div>
            </div>
          </section>
        )}

        {activeEvent.badgeAwarded && (
          <section>
            <Link
              onClick={() => {
                this.props.changeEventTab("resources")
              }}
              style={{ position: "relative" }}
              className={classNames("editLink", !canSchedule && "disabled")}
              to={editUrl}
            >
              <h3>
                Badge Awarded
                <span
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    top: -1,
                    left: 170,
                  }}
                  className="editLabel"
                >
                  <span className="icon edit" />
                  Edit
                </span>
              </h3>

              <div style={{ marginTop: 10 }} className="flexRow badgeWrapper">
                {activeEvent.badgeAwarded.image &&
                  activeEvent.badgeAwarded.image.attributes && (
                    <img
                      alt={activeEvent.badgeAwarded.name}
                      className="badge"
                      src={
                        process.env.REACT_APP_API_URL +
                        activeEvent.badgeAwarded.image.attributes.uri.url
                      }
                    />
                  )}
                {activeEvent.badgeAwarded.name}
              </div>
            </Link>
          </section>
        )}

        {activeEvent.attachments.length > 0 && (
          <section>
            <Link
              onClick={() => {
                this.props.changeEventTab("resources")
              }}
              style={{ position: "relative" }}
              className={classNames("editLink", !canSchedule && "disabled")}
              to={editUrl}
            >
              <h3>
                Attachments
                <span
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    top: -1,
                    left: 145,
                  }}
                  className="editLabel"
                >
                  <span className="icon edit" />
                  Edit
                </span>
              </h3>

              {activeEvent.attachments.map((file) => {
                return (
                  <div key={file.id} className="attachment">
                    {file.extension !== "png" &&
                    file.extension !== "jpg" &&
                    file.extension !== "jpeg" ? (
                      <span className={classNames("icon", file.extension)} />
                    ) : (
                      <span
                        className={classNames("icon image")}
                        style={{
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_API_URL +
                            file.file +
                            ")",
                        }}
                      />
                    )}
                    {he.decode(file.filename)}
                  </div>
                )
              })}
            </Link>
          </section>
        )}

        {activeEvent.notes && (
          <section>
            <Link
              onClick={() => {
                this.props.changeEventTab("details")
              }}
              style={{ position: "relative" }}
              className={classNames("editLink", !canSchedule && "disabled")}
              to={editUrl}
            >
              <h3>
                {isConference ? <>Conference</> : <>Event</>} Notes
                <span
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    top: -1,
                    left: isConference ? 190 : 145,
                  }}
                  className="editLabel"
                >
                  <span className="icon edit" />
                  Edit
                </span>
              </h3>
            </Link>

            <SanitizeHTML html={activeEvent.notes} />
          </section>
        )}

        {activeEvent.helpfulLinks && (
          <section>
            <Link
              onClick={() => {
                this.props.changeEventTab("resources")
              }}
              style={{ position: "relative" }}
              className={classNames("editLink", !canSchedule && "disabled")}
              to={editUrl}
            >
              <h3>
                Helpful Links
                <span
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    top: -1,
                    left: 145,
                  }}
                  className="editLabel"
                >
                  <span className="icon edit" />
                  Edit
                </span>
              </h3>
            </Link>

            <SanitizeHTML html={activeEvent.helpfulLinks} />
          </section>
        )}

        {activeEvent.supportContact && (
          <section>
            <Link
              onClick={() => {
                this.props.changeEventTab("resources")
              }}
              style={{ position: "relative" }}
              className={classNames("editLink", !canSchedule && "disabled")}
              to={editUrl}
            >
              <h3>
                Support Contact
                <span
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    top: -1,
                    left: 180,
                  }}
                  className="editLabel"
                >
                  <span className="icon edit" />
                  Edit
                </span>
              </h3>
            </Link>
            <SanitizeHTML html={activeEvent.supportContact} />
          </section>
        )}
      </div>
    )
  }
}

class EventPersonnel extends Component {
  editEvent = (tab, event) => {
    if (this.props.isSession) {
      this.props.editConferenceEvent(this.props.activeEvent)
    } else {
      this.changeTab(tab)
    }
  }

  changeTab(tab) {
    window.scrollTo(0, 0)
    if (this.props.isConference) {
      this.props.changeConferenceTab(tab)
    } else {
      this.props.changeEventTab(tab)
    }
  }

  render() {
    const { activeEvent, canSchedule, editUrl, canViewEditLinks } = this.props
    let label = "Event"
    if (this.props.isSession) {
      label = "Session"
    } else if (this.props.isConference) {
      label = "Conference"
    }
    return (
      <div className="event-details">
        {activeEvent.personnel.length > 0 && (
          <section>
            <Link
              onClick={() => {
                this.editEvent("personnel", activeEvent)
              }}
              style={{ position: "relative" }}
              className={classNames(
                "editLink",
                (!canSchedule || !canViewEditLinks) && "disabled"
              )}
              to={editUrl}
            >
              <h3>
                Personnel
                <span
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    top: -1,
                    left: 125,
                  }}
                  className="editLabel"
                >
                  <span className="icon edit" />
                  Edit
                </span>
              </h3>

              <ul className={"personnel-list"}>
                {activeEvent.personnel.map((user) => {
                  let userImage =
                    process.env.REACT_APP_API_URL + user.field_user_image_path

                  return (
                    <li key={user.id}>
                      {userImage.includes("default_temp") ||
                      !user.field_user_image_path ? (
                        <UserInitial
                          containerStyle={{ marginRight: 10 }}
                          fontSize={18}
                          size={50}
                          initials={
                            user.field_first_name
                              .substring(0, 1)
                              .toUpperCase() +
                            user.field_last_name.substring(0, 1).toUpperCase()
                          }
                        />
                      ) : (
                        <span
                          className="userImage"
                          style={{
                            height: 50,
                            width: 50,
                            backgroundImage: "url(" + userImage + ")",
                          }}
                        />
                      )}
                      {user.field_event_role_name && (
                        <span
                          className={classNames(
                            "icon",
                            user.field_event_role_name.toLowerCase()
                          )}
                        />
                      )}
                      <div className="personnel-info">
                        <strong style={{ flex: 1 }} className="presenter-name">
                          {user.field_first_name} {user.field_last_name}
                        </strong>
                        <span>
                          {label} {user.field_event_role_name}
                        </span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </Link>
          </section>
        )}
      </div>
    )
  }
}

class LiveViewLayout extends Component {
  async fetchAllInformation({ refresh }) {
    const { eventId, orgBadges, session } = this.props

    this.props.changeTab("events")

    if (!refresh) {
      this.props.changeEventTab("details")
    }

    if (this.props.isConference) {
      if (window.location.href.includes("/view") && !refresh) {
        this.props.changeConferenceTab("schedule")
      }
      this.props.fetchActiveConference({ id: eventId }).then((response) => {
        if (response.payload) {
          this.props.fetchConferenceFeedbackSummary()

          response.payload.locations.forEach(async (location, index) => {
            await this.props.getRoomsForConferenceLocation({
              id: location.id,
              index,
              length: response.payload.locations.length,
              conference: response.payload.data[0],
            })
          })

          this.props.fetchSessionsForConference({
            id: response.payload.data[0].attributes.drupal_internal__id,
          })
        }
        this.props.updateConferenceInviteeSearch("")
      })
    } else {
      this.props
        .fetchActiveEvent({
          isSession: this.props.isSession,
          isObservation: this.props.isObservation,
          id: eventId,
        })
        .then((response) => {
          this.props.fetchFeedbackSummary()
        })
    }
    if (!refresh) {
      this.props.getRooms()

      !this.props.categories.fetched && this.props.getCategories()
      !session.jobTitles.fetched && this.props.getJobTitles()
      !session.subGroups.fetched &&
        this.props.getSubGroups(session.group.uuid[0].value)

      !orgBadges.fetched &&
        this.props.getOrgBadges({
          searchQuery: "",
          status: "any",
          category: null,
          subGroup: "any",
          jobTitle: "any",
          sortBy: "created",
          sortOrder: "DESC",
        })
    }
    return true
  }

  async componentDidMount() {
    this.fetchAllInformation({ refresh: false })
  }

  render() {
    const props = { ...this.props }
    const { currentTab } = props.activeEvent

    const { isSession, categories, isConference, activeEvent } = props

    const allSelfPersonnel = activeEvent.personnel?.filter((person) => {
      return person.uuid === props.session.user.id
    })

    const isPresenter = allSelfPersonnel?.find(
      (person) => person.field_event_role_name === "Presenter"
    )
    const isSecretary = allSelfPersonnel?.find(
      (person) => person.field_event_role_name === "Secretary"
    )
    props.canViewEditLinks = !isPresenter

    let loadingText = isConference
      ? "Getting conference..."
      : "Getting event..."
    if (isSession) {
      loadingText = "Getting session..."
    }
    const eventCategory =
      activeEvent.category &&
      categories.data.find((cat) => cat.id === activeEvent.category.id)

    const hasCompletedRubric =
      props.activeEvent.invitees[0] &&
      props.activeEvent.invitees[0].user_rubric_uuid?.length
    props.reFetchEventInfo = () => {
      this.fetchAllInformation({ refresh: true })
    }

    const { startDate, endDate } = activeEvent

    let showEndDisplayDate =
      activeEvent &&
      moment(startDate).format("MM/DD/YYYY") !==
        moment(endDate).format("MM/DD/YYYY")

    return (
      <ErrorBoundary>
        {!props.activeEvent.fetched && (
          <Loading fullPage={true} message={loadingText} />
        )}
        <div
          className={classNames(
            "page event-single-view event-live-view",
            isSession && "isSession",
            props.isConference && "isConference",
            activeEvent.isFullScreen && "isFullScreen"
          )}
        >
          {!isConference &&
            !isSession &&
            props.activeEvent.fetched &&
            props.activeEvent.name && (
              <div
                className="subnav"
                style={{
                  padding: "0 20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span class="title">
                  <span class="icon calendar white"></span>
                  <span class="eventTitle">
                    {activeEvent.name}

                    <span className="dateTime">
                      <div
                        className="flexRow"
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div>
                          {startDate && !startDate.includes("Invalid") ? (
                            <>{moment(startDate).format("dddd, MMMM Do")}</>
                          ) : (
                            <>{moment(startDate).format("dddd, MMMM Do")}</>
                          )}
                          {!activeEvent.allDay && (
                            <>
                              ,{" "}
                              {startDate && !startDate.includes("Invalid") ? (
                                <>{moment(startDate).format("h:mma")}</>
                              ) : (
                                <>{moment(startDate).format("h:mma")}</>
                              )}
                              {showEndDisplayDate && (
                                <>
                                  {" "}
                                  - {moment(endDate).format("dddd, MMMM Do")},
                                </>
                              )}{" "}
                              {!endDate.includes("Invalid") && (
                                <>
                                  {!showEndDisplayDate && <>-</>}{" "}
                                  {moment(endDate).format("h:mma")}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </span>
                  </span>
                </span>
                {!isSecretary && (
                  <Link
                    style={{
                      pointerEvents: "all",
                      opacity: 1,
                    }}
                    onClick={() => {
                      if (props.isConference) {
                        this.props.changeConferenceTab("details")
                      } else {
                        if (isPresenter) {
                          this.props.changeEventTab("resources")
                        } else {
                          this.props.changeEventTab("details")
                        }
                      }
                    }}
                    to={props.editUrl}
                  >
                    <Button className="button small">
                      <span className="icon edit white" />
                      Edit
                    </Button>
                  </Link>
                )}
              </div>
            )}

          {props.activeEvent.fetched && props.activeEvent.name && (
            <>
              <SidebarEvent {...props} />

              <h1>
                <span className="title">
                  <span>
                    {props.activeEvent.name}
                    {props.activeEvent.field_draft && (
                      <span className="statusLabel">
                        <span className="icon draft" />
                        <span>Draft</span>
                      </span>
                    )}

                    <div className="flexRow" style={{ alignItems: "center" }}>
                      {eventCategory &&
                        activeEvent.category &&
                        activeEvent.category.attributes && (
                          <span
                            className="eventCategory"
                            style={{
                              position: "relative",
                              top: 2,
                              marginRight: 10,
                            }}
                          >
                            {eventCategory.image && (
                              <span
                                style={{
                                  marginRight: 5,
                                  border: "3px solid white",
                                  backgroundImage:
                                    "url(" +
                                    process.env.REACT_APP_API_URL +
                                    eventCategory.image.attributes.uri.url +
                                    ")",
                                }}
                                className="category-icon"
                              ></span>
                            )}

                            <>{activeEvent.category.attributes.name}</>
                          </span>
                        )}
                    </div>
                  </span>
                </span>
              </h1>

              {currentTab === "details" && <EventDetails {...props} />}
              {currentTab === "conference-summary" && (
                <ConferenceAttendanceSummary {...props} />
              )}

              {currentTab === "participants" && (
                <EventParticipants {...props} />
              )}
              {currentTab === "personnel" && <EventPersonnel {...props} />}
              {currentTab === "schedule" && <ConferenceSchedule {...props} />}
              {currentTab === "feedback" && <EventFeedbackSummary {...props} />}
              {currentTab === "updates" && <EventMessages {...props} />}

              {currentTab === "rubric" && (
                <>
                  {hasCompletedRubric ? (
                    <EventRubricCompleted {...props} />
                  ) : (
                    <RubricLayout
                      handleSubmit={() => {
                        this.props.getUserEvents()
                      }}
                      {...props}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </ErrorBoundary>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveViewLayout)
)
