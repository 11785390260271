import { Button, hexToRgb } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import classNames from "classnames"
import theme from "../theme/theme"

const hoverColor = hexToRgb(theme.palette.secondary.main)
  .replace("rgb", "rgba")
  .replace(")", ",.8)")

const StyledButton = withStyles({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: "#000",
    "&:hover": {
      backgroundColor: hoverColor,
      color: "#000",
    },
    "&:focus": {
      backgroundColor: hoverColor,
      color: "#000",
    },
  },
})(Button)

function ButtonLarge(props) {
  return (
    <StyledButton
      style={props.style}
      onClick={props.onClick}
      className={classNames("large button")}
    >
      {props.children}
    </StyledButton>
  )
}

export default ButtonLarge
