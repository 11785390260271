import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Field, Form, useFormikContext } from "formik"
import { CommunityCourseSearchResults } from "./CommunityCourseSearchResults"
import { GenieMessage } from "./GenieMessage"
import type { CreateCourseGenieSearchFormValues } from "./CreateCourseGenieSearch"

type CreateCourseGenieSearchFormProps = {
  onBack: () => void
}

export const CreateCourseGenieSearchForm = (
  props: CreateCourseGenieSearchFormProps
) => {
  const { onBack } = props
  const { touched, errors, values, isSubmitting, isValid } =
    useFormikContext<CreateCourseGenieSearchFormValues>()
  return (
    <Box component={Form} sx={{ display: "contents" }}>
      <DialogContent>
        <GenieMessage>
          <Typography>
            <strong>No worries!</strong> Tell me a little bit about what's going
            to be learned, and I'll try to find some video content for your
            micro-course.
          </Typography>
        </GenieMessage>
        <Stack spacing={4}>
          <Field
            as={TextField}
            name="search"
            label="Tell Me About Your Course"
            fullWidth={true}
            error={touched.search && !!errors.search}
            disabled={isSubmitting}
            autoFocus={true}
          />
          <Typography variant="body2" sx={{ color: "grey.600" }}>
            By clicking "Find Videos", you agree to YouTube's{" "}
            <Link
              href="https://www.youtube.com/t/terms"
              target="_blank"
              rel="noopenner noreferer"
              underline="always"
            >
              Terms of Service
            </Link>
            .
          </Typography>
          <CommunityCourseSearchResults search={values.search} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onBack}>
          Go Back
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          Find Videos
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}
