import BackpackProps from "../../types/backpack"

import "./styles/user-rubric.scss"
import { UserRubricResultsContainer } from "../UserRubricResultsContainer"

type ModalProps = {
  backpack: BackpackProps
  clearBackpackRubric: () => void
}

const UserRubricModal = (props: ModalProps) => {
  const { backpack } = props
  const { activeRubric } = backpack

  if (activeRubric) {
    return <UserRubricResultsContainer userRubric={activeRubric} />
  }
  return null
}

export default UserRubricModal
