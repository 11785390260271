import { MenuItem, Select } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import classNames from "classnames"
import { useState } from "react"
import theme from "../../theme/theme"
import Loading from "../Loading"

import "./orgswitcher.scss"

type Membership = {
  id: string
  internal_id: string
  name: string
  type: "group--partner" | "group--organization"
}

type SwitcherProps = {
  activeOrgId: string
  memberships: Membership[]
  isHQ: boolean
  onChange: (groupId: unknown) => void
}

function OrgSwitcher(props: SwitcherProps) {
  const { activeOrgId, memberships, isHQ, onChange } = props

  const [loading, setLoading] = useState(false)
  const useStyles = makeStyles({
    root: {
      backgroundColor: isHQ ? "#575757" : theme.palette.primary.main,
    },
  })

  const classes = useStyles()

  return (
    <>
      <Select
        variant="standard"
        MenuProps={{
          classes: { list: classes.root },
          id: "orgSelectPopover",
        }}
        labelId="label-crossOrgSelect-select"
        id="crossOrgSelect-select"
        value={activeOrgId}
        onChange={(event) => {
          setLoading(true)
          onChange(event.target.value)
        }}
      >
        {memberships.map((membership: Membership) => {
          return (
            <MenuItem
              key={membership.id}
              className="orgSelect"
              style={{
                backgroundColor:
                  activeOrgId === membership.id
                    ? theme.palette.secondary.main
                    : "transparent",
              }}
              value={membership.id}
            >
              <span
                className={classNames(
                  "icon",
                  membership.type.replace("group--", "")
                )}
              />
              {membership.name}
            </MenuItem>
          )
        })}
      </Select>
      {loading && (
        <div className="switchLoader">
          <Loading fullPage={true} message="Just a moment..." />
        </div>
      )}
    </>
  )
}

export default OrgSwitcher
