import { ThemeOptions } from "@mui/material"

const themeOverrides: Record<string, ThemeOptions> = {
  "learn.devteamafterdark.com": {
    title: "EduCollaborators",
    accountHref: "https://www.educollaborators.com/learn",
    introCourse: {
      development: 963,
      production: 2924,
    },
    palette: {
      primary: {
        main: "#005696",
      },
      secondary: {
        main: "#2ab4bd",
      },
    },
    mixins: {
      images: {
        background:
          "https://admin-dev.mobilemind.io/sites/default/files/sts-app-bg.png",
        logo: "https://stseducation-us.com/wp-content/uploads/2022/11/LEARN-EC-Logo-Horizontal.png",
      },
    },
  },
  "learn.educollaborators.com": {
    title: "EduCollaborators",
    accountHref: "https://www.educollaborators.com/learn",
    introCourse: {
      development: 963,
      production: 2924,
    },
    palette: {
      primary: {
        main: "#005696",
      },
      secondary: {
        main: "#2ab4bd",
      },
    },
    mixins: {
      images: {
        background:
          "https://admin-dev.mobilemind.io/sites/default/files/sts-app-bg.png",
        logo: "https://stseducation-us.com/wp-content/uploads/2022/11/LEARN-EC-Logo-Horizontal.png",
      },
    },
  },
  localehost: {
    title: "EduCollaborators",
    accountHref: "https://www.educollaborators.com/learn",
    introCourse: {
      development: 963,
      production: 2924,
    },
    palette: {
      primary: {
        main: "#005696",
      },
      secondary: {
        main: "#64CCD3",
      },
    },
    mixins: {
      images: {
        background:
          "https://admin-dev.mobilemind.io/sites/default/files/sts-app-bg.png",
        logo: "https://stseducation-us.com/wp-content/uploads/2022/11/LEARN-EC-Logo-Horizontal.png",
      },
    },
  },
}

export default themeOverrides
