import { SubmissionType } from "@mobilemind/common/src/types/course"
import { Box } from "@mui/material"
import { useAsyncEffect } from "@react-hook/async"
import usePrevious from "@react-hook/previous"
import { createNextState, current } from "@reduxjs/toolkit"
import { CourseSubmissionTypeSelect } from "features/courseForm/components/CourseSubmissionTypeSelect"
import { MultipleChoiceQuestionFields } from "features/courseForm/components/MultipleChoiceQuestionFields"
import { QuestionField } from "features/courseForm/components/QuestionField"
import { QuizSubmissionTypeFields } from "features/courseForm/components/QuizSubmissionTypeFields"
import { TextQuestionCourseFields } from "features/courseForm/components/TextQuestionCourseFields"
import { CourseFormValues } from "features/courseForm/types"
import { useFormikContext } from "formik"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  calculateNumQuestions,
  generateApply,
  generateLearnerInstructions,
  initProgress,
  resetProgress,
  selectCourseGenieActive,
  showCourseGenieError,
  updateCourseChallenge,
  updateProgress,
} from "../store/courseGenieSlice"
import { BackButton } from "./BackButton"
import { CheckboxQuestionFields } from "./CheckboxQuestionFields"
import { CourseFormBody } from "./CourseFormBody"
import { CourseFormButtons } from "./CourseFormButtons"
import { CourseFormHeader } from "./CourseFormHeader"
import { CourseFormStep } from "./CourseFormStepper"
import { HintField } from "./HintField"
import { LearnerInstructionsField } from "./LearnerInstructionsField"
import { NextButton } from "./NextButton"
import { ReviewerInstructionsField } from "./ReviewerInstructionsField"
import { ReviewersField } from "./ReviewersField"
import { useContext } from "react"
import { GenieContext } from "./GenieContext"

export const CourseFormChallenge = () => {
  const { setValues, values } = useFormikContext<CourseFormValues>()
  const dispatch = useAppDispatch()
  const genieActive = useAppSelector(selectCourseGenieActive)
  const { videoItem } = useContext(GenieContext)

  const prevSubmissionType = usePrevious(values.submissionType)
  useAsyncEffect(async () => {
    if (
      genieActive &&
      prevSubmissionType &&
      values.submissionType !== prevSubmissionType &&
      videoItem
    ) {
      const numQuestions = await calculateNumQuestions(
        values,
        videoItem.duration
      )
      dispatch(initProgress(numQuestions + 2))

      try {
        const nextValues = await createNextState(values, async (draft) => {
          await dispatch(
            updateCourseChallenge({
              video: videoItem,
              draft,
              commit: (draft) => setValues(current(draft)),
            })
          )

          dispatch(updateProgress("Generating course instructions…"))
          draft.content = await dispatch(generateApply(values.submissionType))

          dispatch(updateProgress("Generating course instructions…"))
          draft.learnerInstructions = await dispatch(
            generateLearnerInstructions(values.submissionType)
          )
        })
        setValues(nextValues)
      } catch (err) {
        dispatch(showCourseGenieError())
      } finally {
        dispatch(resetProgress())
      }
    }
  }, [genieActive, values.submissionType])

  return (
    <>
      <CourseFormHeader course={values} />

      <CourseFormBody>
        <Box sx={{ display: "flex", gap: 3, alignItems: "flex-start" }}>
          <CourseSubmissionTypeSelect required={true} margin="normal" />
        </Box>

        <LearnerInstructionsField />
        <>
          {(values.submissionType === SubmissionType.Url ||
            values.submissionType === SubmissionType.Image) && (
            <>
              <QuestionField />
              <ReviewerInstructionsField />
              {!values.isTemplate && <ReviewersField />}
            </>
          )}
          {values.submissionType === SubmissionType.Text && (
            <>
              <QuestionField />
              <TextQuestionCourseFields />
              {values.reviewMethod !== "manual" && <HintField />}
              <ReviewerInstructionsField />
              {!values.isTemplate && <ReviewersField />}
            </>
          )}
          {values.submissionType === SubmissionType.MultipleChoice && (
            <>
              <QuestionField />
              <MultipleChoiceQuestionFields />
              <HintField />
            </>
          )}
          {values.submissionType === SubmissionType.Checkbox && (
            <>
              <QuestionField />
              <CheckboxQuestionFields />
            </>
          )}
          {values.submissionType === SubmissionType.Quiz && (
            <QuizSubmissionTypeFields />
          )}
        </>
        <CourseFormButtons>
          <BackButton step={CourseFormStep.Overview} />
          <NextButton step={CourseFormStep.Settings} />
        </CourseFormButtons>
      </CourseFormBody>
    </>
  )
}
