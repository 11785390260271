import theme from "../theme/theme"

function List() {
  return (
    <>
      <svg
        width="20px"
        height="14px"
        viewBox="0 0 20 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="list" fill={theme.palette.primary.main} fillRule="nonzero">
            <path
              d="M19.21875,6.1328125 L5.63523437,6.1328125 C5.20375,6.1328125 4.85398437,6.48257812 4.85398437,6.9140625 C4.85398437,7.34554688 5.20375,7.6953125 5.63523437,7.6953125 L19.21875,7.6953125 C19.6502344,7.6953125 20,7.34554688 20,6.9140625 C20,6.48257812 19.6502344,6.1328125 19.21875,6.1328125 Z"
              id="Path"
            ></path>
            <path
              d="M19.21875,0.2734375 L5.63523437,0.2734375 C5.20375,0.2734375 4.85398437,0.623203125 4.85398437,1.0546875 C4.85398437,1.48617187 5.20375,1.8359375 5.63523437,1.8359375 L19.21875,1.8359375 C19.6502344,1.8359375 20,1.48617187 20,1.0546875 C20,0.623203125 19.6502344,0.2734375 19.21875,0.2734375 Z"
              id="Path"
            ></path>
            <path
              d="M19.21875,11.9921875 L5.63523437,11.9921875 C5.20375,11.9921875 4.85398437,12.3419531 4.85398437,12.7734375 C4.85398437,13.2049219 5.20375,13.5546875 5.63523437,13.5546875 L19.21875,13.5546875 C19.6502344,13.5546875 20,13.2049219 20,12.7734375 C20,12.3419531 19.6502344,11.9921875 19.21875,11.9921875 Z"
              id="Path"
            ></path>
            <circle
              id="Oval"
              cx="1.0546875"
              cy="1.0546875"
              r="1.0546875"
            ></circle>
            <circle
              id="Oval"
              cx="1.0546875"
              cy="6.9140625"
              r="1.0546875"
            ></circle>
            <circle
              id="Oval"
              cx="1.0546875"
              cy="12.7734375"
              r="1.0546875"
            ></circle>
          </g>
        </g>
      </svg>
    </>
  )
}

export default List
