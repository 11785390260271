import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import qs from "qs"
import type { RootState } from "../types"
import _ from "lodash"
import { MarketplaceItem } from "features/marketplace/types"

export const getDashboardMarketplace = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("dashboard/getDashboardMarketplace", async (args) => {
  const query = {
    hq: 1,
  }

  const response = await fetchWrapper.get(
    "/api/marketplace?" + qs.stringify(query)
  )

  if (response.ok) {
    const data = await response.json()
    return data.marketplace_data
  }
  return []
})

export const searchOrgUsers = createAsyncThunk<any, any, { state: RootState }>(
  "session/searchOrgUsers",
  async (args, thunkAPI) => {
    const { learnerSearch } = thunkAPI.getState().dashboard
    const { session } = thunkAPI.getState()

    const isOrgLevel =
      session.orgRoles.includes("organization-admin") ||
      session.orgRoles.includes("organization-drafter") ||
      session.orgRoles.includes("organization-creator") ||
      session.orgRoles.includes("organization-reporter") ||
      session.orgRoles.includes("organization-scheduler") ||
      session.orgRoles.includes("organization-observer") ||
      session.orgRoles.includes("organization-observation_admin")

    const orgId = session.group.id[0].value

    let users: any[] = []
    let query: any = {
      search: learnerSearch.query,
    }
    query["role[0]"] = "all"
    let response
    let allSubGroupIds: any[] = []

    // If they aren't org level, we've got to go through all of their subgroups
    if (learnerSearch.query?.length >= 2) {
      if (!isOrgLevel) {
        allSubGroupIds = session.subgroups.data.map(
          (subgroup: any) => subgroup.drupal_internal__id
        )

        // We have to do a while loop and throw all results into an array here
        let i = 0
        while (i < allSubGroupIds.length) {
          response = await fetchWrapper.get(
            "/api/organization-members/" +
              allSubGroupIds[i] +
              "?" +
              qs.stringify(query)
          )

          if (response.ok) {
            let data = await response.json()

            const baseArray = !data.rows.content ? data.rows : []
            // eslint-disable-next-line no-loop-func
            baseArray.forEach((fetchedUser: any) => {
              if (!users.find((existing) => existing.uid === fetchedUser.uid)) {
                users.push(fetchedUser)
              }
            })
          }
          i++
        }
      } else {
        response = await fetchWrapper.get(
          "/api/organization-members/" + orgId + "?" + qs.stringify(query)
        )

        if (response.ok) {
          let data = await response.json()
          users = !data.rows.content ? data.rows : []
        }
      }
    }

    return users
  }
)

const debouncedLearnerSearch = debounceThunk(searchOrgUsers, 750)

export const updateLearnerSearch = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("dashboard/updateLearnerSearch", async (args, thunkAPI) => {
  thunkAPI.dispatch(debouncedLearnerSearch())
  return args
})

export const getChallenges = createAsyncThunk<any, any, { state: RootState }>(
  "session/getChallenges",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()

    let challenges: any[] = []
    let data: any = {}
    let groupData: any = {}

    if (session.isOrgAdmin) {
      let orgResponse = await fetchWrapper.get(
        "/api/reviewer/organization/" + session.group.id[0].value
      )

      if (orgResponse.ok) {
        data = await orgResponse.json()
        return { data }
      }
    }

    let response = await fetchWrapper.get("/api/reviewer")
    let groupResponse = await fetchWrapper.get("/api/rev_group")

    if (response.ok && groupResponse.ok) {
      data = await response.json()
      groupData = await groupResponse.json()

      challenges = !data.rows.content ? data.rows.slice(0, 3) : []
    }

    return { challenges, data, groupData }
  }
)

export const fetchEvents = createAsyncThunk<any, any, { state: RootState }>(
  "dashboard/fetchEvents",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()

    let events: any[] = []

    let query = {
      event_date: args,
      archived: 0,
      draft: 0,
      archive: 0,
    }

    let url = "/api/events-calendar/" + session.group.id[0].value
    if (
      !session.orgRoles.includes("organization-admin") &&
      !session.orgRoles.includes("organization-scheduler") &&
      !session.orgRoles.includes("organization-event_personnel")
    ) {
      url = "/api/events-group-calendar/" + session.subgroup.id[0].value
    }

    let response = await fetchWrapper.get(url + "?" + qs.stringify(query))

    if (response.ok) {
      let data = await response.json()

      events = !data.rows.content ? data.rows.slice(0, 3) : []

      return events
    }
  }
)

export const fetchQuickActions = createAsyncThunk(
  "dashboard/fetchQuickActions",
  async () => {
    let response = await fetchWrapper.get("/api/taxonomy_term/quick_links")

    if (response.ok) {
      let data = await response.json()

      return _.orderBy(data.data, ["attributes.weight"], ["asc"])
    }
  }
)

export const getOrgList = createAsyncThunk<any, any, { state: RootState }>(
  "dashboard/getOrgList",
  async (args, thunkAPI) => {
    const { filters, currentPage } = args

    const query = {
      search: filters.search,
      status: filters.status,
      page: {
        offset: Number(currentPage) * 25,
      },
    }

    const response = await fetchWrapper.get(
      "/api/org_list?" + qs.stringify(query)
    )

    if (response.ok) {
      const data = await response.json()
      data.org_data.forEach((org: any) => {
        org.org_external_pd_access = Boolean(org.org_external_pd_access === "1")
        org.org_hide_mm_content = Boolean(org.org_hide_mm_content === "1")
        org.org_status = Boolean(org.org_status === "Active")
        org.org_hq = Boolean(org.org_hq === "1")
      })

      return { data: data.org_data, total_records: data.total_records }
    }
  }
)

export const getCollectionMembers = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("dashboard/getCollectionMembers", async (args, thunkAPI) => {
  const collectionId = thunkAPI.getState().session.userCollection[0].id[0].value
  const response = await fetchWrapper.get(
    "/api/organization-members/" + collectionId + "?role=collection-admin"
  )

  if (response.ok) {
    const data = await response.json()

    return !data.rows.content ? data.rows : []
  }
})

type InitialState = {
  orgList: {
    data: any[]
    fetching: boolean
    totalPages: number
  }
  collectionMembers: {
    data: any[]
    fetched: boolean
  }
  learnerSearch: {
    query: string
    data: any[]
    isFetching: boolean
  }
  quickActions: any[]
  widgets: {
    calendar: {
      data: any[]
      fetched: boolean
    }
    challenges: {
      data: any[]
      fetched: boolean
      total: number
    }
    marketplace: MarketplaceItem[]
  }
}

const initialState: InitialState = {
  orgList: {
    data: [],
    fetching: false,
    totalPages: 1,
  },
  collectionMembers: {
    data: [],
    fetched: false,
  },
  widgets: {
    calendar: {
      data: [],
      fetched: false,
    },
    challenges: {
      data: [],
      fetched: false,
      total: 0,
    },
    marketplace: [],
  },
  quickActions: [],
  learnerSearch: {
    query: "",
    data: [],
    isFetching: false,
  },
}

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.learnerSearch.query = ""
      state.learnerSearch.data = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrgList.pending, (state, action) => {
      if (action.meta.arg.currentPage) {
        state.orgList.fetching = true
      }
    })
    builder.addCase(getOrgList.fulfilled, (state, action) => {
      state.orgList.totalPages = Math.ceil(action.payload.total_records / 25)
      state.orgList.data = action.meta.arg.currentPage
        ? state.orgList.data.concat(action.payload.data)
        : action.payload.data
      state.orgList.fetching = false
    })
    builder.addCase(getCollectionMembers.fulfilled, (state, action) => {
      state.collectionMembers.data = action.payload
      state.collectionMembers.fetched = true
    })
    builder.addCase(getDashboardMarketplace.fulfilled, (state, action) => {
      state.widgets.marketplace = action.payload
    })

    builder.addCase(updateLearnerSearch.pending, (state, action: any) => {
      if (action.meta.arg.length >= 2) {
        state.learnerSearch.isFetching = true
      }
      state.learnerSearch.query = action.meta.arg
    })
    builder.addCase(searchOrgUsers.fulfilled, (state, action: any) => {
      state.learnerSearch.isFetching = false
      state.learnerSearch.data = action.payload
    })
    builder.addCase(getChallenges.fulfilled, (state, action: any) => {
      state.widgets.challenges.fetched = true
      state.widgets.challenges.total = Number(
        action.payload.data.pager.total_items
      )

      if (action.payload.groupData) {
        state.widgets.challenges.total =
          Number(state.widgets.challenges.total) +
          Number(action.payload.groupData.pager.total_items)
      }
    })
    builder.addCase(fetchEvents.fulfilled, (state, action: any) => {
      state.widgets.calendar.fetched = true
      state.widgets.calendar.data = action.payload
    })
    builder.addCase(fetchQuickActions.fulfilled, (state, action) => {
      // @ts-ignore
      state.quickActions = action.payload
    })
  },
})

export const { resetSearch } = dashboardSlice.actions

export default dashboardSlice.reducer
