import { Tabs, alpha, styled } from "@mui/material"

export const ConferenceScheduleViewSelectTabs = styled(Tabs)(({ theme }) => ({
  height: 35,
  minHeight: "unset",
  borderRadius: 6,
  "& .MuiTabs-scroller": {
    height: 35,
    minHeight: "unset",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTab-root": {
    height: 35,
    width: 40,
    minWidth: "unset",
    minHeight: "unset",
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey.A200,
    "&:hover": {
      backgroundColor: alpha(theme.palette.grey.A200, 0.8),
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.grey.A400,
    },
    "&[disabled]": {
      opacity: theme.palette.action.disabledOpacity,
    },
  },
}))
