import { useState } from "react"
import { DatePicker } from "@mui/x-date-pickers"

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import moment from "moment"

import {
  clearUserSearchResults,
  getRubricAnalyticsMonth,
  getRubricAnalyticsYear,
  getSingleRubric,
  resetFilters,
  setRubricId,
  setUser,
  updateAnalyticsFilters,
  updateUserSearch,
} from "../../store/reducers/analytics"
import he from "he"

import { getOrgRubrics } from "actions/rubrics"
import { getSubGroups } from "store/reducers/session"
import {
  OrgRubric,
  OrgRubricCriteria,
} from "@mobilemind/common/src/types/rubrics"

function SidebarAnalytics() {
  const dispatch = useAppDispatch()
  const analytics = useAppSelector((state) => state.analytics)
  const session = useAppSelector((state) => state.session)
  const entityList = useAppSelector((state) => state.entityList)
  const {
    contentType,
    dateFrom,
    dateTo,
    subgroup,
    userSearch,
    user,
    rubricId,
    criteriaId,
  } = analytics.filters

  const [areRubricsFetched, setAreRubricsFetched] = useState(false)

  useEffect(() => {
    if (!areRubricsFetched) {
      dispatch(getOrgRubrics())
      setAreRubricsFetched(true)
    }
    if (rubricId) {
      if (analytics.currentTab === "year") {
        dispatch(getRubricAnalyticsYear({ rubricId }))
      } else {
        dispatch(getRubricAnalyticsMonth({ rubricId }))
      }
    }
    if (!session.subGroups.fetched) {
      dispatch(getSubGroups())
    }
  }, [
    areRubricsFetched,
    dateFrom,
    dateTo,
    subgroup,
    criteriaId,
    rubricId,
    analytics.currentTab,
    user,
    session.subGroups.fetched,
    dispatch,
  ])

  const allRoles = session.orgRoles.concat(session.groupRoles).join(",")

  const showUserSelect = allRoles.includes("observation")
  const showGroupSelect =
    !allRoles.includes("group-observation_admin") &&
    (allRoles.includes("organization-observation_admin") ||
      allRoles.includes("organization-admin"))

  const showGroupLevelGroupSelect = allRoles.includes("group-observation_admin")
  let allCriteria: OrgRubricCriteria[] = []

  analytics.singleRubricResult &&
    analytics.singleRubricResult.domain.forEach((domain) => {
      domain.criteria.forEach((criteria) => {
        allCriteria.push(criteria)
      })
    })

  const fullSubgroup = session.subGroups.data.find(
    (group) => group.entity_id === subgroup
  )

  const learnerSearchLabel = fullSubgroup
    ? fullSubgroup.attributes.label
    : "Learner"

  return (
    <div id="sidebar">
      <form className="filters" style={{ marginTop: -35 }}>
        <header style={{ marginBottom: 15 }}>
          <strong>Filter</strong>
          <Button
            className="button small"
            onClick={() => {
              dispatch(resetFilters())
            }}
          >
            Reset
          </Button>
        </header>
        <FormControl variant="standard" className="inputSelect contentType">
          <InputLabel id="label-contentType-select">Content Type</InputLabel>
          <Select
            variant="standard"
            labelId="label-contentType-select"
            id="contentType-select"
            value={contentType}
            onChange={(event) => {
              // dispatch(setContentType({ value: event.target.value }))
            }}
          >
            <MenuItem value={"rubric"}>Rubric</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="standard" className="inputSelect rubric">
          <InputLabel id="label-rubric-select">Rubric</InputLabel>
          <Select
            variant="standard"
            labelId="label-rubric-select"
            id="rubric-select"
            value={rubricId}
            onChange={(event) => {
              const fullRubric = entityList.orgRubrics.data.find(
                (rubric: OrgRubric) => rubric.id === event.target.value
              )

              if (fullRubric) {
                dispatch(
                  getSingleRubric({
                    // @ts-ignore
                    rubricUUID: fullRubric.uuid,
                  })
                )
              }
              dispatch(setRubricId(event.target.value))
            }}
          >
            {entityList.orgRubrics.data.map((rubric: OrgRubric) => {
              return (
                <MenuItem value={rubric.id}>{he.decode(rubric.name)}</MenuItem>
              )
            })}
          </Select>
        </FormControl>

        {allCriteria[0] && (
          <FormControl variant="standard" className="inputSelect criteria">
            <InputLabel id="label-criteria-select">Criteria</InputLabel>
            <Select
              variant="standard"
              labelId="label-criteria-select"
              id="criteria-select"
              value={analytics.filters.criteriaId}
              onChange={(event) => {
                dispatch(
                  updateAnalyticsFilters({
                    name: "criteriaId",
                    value: event.target.value,
                  })
                )
              }}
            >
              <MenuItem value={"allCriteria"}>Total Score</MenuItem>
              {allCriteria.map((criteria) => {
                return (
                  <MenuItem value={Number(criteria.criteria_id)}>
                    {criteria.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {showGroupSelect && (
          <FormControl variant="standard" className="inputSelect group">
            <InputLabel id="label-group-select">Group</InputLabel>
            <Select
              variant="standard"
              labelId="label-group-select"
              id="group-select"
              value={subgroup}
              onChange={(event) => {
                dispatch(setUser(null))
                dispatch(clearUserSearchResults())
                dispatch(
                  updateAnalyticsFilters({
                    name: "subgroup",
                    value: event.target.value,
                  })
                )
              }}
            >
              <MenuItem value={"all"}>All Groups</MenuItem>

              {session.subGroups.data.map((group) => {
                return (
                  <MenuItem value={group.entity_id}>
                    {group.attributes.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {showGroupLevelGroupSelect && (
          <FormControl variant="standard" className="inputSelect group">
            <InputLabel id="label-group-select">Group</InputLabel>
            <Select
              variant="standard"
              labelId="label-group-select"
              id="group-select"
              value={
                subgroup !== "all"
                  ? subgroup
                  : session.subgroups.data[0].attributes?.drupal_internal__id ??
                    null
              }
              onChange={(event) => {
                dispatch(setUser(null))
                dispatch(clearUserSearchResults())
                dispatch(
                  updateAnalyticsFilters({
                    name: "subgroup",
                    value: event.target.value,
                  })
                )
              }}
            >
              {session.subgroups.data.map((group) => {
                return (
                  <MenuItem
                    key={group.id}
                    value={group.attributes?.drupal_internal__id}
                  >
                    {group.attributes?.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {showUserSelect && (
          <FormControl variant="standard" className={"inputSelect"}>
            <Autocomplete
              id="user-select"
              loadingText={
                userSearch ? "Getting learners..." : "Search for learners..."
              }
              options={analytics.userSearchResults}
              // @ts-ignore
              value={user ?? "none"}
              getOptionLabel={(user) =>
                user && user?.field_first_name
                  ? user.field_first_name + " " + user.field_last_name
                  : "All Learners"
              }
              renderInput={(params) => (
                <TextField
                  placeholder="Search for learners..."
                  variant="standard"
                  {...params}
                  value={userSearch}
                  onChange={(event) =>
                    dispatch(updateUserSearch(event.target.value))
                  }
                  label={learnerSearchLabel}
                />
              )}
              onChange={(event, newValue) => dispatch(setUser(newValue))}
            />
          </FormControl>
        )}

        <Box
          sx={{
            marginTop: 3,
            "& label": {
              marginLeft: -3,
            },
          }}
        >
          <DatePicker
            label="Year From"
            value={moment(dateFrom)}
            openTo="year"
            views={["year"]}
            format={"YYYY"}
            disableFuture={true}
            maxDate={moment(dateTo)}
            sx={{
              "& input": {
                pointerEvents: "none",
                borderBottom: "none",
              },
            }}
            slotProps={{
              textField: {
                // @ts-ignore
                readOnly: true,
              },
            }}
            onChange={(date) => {
              dispatch(
                updateAnalyticsFilters({
                  name: "dateFrom",
                  value: moment(date).format(),
                })
              )
            }}
          />
        </Box>

        <Box
          sx={{
            marginTop: 3,
            "& label": {
              marginLeft: -3,
            },
          }}
        >
          <DatePicker
            label="Year To"
            value={moment(dateTo)}
            openTo="year"
            views={["year"]}
            format={"YYYY"}
            disableFuture={true}
            minDate={moment(dateFrom)}
            sx={{
              "& input": {
                pointerEvents: "none",
                borderBottom: "none",
              },
            }}
            slotProps={{
              textField: {
                // @ts-ignore
                readOnly: true,
              },
            }}
            onChange={(date) => {
              dispatch(
                updateAnalyticsFilters({
                  name: "dateTo",
                  value: moment(date).format(),
                })
              )
            }}
          />
        </Box>
      </form>
    </div>
  )
}

export default SidebarAnalytics
