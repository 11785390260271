import { Card, Typography, Box, Stack } from "@mui/material"
// eslint-disable-next-line
// @ts-ignore -- also wtf these are totally in this file
import { UserRubricResults } from "../types/rubrics"

import Checklist from "../img/checklist.svg"
import VennDiagram from "../img/venn-diagram.svg"
import moment from "moment"
import SpeechBubble from "../img/speech-bubble.svg"

type UserRubricResultsProps = {
  userRubric: UserRubricResults
}

export function UserRubricResultsContainer(props: UserRubricResultsProps) {
  const { userRubric } = props

  return (
    <Card>
      <h4
        style={{
          backgroundColor: "white",
          fontSize: 18,
          paddingLeft: 15,
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          margin: 0,
        }}
      >
        <img
          src={Checklist}
          alt="Checklist"
          width={15}
          style={{ marginRight: 20 }}
        />

        <div style={{ flex: 1 }}>
          {userRubric.title}
          <br />
          <Typography
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              fontSize: 14,
              paddingTop: 0.5,
            }}
            variant="h4"
          >
            Completed by {userRubric.author} on{" "}
            {moment(userRubric.completed_date).format("MMMM Do, YYYY")}
          </Typography>
        </div>

        <div
          style={{
            borderLeft: "1px solid rgba(0,0,0,.1)",
            display: "flex",
            paddingLeft: 30,
            paddingRight: 30,
            marginLeft: 15,
            padding: 15,
            alignItems: "center",
          }}
        >
          <strong style={{ textTransform: "uppercase", fontSize: 12 }}>
            Total Score
          </strong>
          <Box
            sx={(theme) => ({
              marginLeft: 2,
              fontSize: 20,
              padding: 2,
              borderRadius: 1,
              fontWeight: "bold",
              backgroundColor: theme.palette.secondary.main,
            })}
          >
            <strong>
              {userRubric.total_score}{" "}
              {userRubric.max_score && <>/ {userRubric.max_score}</>}
            </strong>
          </Box>
        </div>
      </h4>

      {userRubric.domain.map((domain, index: number) => {
        return (
          <Box key={"domain-" + index}>
            <Box
              key={"domain-" + index}
              sx={() => ({
                display: "flex",
                alignItems: "center",
                backgroundColor: "#b1e3f3",
                padding: 3,
                paddingBlock: 5,
              })}
            >
              <img
                alt="Domain"
                src={VennDiagram}
                width={25}
                style={{ marginRight: 10 }}
              />
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {domain.title}
              </Typography>
            </Box>

            {domain.criteria.map((criteria, index: number) => {
              const { points, score, comment } = criteria.user_criteria
              const selectedLevel = criteria.criteria_details.levels.find(
                (level) => level.level_points === points
              )
              const isNA = !score
              return (
                <Box key={index + "-" + criteria.criteria_details.title}>
                  <Box
                    sx={(theme) => ({
                      padding: 2,
                      paddingInline: 3,
                      marginBottom: 2,
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: theme.palette.grey[100],
                    })}
                  >
                    <Typography sx={{ fontWeight: "bold", flex: 1 }}>
                      {criteria.criteria_details.title}
                    </Typography>
                    {isNA ? (
                      <Typography
                        sx={{
                          borderRadius: 1,
                          backgroundColor: "black",
                          color: "white",
                          fontSize: 12,
                          fontWeight: "bold",
                          height: 30,
                          minWidth: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        N/A
                      </Typography>
                    ) : (
                      <Typography
                        sx={(theme) => ({
                          borderRadius: 1,
                          backgroundColor: theme.palette.secondary.main,
                          fontWeight: "bold",
                          height: 30,
                          minWidth: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        })}
                      >
                        {score}
                      </Typography>
                    )}
                  </Box>
                  {comment && (
                    <Box
                      sx={(theme) => ({
                        paddingInline: 3,
                        paddingBlock: 1,
                        alignItems: "flex-start",
                        marginInline: 2,
                        marginBottom: 3,
                        marginTop: 1,
                        backgoundColor: theme.palette.secondary.light,
                        borderRadius: 1,
                        display: "inline-flex",
                        backgroundColor: theme.palette.secondary.light,
                      })}
                    >
                      <img
                        src={SpeechBubble}
                        alt="Comment"
                        width={25}
                        style={{ marginRight: 10 }}
                      />
                      <Typography
                        sx={() => ({
                          fontStyle: "italic",
                        })}
                      >
                        &quot;{comment}&quot;
                      </Typography>
                    </Box>
                  )}
                  {!isNA && (
                    <Stack className="criteria-grid">
                      {criteria.criteria_details.levels.map((level, index) => {
                        const isSelected =
                          selectedLevel?.level_points === level.level_points

                        return (
                          <Box
                            sx={(theme) => ({
                              flex: 1,
                              marginInline: 1,
                              borderRadius: 1,
                              backgroundColor: isSelected
                                ? theme.palette.secondary.light
                                : "white",
                            })}
                            key={index}
                          >
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline-flex",
                                  padding: 2,
                                  borderBottom: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {level.level_name}
                              </Typography>
                            </Box>

                            <Typography
                              sx={{
                                padding: 3,
                                flex: 1,
                                fontSize: 14,
                              }}
                            >
                              {level.level_description}
                            </Typography>
                          </Box>
                        )
                      })}
                    </Stack>
                  )}
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Card>
  )
}
