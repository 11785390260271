import { useState } from "react"
import { connect } from "react-redux"

import { Popover, Button } from "@mui/material"
import moment from "moment"
import classNames from "classnames"

import "../../../styles/calendar.scss"
import he from "he"

import { MonthView } from "@devexpress/dx-react-scheduler-material-ui"
import Values from "values.js"
import theme from "@mobilemind/common/src/theme/theme"

import {
  toggleExternalEventModal,
  openEvent,
} from "../../../store/reducers/externalPD"
import { useAppDispatch } from "store/hooks"

const mapStateToProps = ({ calendar, session }) => {
  return {
    calendar,
    session,
  }
}

function MonthCellView(props) {
  const { calendar, onDoubleClick } = props
  const dispatch = useAppDispatch()

  let filteredProps = {
    calendar: props.calendar,
    endDate: props.endDate,
    startDate: props.startDate,
    today: props.today,
    otherMonth: props.otherMonth,
    formatDate: props.formatDate,
    isShaded: props.isShaded,
    onClick: props.onClick,
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const goToEvent = (event, isView) => {
    if (!event.isExternal) {
      let bundle = event.isConference ? "conference" : "event"
      if (event.isObservation) {
        bundle = "observation"
      }

      let url = "/events/" + bundle + "/" + event.drupal_internal__id

      if (isView) {
        url = "/events/view/" + bundle + "/" + event.drupal_internal__id
      }
      props.history.push(url)
    } else {
      dispatch(toggleExternalEventModal(true))
      dispatch(openEvent(event.fullEvent))
    }
  }

  let showMore

  let eventsOnDay = calendar.data.filter((event) => {
    let onDay =
      moment(event.startDate).startOf("day").format() ===
      moment(props.startDate).format()
    if (
      moment(props.startDate)
        .startOf("day")
        .isBetween(event.startDate, event.endDate)
    ) {
      onDay = true
    }
    return onDay && !event.field_archive && !event.month
  })

  if (eventsOnDay.length > 2) {
    showMore = true
  }

  if (
    eventsOnDay &&
    eventsOnDay[0] &&
    moment(eventsOnDay[0].startDate).format("YYYY-MM-DD") === "2024-11-05"
  ) {
    console.log("Events On 2024-11-05", eventsOnDay)
  }
  let numShowMore = eventsOnDay.length - 2
  const primary = new Values(theme.palette.primary.main)
  const secondary = new Values(theme.palette.secondary.main)

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const isActive = props.createNewVisible === moment(props.startDate).format()

  if (!showMore) {
    return (
      <MonthView.TimeTableCell
        className={classNames(
          isActive && "highlighted",
          props.today && "isToday"
        )}
        onClick={onDoubleClick}
        {...filteredProps}
      />
    )
  }

  return (
    <td className="maxEventOverride">
      <div onClick={handleClick} className="showMoreWrapper">
        <div className="Cell-text">{moment(props.startDate).format("D")}</div>
        <span className="showMore">+{numShowMore} More</span>
      </div>

      <Popover
        className="showMorePopover"
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ul className="sameDayEvents">
          {eventsOnDay.length > 0 &&
            eventsOnDay.map((event) => {
              let isAllDay = event.isAllDay

              let chipColor = primary
              if (event.isExternal) {
                chipColor = secondary.shade(35).hexString()
              } else if (event.isConference) {
                chipColor = primary.shade(15).hexString()
              } else if (event.field_draft) {
                chipColor = "#575757"
              }

              if (event && event.title) {
                return (
                  <li key={event.id}>
                    <div
                      className={"chip"}
                      style={{ backgroundColor: chipColor }}
                    />
                    {isAllDay ? (
                      <span className="time">All Day</span>
                    ) : (
                      <span className="time">
                        {moment(event.startDate).format("h:mma")}
                      </span>
                    )}
                    {event?.title && <strong>{he.decode(event.title)}</strong>}
                    {(!event.isConference ||
                      (event.isConference &&
                        event.eventRole !== "Secretary")) && (
                      <Button
                        onClick={() => {
                          goToEvent(event)
                        }}
                        className="button small"
                      >
                        <span className="icon edit"></span>
                        Edit
                      </Button>
                    )}

                    <div style={{ marginLeft: 20, width: 75 }}>
                      {!event.field_draft && !event.isExternal && (
                        <Button
                          onClick={() => {
                            goToEvent(event, true)
                          }}
                          className="button small"
                        >
                          <span className="icon view"></span>
                          View
                        </Button>
                      )}
                    </div>
                  </li>
                )
              }
              return null
            })}
        </ul>
      </Popover>
    </td>
  )
}

export default connect(mapStateToProps)(MonthCellView)
