import { Stack, Card } from "@mui/material"
import {
  MobileMindLocationLocationBase,
  MobileMindRoomRoomBase,
} from "@mobilemind/common/src/types/jsonapi"
import { EventLocationItem } from "./EventLocationItem"

type LocationPanelProps = {
  singleLocation: MobileMindLocationLocationBase
  locations: MobileMindLocationLocationBase[]
  isConference: boolean
  isSession: boolean
  currentRoom?: MobileMindRoomRoomBase
  availableRooms?: MobileMindRoomRoomBase[]
  changeRoom: (fullRoom: MobileMindRoomRoomBase) => void
}

export function EventLocationPanel({
  locations,
  isConference,
  singleLocation,
  currentRoom,
  availableRooms,
  isSession,
  changeRoom,
}: LocationPanelProps) {
  if (locations || singleLocation) {
    return (
      <Card
        sx={{
          overflow: "visible",
          borderRadius: "12px !important",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important",
        }}
      >
        {isConference ? (
          <Stack spacing={6}>
            {locations.map((location: MobileMindLocationLocationBase) => {
              return (
                <EventLocationItem
                  changeRoom={changeRoom}
                  location={location}
                  isConference={true}
                  availableRooms={availableRooms}
                />
              )
            })}
          </Stack>
        ) : (
          <EventLocationItem
            isSession={isSession}
            changeRoom={changeRoom}
            isConference={isConference}
            availableRooms={availableRooms}
            currentRoom={currentRoom}
            location={singleLocation}
          />
        )}
      </Card>
    )
  }

  return null
}
