import moment from "moment"
import { utcToZonedTime, format } from "date-fns-tz"
import { formatDistance, intervalToDuration } from "date-fns"

export function UTCToLocalTime(date, timezone, dateFormat) {
  let UTC
  if (date.includes("-04:00") || date.includes("-05:00")) {
    UTC = date.substring(0, date.lastIndexOf("-")) + "-00:00"
  } else {
    UTC = date
  }
  const workingDate = new Date(UTC)

  const zonedDate = utcToZonedTime(workingDate, timezone)
  const formattedDate = format(zonedDate, dateFormat)

  return formattedDate
}

export function hexToRgbA(hex) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    )
  }
  throw new Error("Bad Hex")
}

export function generateCalendarTimestamp(date) {
  if (
    moment(date).format("YYYY-MM-DD") ===
    moment().add(1, "days").format("YYYY-MM-DD")
  ) {
    return "Tomorrow at " + moment(date).format("HH:mma")
  }
  if (moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
    return "Today at " + moment(date).format("h:mma")
  } else {
    return moment(date).format("MMMM Do h:mma")
  }
}

export function generateTimestamp(date, dateOnly) {
  if (date) {
    const distance = formatDistance(new Date(date), new Date()).replace(
      "about ",
      ""
    )
    const duration = intervalToDuration({
      start: new Date(date),
      end: new Date(),
    })

    let { days, months, years } = duration

    if (days < 1 && months === 0 && years === 0) {
      return distance + " ago"
    } else if (days === 1) {
      return "Yesterday"
    } else {
      return dateOnly
        ? format(new Date(date), "M/d/yyyy")
        : "on " + format(new Date(date), "M/d/yyyy")
    }
  }
  return ""
}

/**
 * Convert estimated number of minutes for a course to a display string of hours and minutes
 * @param {number} totalMinutes The amount of estimated time for a course in minutes
 */

export function convertEstimatedTime(totalMinutes) {
  let hours =
    totalMinutes >= 60
      ? Math.floor(totalMinutes / 60).toLocaleString() + "h "
      : ""
  let minutes = (totalMinutes % 60) + "m"

  return hours + minutes
}

/**
 * Take a public/view URL to a video and generate the appropriate embed url
 * @param {string} url The URL to the video to generate the embed URL for
 */
export function generateVideoEmbedURL(url) {
  if (url.includes("youtube")) {
    if (url.includes("watch") && url.split("=")[1]) {
      // Remove any additional url parameters
      const baseUrl = url.split("&")[0]
      return "https://www.youtube.com/embed/" + baseUrl.split("=")[1]
    } else if (url.includes("embed") && url.split("/")[4]) {
      return "https://www.youtube.com/embed/" + url.split("/")[4]
    }
  } else if (url.includes("vimeo") && url.split("/")[3]) {
    const shareId = url.split("/")[4]
    if (shareId) {
      return (
        "https://player.vimeo.com/video/" +
        url.split("/")[3] +
        "?h=" +
        shareId.split("?")[0]
      )
    } else {
      return "https://player.vimeo.com/video/" + url.split("/")[3]
    }
  } else if (url.includes("screenpal")) {
    return (
      "https://go.screenpal.com/player/" +
      url.split("/")[4] +
      "?width=100%&height=100%&ff=1&title=0"
    )
  } else if (url.includes("drive.google")) {
    return url.replace("view", "preview")
  } else if (url.includes("wistia")) {
    const videoId = url.substring(url.lastIndexOf("/") + 1)
    let newUrl =
      "https://fast.wistia.net/embed/iframe/" + videoId + "?videoFoam=true"
    return newUrl
  }
}

/**
 * Return all entities based on available partners
 * @param {object} organization The user's org
 * @param {object} entities All entities to filter
 */
export function filterByPartners(organization, groups, entities, included) {
  return entities.filter((entity) => {
    // The IDs of partners that the organization has access to
    let partnerIds = []
    if (organization && organization.field_partner) {
      partnerIds = organization.field_partner.map(
        (partner) => partner.target_uuid
      )
    }

    // If they are actually a partner themself
    if (
      organization &&
      organization.type[0] &&
      organization.type[0].target_id === "partner"
    ) {
      partnerIds = [organization.uuid[0].value]
    }

    // If they're a member of a group
    if (groups && groups.length && !groups.data && !groups[0].data) {
      groups[0].forEach((group) => {
        let target = group[0] && group[0]

        // If they have partner access, add it to our array of ids
        if (target && target.field_partner) {
          target.field_partner.forEach((partner) =>
            partnerIds.push(partner.target_uuid)
          )
        }
      })
    }
    if (groups && groups.field_partner) {
      groups.field_partner.forEach((partner) =>
        partnerIds.push(partner.target_uuid)
      )
    }

    // Strip out duplicates of partners both the Org and Group have access to
    let uniquePartnerIds = [...new Set(partnerIds)]

    // If this is partner content
    if (
      entity.field_partner_target_id ||
      (entity.relationships &&
        entity.relationships.field_partner &&
        entity.relationships.field_partner.data)
    ) {
      let partnerId = entity.field_partner_target_id
        ? entity.field_partner_target_id
        : entity.relationships.field_partner.data.id
      entity.fullPartner =
        included && included.find((included) => included.id === partnerId)

      // Return it if it's for one of the org's partners or group partners
      return uniquePartnerIds.includes(
        entity.relationships.field_partner.data.id
      )
    }
    // Otherwise just return it
    return true
  })
}

/**
 * Find out all the ways MM content might need to be excluded
 * @param {object} session The current user's session object
 */
export function isMMContentHidden(session) {
  let isExcluded = false
  let groupSettings = []

  if (session.group || session.subgroup) {
    if (session.group) {
      if (
        session.group.field_org_hide_mm_content &&
        session.group.field_org_hide_mm_content[0]
      ) {
        if (session.group.field_org_hide_mm_content[0].value) {
          isExcluded = true
        }
      }
    }

    if (!isExcluded && session.subgroups && session.subgroups[0]) {
      session.subgroups.forEach((group) => {
        if (
          group[0] &&
          group[0].field_group_hide_mm_content &&
          group[0].field_group_hide_mm_content[0]
        ) {
          if (group[0].field_group_hide_mm_content[0].value) {
            groupSettings.push(group[0].field_group_hide_mm_content[0].value)
          } else {
            groupSettings.push(false)
          }
        } else {
          groupSettings.push(false)
        }
      })

      if (!groupSettings.includes(false)) {
        isExcluded = true
      }
    }
  }

  return isExcluded
}

/**
 * Generate a street address string from a drupal address object
 * @param {object} field_address The field_address attributes of a location
 */
export function addressToString(field_address) {
  let address = ""
  if (field_address) {
    address = field_address.address_line1 + " "
    if (field_address.address_line2) {
      address += field_address.address_line2 + ", "
    }
    address +=
      field_address.locality + " " + field_address.administrative_area + " "
    if (field_address.postal_code) {
      address += field_address.postal_code
    }
  }

  return address
}

export function generateTrackWidth(path) {
  let trackWidth = 0
  let goal = path.lp_goal_info && path.lp_goal_info[0]

  if (goal) {
    let duration = goal.lp_goal_duration_value
    let daysSinceStart =
      moment(goal.user_lp_goal_created_date).diff(moment(), "days") * -1

    let width = (daysSinceStart / duration) * 100

    if (Number(width)) {
      trackWidth = (daysSinceStart / duration) * 100 + "%"
    }
  }

  return trackWidth
}

export function calculateGoalPace(learningPath) {
  let paceInfo = {}

  const goal = learningPath.lp_goal_info && learningPath.lp_goal_info[0]
  const completedMinutes = Number(learningPath.field_user_lp_total_time_spent)
  const minutesLeft = Number(goal.lp_goal_est_time_value) - completedMinutes
  const coursesRemaining =
    Number(learningPath.field_num_courses) -
    Number(learningPath.field_user_lp_completed_courses)

  // The difference between the deadline and now, in months
  let monthsLeft = moment(goal.lp_goal_due_date_value).diff(moment(), "months")

  // And in days
  let daysLeft = moment(goal.lp_goal_due_date_value).diff(moment(), "days") + 1

  // The daily amount of minutes needed to meet the deadline
  let dailyPace = Math.ceil(minutesLeft / daysLeft)

  // If there's more than one month remaining in the goal
  if (monthsLeft >= 1) {
    // The amount of weeks we have left
    const weeksLeft = moment(goal.lp_goal_due_date_value).diff(
      moment(),
      "weeks"
    )
    // Minutes per week to meet pace
    const weeklyPace = Math.ceil(minutesLeft / weeksLeft)
    // Courses per week to meet pace
    const coursesPerWeek = Math.ceil(coursesRemaining / weeksLeft)

    // If there's more than an hour per week need to complete on time, return "X courses / week"
    if (weeklyPace > 60) {
      paceInfo = {
        pace: coursesPerWeek,
        unit: "week",
        type: "Courses",
      }
    }
    // Otherwise just return minutes per week
    else {
      paceInfo = {
        pace: convertEstimatedTime(weeklyPace),
        unit: "week",
      }
    }
  }

  // If there's less than a month remaining
  else {
    // If it's less than 5m / day, always show 5m / day
    if (dailyPace < 5) {
      paceInfo = {
        pace: convertEstimatedTime(5),
        unit: "day",
      }
    }
    // Or the actual minutes if it's between 5m and 20m
    else if (dailyPace >= 5 && dailyPace <= 20) {
      paceInfo = {
        pace: convertEstimatedTime(dailyPace),
        unit: "day",
      }
    }
    // If it's more than 20m / day, show "X courses / day"
    else if (dailyPace > 20) {
      paceInfo = {
        pace: Math.ceil(coursesRemaining / daysLeft),
        unit: "day",
        type: "Courses",
      }
    }
  }

  return paceInfo
}

export function generateContrastColor(hex) {
  hex = hex.indexOf("#") === 0 ? hex.slice(1) : hex

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF"
}

// This will determine whether we need to adjust an hour
// due to Daylight Saving Time & moment.js
export function setDSTDisplay(date) {
  if (moment().isDST() && !moment(date).isDST()) {
    return moment(date).add(1, "hour").format()
  }

  if (!moment().isDST() && moment(date).isDST()) {
    return moment(date).subtract(1, "hour").format()
  } else {
    return moment(date).format()
  }
}

// Converts minutes into hours and minutes
export function convertMinutesToHoursMinutes(totalMinutes) {
  totalMinutes = Number(totalMinutes)
  let hours = totalMinutes >= 60 ? Math.floor(totalMinutes / 60) : 0
  let minutes = totalMinutes % 60

  return { hours: Number(hours), minutes: Number(minutes) }
}

export function filterPartnerCategories(categories, session) {
  let payload = []

  // Need to handle partner categories here
  categories.forEach((category) => {
    if (!session.isPartner) {
      if (!category.relationships.field_organization.data) {
        if (category.relationships.field_partner.data) {
          const hasPartnerships =
            session.group.field_partner && session.group.field_partner.length

          const orgPartnerIds = session.group.field_partner.map(
            (partner) => partner.target_uuid
          )

          if (
            hasPartnerships &&
            orgPartnerIds.includes(category.relationships.field_partner.data.id)
          ) {
            payload.push(category)
          }
        } else {
          payload.push(category)
        }
      } else {
        payload.push(category)
      }
    } else {
      let partnerId = session.group.uuid[0].value
      if (
        category.relationships.field_partner.data &&
        category.relationships.field_partner.data.id === partnerId
      ) {
        payload.push(category)
      } else if (!category.relationships.field_partner.data) {
        payload.push(category)
      }
    }
  })

  return payload
}

// Utility function to asynchronously pause for x milliseconds.
// Works with async/await
export const delay = (timeout) =>
  new Promise((resolve) => setTimeout(resolve, timeout))
