import { Component } from "react"
import classNames from "classnames"
import { connect } from "react-redux"

import { markNotificationAsSeen } from "../actions/notifications"
import PropTypes from "prop-types"
import { getUnlockedPaths } from "../actions/notifications"
import ModalLearningPathCelebration from "./ModalLearningPathCelebration"

const mapStateToProps = ({
  goals,
  learningPaths,
  courses,
  categories,
  assessments,
  badges,
}) => {
  return {
    goals,
    learningPaths,
    assessments,
    categories: categories.data,
    courses: courses.courseData,
    badges: badges.badgeData,
  }
}

const mapDispatchToProps = {
  markNotificationAsSeen,
  getUnlockedPaths,
}

class NotificationPopup extends Component {
  state = {
    open: true,
  }

  closeModal = () => {
    this.setState({ open: false })
  }

  componentDidMount = () => {
    const { notification } = this.props
    if (notification) {
      this.prepareContent(notification)
      this.props.getUnlockedPaths(notification).then((response) => {
        this.setState({ unlockedPaths: response.data })
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.notification &&
      prevProps.notification.notification_id !==
        this.props.notification.notification_id
    ) {
      this.prepareContent(this.props.notification)
    }
  }

  prepareContent = (notification) => {
    let content

    if (notification.type === "assessment_complete") {
      content = this.generateCourseContent(notification)
    } else if (notification.type === "assessment_feedback") {
      if (
        notification.course_name &&
        notification.course_submission_type === "Quiz"
      ) {
        content = (
          <>
            You didn&apos;t pass the quiz for{" "}
            <strong>{notification.course_name}</strong>. Click here to review
            your results.
          </>
        )
      } else {
        content = (
          <>
            Feedback available for <strong>{notification.course_name}</strong>.
          </>
        )
      }
    } else if (notification.type === "reviewer") {
      content = this.generateReviewerContent(notification)
    } else if (notification.type === "user_badge_created") {
      content = this.generateBadgeContent(notification)
    }

    this.setState({ content })

    setTimeout(() => {
      if (
        notification.type !== "ulp_complete" &&
        notification.type !== "user_goal_complete"
      ) {
        this.setState({ open: false })
      }
      this.props.markNotificationAsSeen(notification.notification_uuid)
    }, 8000)
  }

  generateReviewerContent = (notification) => {
    let courseName = notification.course_name
    return (
      <>
        You&apos;ve been added as a reviewer for <strong>{courseName}</strong>.
      </>
    )
  }

  generateCourseContent = (notification) => {
    let courseName = notification.course_name
    let index = Math.floor(Math.random() * Math.floor(3))

    let options = [
      <>
        <strong>Woohoo!</strong> You completed <strong>{courseName}</strong>.
        What&apos;s next?
      </>,
      <>
        <strong>Oh yeah! {courseName}</strong> is done! Let&apos;s keep going!
      </>,
      <>
        <strong>Sweet!</strong> You rocked the challenge for{" "}
        <strong>{courseName}</strong>. Keep it up!
      </>,
      <>
        <strong>Great job!</strong> You successfully finished{" "}
        <strong>{courseName}</strong>. What’s next?
      </>,
    ]

    return options[index]
  }

  generateBadgeContent = (notification) => {
    let badgeName = notification.badge_name
    let index = Math.floor(Math.random() * Math.floor(4))

    let options = [
      <>
        <strong>Woohoo!</strong> You earned the <strong>{badgeName}</strong>{" "}
        badge. What&apos;s next?
      </>,
      <>
        <strong>Nice!</strong> You snagged the <strong>{badgeName}</strong>{" "}
        badge. Keep going!
      </>,
      <>
        <strong>Oh yeah!</strong> The <strong>{badgeName}</strong> badge is
        yours! What&apos;s next?
      </>,
      <>
        <strong>Sweet!</strong> You earned the <strong>{badgeName}</strong>{" "}
        badge. Keep going!
      </>,
      <>
        <strong>Great job!</strong> You got the <strong>{badgeName}</strong>{" "}
        badge. What&apos;s next?
      </>,
    ]

    return options[index]
  }

  handleClick = () => {
    const { notification } = this.props
    this.setState({ open: false })
    this.props.markNotificationAsSeen(notification.notification_uuid)
    let type = notification.type

    if (type === "assessment_complete") {
      this.props.setSidebar("achievements", "completed")
    }
    if (type === "user_badge_created") {
      this.props.setSidebar("achievements", "badgesEarned")
    }
  }

  render() {
    const { content } = this.state
    const { notification, categories, learningPaths } = this.props
    const isSeen = notification.seen === "1"

    let type = notification.type

    let isExternalURL = this.props.isHQ
    let path

    if (type === "reviewer") {
      isExternalURL = !this.props.isHQ
      path = process.env.REACT_APP_HQ_URL + "/review"
    }

    const innerContent = (
      <>
        <div className="inner">
          <div className="flexRow">
            {type === "user_badge_created" && (
              <div
                style={{
                  flexShrink: 0,
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    notification.badge_image +
                    ")",
                  width: 40,
                  height: 40,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  marginRight: 10,
                  borderRadius: 40,
                  boxShadow: "0 2px 4px 0 rgb(0 0 0 / 40%)",
                }}
              />
            )}
            {type === "reviewer" && <div className="icon reviewer" />}
            {type === "ulp_completed" && <div className="icon learningPath" />}
            {type.includes("assessment") && (
              <div
                className={classNames(
                  "icon",
                  notification.type,
                  notification.type === "assessment_feedback" &&
                    notification.course_submission_type === "Quiz" &&
                    "quiz"
                )}
              />
            )}
            <p>{content}</p>
          </div>
        </div>
      </>
    )

    return (
      <>
        {!this.props.isHQ &&
          (type === "ulp_complete" || type === "user_goal_complete") &&
          this.state.open &&
          !(1 + 1 == 2) && (
            <ModalLearningPathCelebration
              learningPaths={learningPaths}
              unlockedPaths={this.state.unlockedPaths}
              markAsSeen={() => {
                this.handleClick()
              }}
              closeULPModal={() => {
                this.closeModal()
              }}
              categories={categories}
              learningPath={
                notification.learningPath ? notification.learningPath : {}
              }
              notification={notification}
              goals={this.props.goals}
              getUserGoals={this.props.getUserGoals}
              getLearningPaths={this.props.getLearningPaths}
              setSidebar={this.props.setSidebar}
            />
          )}

        {notification &&
          !isSeen &&
          !this.props.isHQ &&
          type !== "ulp_complete" &&
          type !== "user_goal_complete" && (
            <>
              {isExternalURL ? (
                <a
                  style={{ pointerEvents: this.state.open ? "all" : "none" }}
                  className={classNames(
                    "notification-popup",
                    !this.state.open && "slideOut"
                  )}
                  href={path}
                >
                  {innerContent}
                </a>
              ) : (
                <div
                  style={{ pointerEvents: this.state.open ? "all" : "none" }}
                  onClick={this.props.onClick}
                  className={classNames(
                    "notification-popup",
                    !this.state.open && "slideOut"
                  )}
                >
                  {innerContent}
                </div>
              )}
            </>
          )}
      </>
    )
  }
}

NotificationPopup.propTypes = {
  assessments: PropTypes.object,
  badges: PropTypes.array,
  courses: PropTypes.array,
  notification: PropTypes.object,
  session: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup)
