import { useEffect, useState } from "react"
import GoogleMapsContainer from "../GoogleMapsContainer"
import { MobileMindLocationLocationBase } from "../../types/jsonapi"
import { addressToString } from "../../functions"
import { Box, Typography, Link, alpha } from "@mui/material"

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"

import LocationIcon from "../../img/location-color.svg"

type AccordionProps = {
  locations: MobileMindLocationLocationBase[]
  locationId: string
}

export function SidebarLocationsAccordion({
  locations,
  locationId,
}: AccordionProps) {
  const [expanded, setExpanded] = useState<string>(locationId)

  useEffect(() => {
    setExpanded(locationId)
  }, [locationId])

  return (
    <Box>
      <Typography
        sx={{
          textAlign: "center",
          backgroundColor: "#54839f",
          textTransform: "uppercase",
          color: "white",
          marginLeft: -2,
          marginBlock: 2,
          borderRadius: 1,
          marginRight: -2,
          fontSize: 10,
          fontWeight: "bold",
          paddingBlock: 1,
        }}
      >
        Conference Locations
      </Typography>
      {locations.map(
        (location: MobileMindLocationLocationBase, index: number) => {
          const { field_address } = location.attributes

          return (
            <Accordion
              key={index}
              sx={{
                boxShadow: "none",
                borderColor: locationId ? "#ebebeb" : "transparent",
                borderBottomWidth: 1,
                marginLeft: -4,
                marginRight: -4,
                marginBottom: 0,
                borderRadius: "0px !important",

                "& .MuiAccordionSummary-root.Mui-expanded": {
                  backgroundColor: alpha("#54839f", 0.25),
                },
              }}
              disableGutters={true}
              square={true}
              expanded={expanded === location.id}
              onChange={() => {
                setExpanded(location.id)
              }}
            >
              <AccordionSummary
                aria-controls={`${location.id}-content`}
                id={`${location.id}-header`}
              >
                <img
                  src={LocationIcon}
                  alt="Location"
                  width={15}
                  style={{ marginRight: 5 }}
                />
                <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                  {location.attributes.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 2 }}>
                <Typography sx={{ fontSize: 12, marginBottom: 2 }}>
                  {field_address && (
                    <Link
                      href={
                        "https://www.google.com/maps?q=" +
                        addressToString(field_address)
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <strong>
                        {field_address.address_line1},{" "}
                        {field_address.address_line2 &&
                          field_address.address_line2 + ","}{" "}
                        {field_address.locality}{" "}
                        {field_address.administrative_area}{" "}
                        {field_address.postal_code}
                      </strong>
                    </Link>
                  )}
                </Typography>

                {field_address && <GoogleMapsContainer location={location} />}

                {location.attributes.field_directions && (
                  <Typography sx={{ fontSize: 12, lineHeight: "1.15rem" }}>
                    &quot;{location.attributes.field_directions}&quot;
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )
        }
      )}
    </Box>
  )
}
