import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"

export const updateOrgSettings = createAsyncThunk(
  "session/updateOrgSettings",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()
    const org = session.group
    const { values } = args

    const {
      field_teacher_leaderboard_visibi,
      field_rewind_begin,
      field_rewind_end,
      field_open_time,
      field_close_time,
      field_knowbe4_api_key,
      field_org_time_zone,
      field_disable_observation,
    } = values

    const body = {
      data: {
        type: "group--organization",
        id: org.uuid[0].value,
        attributes: {
          field_teacher_leaderboard_visibi,
          field_knowbe4_api_key,
          field_org_time_zone,
          field_disable_observation,
          field_rewind_begin: moment(field_rewind_begin).format("YYYY-MM-DD"),
          field_rewind_end: moment(field_rewind_end).format("YYYY-MM-DD"),
          field_open_time: moment(field_open_time).format("h:mma"),
          field_close_time: moment(field_close_time).format("h:mma"),
        },
      },
    }

    let response = await fetchWrapper.patch(
      "/api/group/organization/" + org.uuid[0].value,
      session.token,
      JSON.stringify(body)
    )

    let data = await response.json()

    return data
  }
)

export const removeKB4APIKey = createAsyncThunk(
  "session/removeKB4APIKey",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()
    const org = session.group

    const body = {
      data: {
        type: "group--organization",
        id: org.uuid[0].value,
        attributes: {
          field_knowbe4_api_key: null,
        },
      },
    }

    let response = await fetchWrapper.patch(
      "/api/group/organization/" + org.uuid[0].value,
      session.token,
      JSON.stringify(body)
    )

    let data = await response.json()

    return data
  }
)
