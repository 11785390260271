import React from "react"
import { Autocomplete, FormControl, TextField, Switch } from "@mui/material"

import { RootState } from "../../store/types"
import Extension from "../../img/extension.svg"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import School from "../../img/school.svg"

import { MarketplaceItem } from "./types"
import Loading from "@mobilemind/common/src/components/Loading"
import { useState } from "react"

import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import DeleteButton from "../../components/DeleteButton"
import { SubGroup } from "@mobilemind/common/src/types/groups"
import {
  addPartnerToOrganization,
  removePartnerFromGroups,
  removePartnerFromOrganization,
} from "../../actions/partners"
import { openSnackbar } from "../snackbar/snackbarSlice"
import { useHistory } from "react-router-dom"
import { startNewPartnerAnnouncement } from "features/createAnnouncement/activeAnnouncementSlice"
import {
  getMarketplaceItems,
  getSinglePartnerImage,
} from "store/reducers/marketplace"
import { getSubGroups } from "store/reducers/session"

type ModalProps = {
  partner: MarketplaceItem | null
  subGroupsWithPartner: SubGroup[]
  onClose: () => void
}

export const PartnerGroupSelect = ({
  partner,
  subGroupsWithPartner,
  onClose,
}: ModalProps) => {
  const {
    group,
    subGroups,
    userCollection,
    activeMembership,
    isCollectionAdmin,
  } = useAppSelector((state: RootState) => {
    return state.session
  })

  const [groupSearch, setGroupSearch] = useState("")
  const [selectedGroups, setSelectedGroups] = useState(subGroupsWithPartner)
  const [deletedGroups, setDeletedGroups] = useState<SubGroup[]>([])

  const [isCreateAnnouncement, setIsCreateAnnouncement] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [deliverAll, setDeliverAll] = useState(
    subGroupsWithPartner.length > 0 ? false : true
  )

  const dispatch = useAppDispatch()
  const history = useHistory()
  const showSaveButton = (!deliverAll && selectedGroups.length) || deliverAll

  const isCollectionAdminView = isCollectionAdmin && !activeMembership?.data
  const groupLabel = isCollectionAdminView
    ? userCollection[0].label[0].value
    : group.label[0].value
  return (
    <>
      <div>
        {!isCollectionAdminView && (
          <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
            <div style={styles.switchLabel}>
              <strong>Deliver to All {groupLabel} Members</strong>
              <span style={styles.helperText}>
                All members of {groupLabel} can access this content. If
                unchecked, you can select a from list of groups.
              </span>
            </div>

            <Switch
              checked={deliverAll}
              color="secondary"
              name="deliver_all_groups"
              onChange={(event) => {
                setDeliverAll(event.target.checked)
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        )}

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={styles.switchLabel}>
            <strong>Create Announcement</strong>
            <span style={styles.helperText}>
              A draft announcement will be generated for learners receiving this
              content. You can publish or delete it at any time.
            </span>
          </div>

          <Switch
            checked={isCreateAnnouncement}
            color="secondary"
            name="create_announcement"
            onChange={(event) => {
              setIsCreateAnnouncement(event.target.checked)
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>

        {!deliverAll && (
          <>
            <FormControl
              variant="standard"
              className={classNames("inputSelect subGroup")}
              style={{ width: "100%", marginTop: 15, marginBottom: -20 }}
            >
              <Autocomplete
                style={{ width: "100%" }}
                id="subGroup-select"
                loadingText={
                  groupSearch
                    ? "Getting groups..."
                    : "Start typing to search..."
                }
                options={subGroups.data.filter(
                  (group) =>
                    !selectedGroups.find((selected) => selected.id === group.id)
                )}
                getOptionLabel={(group: SubGroup) =>
                  group.attributes?.label ?? ""
                }
                renderInput={(params) => (
                  <TextField
                    style={{ width: "100%" }}
                    {...params}
                    value={groupSearch}
                    onChange={(event) => {
                      setGroupSearch(event.target.value)
                    }}
                    label="Search for Groups..."
                  />
                )}
                onChange={(event, newValue) => {
                  setGroupSearch("")
                  if (newValue) {
                    const selected = [...selectedGroups]

                    if (selected) {
                      selected.unshift(newValue)
                    }

                    newValue &&
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setSelectedGroups(selected)
                  }
                }}
              />
            </FormControl>

            <ul style={styles.groupsList}>
              {selectedGroups.map((group: SubGroup, index: number) => {
                return (
                  <li
                    key={group.id}
                    style={{
                      ...styles.groupItem,
                      borderBottom:
                        index === selectedGroups.length - 1
                          ? "1px solid #ebebeb"
                          : "none",
                    }}
                  >
                    <img src={School} alt="Group" style={styles.schoolIcon} />

                    <strong style={{ flex: 1 }}>
                      {group?.attributes?.label}
                    </strong>

                    <DeleteButton
                      onClick={() => {
                        const selected = [...selectedGroups].filter(
                          (existing: SubGroup) => {
                            return existing.id !== group.id
                          }
                        )
                        if (!deletedGroups.find((g) => g.id === group.id)) {
                          setDeletedGroups([...deletedGroups, group])
                        }

                        setSelectedGroups(selected)
                      }}
                    />
                  </li>
                )
              })}
            </ul>
          </>
        )}
      </div>
      {!showSaveButton && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: 12,
            opacity: 0.6,
          }}
        >
          <strong>Select at least one Group</strong>
        </div>
      )}
      {showSaveButton && !isLoading && (
        <footer style={{ ...styles.footer, marginTop: 0 }}>
          <ButtonLarge
            onClick={async () => {
              setIsLoading(true)

              if (deletedGroups.length) {
                await dispatch(
                  removePartnerFromGroups({
                    partner,
                    subGroupsWithPartner: deletedGroups,
                  })
                )
              }

              if (selectedGroups.length > 0) {
                dispatch(removePartnerFromOrganization({ partner }))
              }

              const response = await dispatch(
                addPartnerToOrganization({
                  deliverAll,
                  partner,
                  selectedGroups,
                })
              )

              await dispatch(getMarketplaceItems({}))
              await dispatch(getSubGroups())
              setIsLoading(false)
              onClose()

              if (response && partner) {
                dispatch(
                  openSnackbar({
                    message: (
                      <>
                        <strong>{partner.name}</strong> successfully added.
                      </>
                    ),
                  })
                )

                setTimeout(() => {
                  dispatch({
                    type: "snackbar/closeSnackbar",
                  })
                }, 2000)
              }

              if (isCreateAnnouncement) {
                const partnerID = partner && partner.uuid
                dispatch(getSinglePartnerImage({ partnerID })).then(
                  (imageData) => {
                    dispatch(
                      startNewPartnerAnnouncement({
                        partner,
                        image: imageData.payload,
                      })
                    )
                  }
                )
                history.push("/create/announcements/new", {
                  partner,
                  selectedGroups,
                })
              }
            }}
          >
            <img
              alt="Extension"
              src={Extension}
              style={{ width: 25, marginRight: 10 }}
            />
            {deliverAll ? (
              <>Add to {groupLabel}</>
            ) : (
              <>Add to Selected Groups</>
            )}
          </ButtonLarge>
        </footer>
      )}

      {isLoading && (
        <div style={styles.loadingContainer}>
          <Loading message="Adding partner content..." />
        </div>
      )}
    </>
  )
}

const styles = {
  helperText: {
    fontSize: 12,
    opacity: 0.6,
    maxWidth: 485,
  },
  groupItem: {
    display: "flex",
    alignItems: "center",
    padding: "12px 20px",
    borderTop: "1px solid #ebebeb",
  },
  schoolIcon: {
    width: 30,
    height: 30,
    border: "3px solid white",
    boxShadow: "0 2px 1px 1px rgba(0, 0, 0, 0.17)",
    borderRadius: 30,
    marginRight: 10,
  },
  loadingContainer: {
    width: "100%",
    marginTop: 35,
    display: "flex",
    justifyContent: "center",
  },
  groupsList: {
    listStyleType: "none",
    marginLeft: -20,
    marginRight: -20,
    maxHeight: 400,
    overflow: "auto",
  },
  switchLabel: {
    display: "flex",
    flexDirection: "column" as "column",
    fontSize: 14,
    flex: 1,
    marginTop: 10,
  },
  footer: {
    display: "flex",
    justifyContent: "center",
  },
}
