import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material"
import { CourseTemplateView } from "features/courseForm/components/CourseTemplateView"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import { useGetCommunityCourseByIdQuery } from "../common/communityCourseApi"
import { CreateCourseGenieStep } from "../common/types"
import { CreateCourseGenieContext } from "./CreateCourseGenieContext"
import { GenieMessage } from "./GenieMessage"
import { useAppSelector } from "store/hooks"

type CommunityCourseReviewProps = {
  courseId: string
}

export const CommunityCourseReview = (props: CommunityCourseReviewProps) => {
  const { courseId } = props
  const categories = useAppSelector((state) => state.categories)
  const history = useHistory()
  const { setCurrentStep } = useContext(CreateCourseGenieContext)
  const { data, isLoading } = useGetCommunityCourseByIdQuery({
    id: courseId,
    categories,
  })

  if (isLoading) {
    return (
      <DialogContent
        sx={{
          height: 400,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <CircularProgress />
      </DialogContent>
    )
  }
  if (!data) {
    return null
  }
  return (
    <>
      <DialogContent sx={{ padding: 0 }}>
        <CourseTemplateView course={data} />
      </DialogContent>
      <DialogActions
        sx={{
          position: "relative",
          my: -5,
          pt: 10,
          backgroundImage:
            "linear-gradient(180deg, rgba(255,255,255,0) 0px, rgba(255,255,255,1) 25px)",
          zIndex: 1,
        }}
      >
        <GenieMessage sx={{ my: 0 }}>
          <strong>Would this course work?</strong> Click{" "}
          <strong>Use Course</strong> and we'll get started!
        </GenieMessage>
      </DialogActions>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setCurrentStep({ step: CreateCourseGenieStep.Search })}
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            history.push(`/create/courses/${courseId}/template`)
          }}
        >
          Use Course
        </Button>
      </DialogActions>
    </>
  )
}
