import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"
import _ from "lodash"
import he from "he"

import { setDSTDisplay } from "@mobilemind/common/src/functions"
import type { RootState } from "../types"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import { UTCToLocalTime } from "@mobilemind/common/src/functions"
import { HQRecommendedEvent } from "@mobilemind/common/src/types/events"

export const fetchPersonnelEvents = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("calendarSlice/fetchPersonnelEvents", async (args, thunkAPI) => {
  const { locations } = thunkAPI.getState()
  const { dateRange } = args

  let personnelEvents: any[] = []
  let query = {
    event_date: dateRange,
  }

  let response = await fetchWrapper.get(
    "/api/events-personnel-calendar?" + qs.stringify(query)
  )

  if (response.ok) {
    let data = await response.json()

    // If there's actual content
    if (!data.rows.content) {
      data.rows.forEach((event: any) => {
        // Decode the name
        event.name = he.decode(event.field_event_name)
        // Add the full location
        event.location = locations.data.find((location: any) => {
          return location.attributes.name === event.field_location
        })
        personnelEvents.push(event)
      })
    } else {
      personnelEvents = []
    }

    return personnelEvents
  }
})

export const fetchRecommendedEvents = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("calendarSlice/fetchRecommendedEvents", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const { orgRoles, groupRoles } = session
  const { dateRange } = args
  let query: {
    group?: number | null
    start: string
    end: string
    page: {
      offset: number
    }
  } = {
    start: dateRange.min,
    end: dateRange.max,
    page: {
      offset: 0,
    },
  }
  if (
    !orgRoles.includes("organization-scheduler") &&
    !orgRoles.includes("organization-admin") &&
    (session.isGroupAdmin || groupRoles.includes("group-scheduler"))
  ) {
    query.group = session.subgroup.id[0].value
  }

  let response = await fetchWrapper.get(
    "/api/mm_rec_ext_event/hq/" +
      session.group.id[0].value +
      "?" +
      qs.stringify(query)
  )

  if (response.ok) {
    let data = await response.json()
    const keys = Object.keys(data.rec_ext_event_data)
    let recommendedData = keys.map((key) => data.rec_ext_event_data[key])

    recommendedData.forEach((event: HQRecommendedEvent) => {
      if (event.field_start_date_value && event.field_end_date_value) {
        event.field_start_date_value = UTCToLocalTime(
          event.field_start_date_value + "-00:00",
          session.user.attributes.timezone,
          "yyyy-MM-dd'T'HH:mm:ss"
        )

        event.field_end_date_value = UTCToLocalTime(
          event.field_end_date_value + "-00:00",
          session.user.attributes.timezone,
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      }
    })

    return recommendedData
  }
})

export const fetchObservationEvents = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("calendarSlice/fetchObservationEvents", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const { orgRoles, groupRoles } = session

  const { dateRange } = args

  let query: { group?: number | null; event_date: any } = {
    event_date: dateRange,
  }

  if (
    !orgRoles.includes("organization-scheduler") &&
    !orgRoles.includes("organization-admin") &&
    (session.isGroupAdmin || groupRoles.includes("group-scheduler"))
  ) {
    query.group = session.subgroup.id[0].value
  }

  let response = await fetchWrapper.get(
    "/api/observe-calendar/" +
      session.group.id[0].value +
      "?" +
      qs.stringify(query)
  )

  if (response.ok) {
    let data = await response.json()

    let finalData
    if (!data.rows.content) {
      finalData = data.rows.map((event: any) => {
        event.isObservation = true
        return event
      })
    }

    return finalData
  }
})

export const fetchCalendar = createAsyncThunk<any, any, { state: RootState }>(
  "calendarSlice/fetchCalendar",
  async (args, thunkAPI) => {
    const { session, locations } = thunkAPI.getState()
    const { dateRange, observationsOnly } = args
    let calendarEvents: any[] = []

    if (!observationsOnly) {
      let query = {
        event_date: dateRange,
        archive: 0,
        draft: "All",
      }

      let url = "/api/events-calendar/" + session.group.id[0].value
      if (
        !session.orgRoles.includes("organization-admin") &&
        !session.orgRoles.includes("organization-scheduler")
      ) {
        url = "/api/events-group-calendar/" + session.subgroup.id[0].value
      }

      let response = await fetchWrapper.get(url + "?" + qs.stringify(query))

      if (response.ok) {
        let data = await response.json()

        if (!data.rows.content) {
          data.rows.forEach((event: any) => {
            event.name = he.decode(event.name)
            event.location = locations.data.filter((location: any) => {
              return event.field_location.includes(
                location.attributes.drupal_internal__id
              )
            })
            calendarEvents.push(event)
          })
        } else {
          calendarEvents = []
        }
        let recommended = await thunkAPI.dispatch(
          fetchRecommendedEvents({ dateRange })
        )
        let observation = await thunkAPI.dispatch(
          fetchObservationEvents({ dateRange })
        )

        return {
          events: calendarEvents,
          recommended: recommended.payload,
          observation: observation.payload,
          locations: locations.data,
        }
      }
    } else {
      let observation = await thunkAPI.dispatch(
        fetchObservationEvents({ dateRange })
      )

      return {
        events: calendarEvents,
        recommended: [],
        observation: observation.payload,
        locations: locations.data,
      }
    }
  }
)

export const debouncedFetchCalendar = debounceThunk(fetchCalendar, 750)

export const fetchArchivedEvents = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("calendarSlice/fetchArchivedEvents", async (args, thunkAPI) => {
  const { session, locations } = thunkAPI.getState()
  let archivedEvents: any[] = []

  let query = {
    archive: 1,
    sort_order: "DESC",
  }

  let url = "/api/events-calendar/" + session.group.id[0].value
  if (
    !session.orgRoles.includes("organization-admin") &&
    !session.orgRoles.includes("organization-scheduler")
  ) {
    url = "/api/events-group-calendar/" + session.subgroup.id[0].value
  }

  let response = await fetchWrapper.get(url + "?" + qs.stringify(query))

  if (response.ok) {
    let data = await response.json()

    if (!data.rows.content) {
      data.rows.forEach((event: any) => {
        event.name = he.decode(event.name)
        event.location = locations.data.find((location: any) => {
          return (
            location.attributes.drupal_internal__id ===
            Number(event.field_location)
          )
        })
        archivedEvents.push(event)
      })
    } else {
      archivedEvents = []
    }

    return { events: archivedEvents }
  }
})

type InitialState = {
  data: any[]
  truncatedData: any[]
  currentDate: string
  recommendedEvents: {
    data: any[]
    fetched: boolean
  }
  observationEvents: {
    data: any[]
    fetched: boolean
  }
  fetched: boolean
  archived: {
    fetched: boolean
    data: any[]
  }
  isSearchActive: boolean
  searchQuery: ""
  showArchived: boolean
  sidebarActiveItem: string
  categories: {
    data: any[]
    fetched: boolean
  }
}

const initialState: InitialState = {
  data: [],
  truncatedData: [],
  currentDate: moment().format("YYYY-MM-DD"),
  recommendedEvents: {
    data: [],
    fetched: false,
  },
  observationEvents: {
    data: [],
    fetched: false,
  },
  fetched: false,
  archived: {
    fetched: false,
    data: [],
  },
  isSearchActive: false,
  searchQuery: "",
  showArchived: false,
  sidebarActiveItem: "Event Calendar",
  categories: {
    data: [],
    fetched: false,
  },
}

export const calendarSlice = createSlice({
  name: "calendarSlice",
  initialState,
  reducers: {
    setCurrentDate: (state, action) => {
      state.currentDate = action.payload
    },

    setSidebarItem: (state, action) => {
      state.sidebarActiveItem = action.payload
    },
    setSearchActive: (state, action) => {
      state.isSearchActive = action.payload
      if (!action.payload) {
        state.searchQuery = ""
      }
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    unArchive: (state, action) => {
      let targetEvent = state.data.find(
        (event) => event.id === action.payload.id
      )
      if (targetEvent) {
        targetEvent.field_archive = false
      }

      state.archived.data = state.archived.data.filter(
        (event: any) => event.id !== action.payload.id
      )
      let newData = [...state.data]
      newData.push(action.payload)
      state.data = _.orderBy(newData, (event: any) => event.startDate)
      state.truncatedData = truncateData(newData)
    },
    updateSavedEvent: (state, action) => {
      const { event } = action.payload
      const locations = action.payload.locations.data

      let targetEvent = state.data.find(
        (event) => event.id === action.payload.event.id
      )
      let startDate: any, endDate: any

      if (event.attributes.field_all_day) {
        startDate = moment(event.attributes.field_event_date_time[0].value)
          .startOf("day")
          .format()
        endDate = moment(event.attributes.field_event_date_time[0].value)
          .endOf("day")
          .subtract(1, "hour")
          .format()
      } else {
        startDate = moment(
          event.attributes.field_event_date_time[0].value
        ).format()
        endDate = moment(
          event.attributes.field_event_date_time[0].end_value
        ).format()
      }

      if (targetEvent) {
        targetEvent.name = event.attributes.name
        targetEvent.startDate = startDate
        targetEvent.endDate = endDate
        targetEvent.title = event.attributes.name
        targetEvent.field_draft = event.attributes.field_draft
        targetEvent.field_archive = event.attributes.field_archive
        targetEvent.isConference =
          event.type === "mobilemind_event_entity--conference"
        targetEvent.isObservation =
          event.type === "mobilemind_event_entity--observation"
        targetEvent.description = event.attributes.field_description
        targetEvent.image = action.payload.image.image
          ? action.payload.image.image
          : action.payload.image.file
        targetEvent.location = locations.find(
          (location: any) =>
            event.relationships.field_location.data &&
            location.id === event.relationships.field_location.data.id
        )
      } else {
        state.data.push({
          id: event.id,
          drupal_internal__id: event.attributes.drupal_internal__id,
          startDate,
          endDate,
          title: event.attributes.name,
          field_draft: event.attributes.field_draft,
          field_archive: event.attributes.field_archive,
          isConference: event.type === "mobilemind_event_entity--conference",
          isObservation: event.type === "mobilemind_event_entity--observation",
          description: event.attributes.field_description,
          location: locations.find(
            (location: any) =>
              event.relationships.field_location.data &&
              location.id === event.relationships.field_location.data.id
          ),
          category: event.relationships.field_event_category.data
            ? event.relationships.field_event_category.data
            : "None",
        })
      }

      state.data = _.orderBy(state.data, (event: any) => event.startDate)
      state.truncatedData = truncateData(state.data)
    },
    dropEvent: (state, action) => {
      const { startDate, endDate } = action.payload
      let targetEvent = state.data.find((event) => {
        return event.id === action.payload.id
      })

      targetEvent.startDate = startDate
      targetEvent.endDate = endDate

      state.data = _.orderBy(state.data, (event: any) => event.startDate)
      state.truncatedData = truncateData(state.data)
    },
    archiveEvent: (state, action) => {
      let targetEvent = state.data.find(
        (event) => event.id === action.payload.event.id
      )
      if (targetEvent) {
        targetEvent.field_archive = true
      }

      state.data = _.orderBy(state.data, (event: any) => event.startDate)
      state.truncatedData = truncateData(state.data)
    },
    removeDeletedEvent: (state, action) => {
      state.data = state.data.filter((event) => event.id !== action.payload)
      state.truncatedData = truncateData(state.data)
    },
    setShowArchive: (state, action) => {
      state.showArchived = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecommendedEvents.fulfilled, (state, action: any) => {
      state.recommendedEvents.data = action.payload
      state.recommendedEvents.fetched = true
    })
    builder.addCase(fetchObservationEvents.fulfilled, (state, action: any) => {
      state.observationEvents.data = action.payload
      state.observationEvents.fetched = true
    })

    builder.addCase(fetchCalendar.pending, (state, action: any) => {
      if (!action.meta.arg.isFetchingMore) {
        state.fetched = false
      }
    })

    builder.addCase(fetchPersonnelEvents.pending, (state, action: any) => {
      state.fetched = false
    })

    builder.addCase(fetchPersonnelEvents.fulfilled, (state, action: any) => {
      let events = action.payload.map((event: any) => {
        let startDate: string, endDate: string

        if (event.field_all_day === "True") {
          startDate = moment(event.start_date).startOf("day").format()
          endDate = moment(event.start_date)
            .add(1, "day")
            .startOf("day")
            .format()
        } else {
          startDate = moment(setDSTDisplay(event.start_date)).format()
          endDate = moment(setDSTDisplay(event.end_date)).format()
        }

        if (moment(startDate).isDST() && !moment().isDST()) {
          startDate = moment(startDate).add(1, "hour").format()
          endDate = moment(endDate).add(1, "hour").format()
        } else if (!moment(startDate).isDST() && moment().isDST()) {
          startDate = moment(startDate).subtract(1, "hour").format()
          endDate = moment(endDate).subtract(1, "hour").format()
        }

        let isConference = event.field_award_credit_for_conferenc ? true : false

        let eventObject = {
          bundle: event.field_bundle,
          id: event.uuid,
          field_draft: event.field_draft === "True",
          field_parent_conference: event.field_parent_conference_name,
          isAllDay: event.field_all_day === "True",
          isConference,
          image: event.field_event_image_path,
          drupal_internal__id: event.id,
          startDate,
          eventRole: event.field_event_role_name,
          endDate,
          title: event.name,
          description: event.field_description,
          location: event.location,
        }

        return eventObject
      })

      const orderedEvents = _.orderBy(events, (event: any) => event.startDate)
      orderedEvents.forEach((event: any) => {
        if (!state.data.find((existing) => existing.id === event.id)) {
          state.data.push(event)
        }
      })
      let truncatedData: any[] = []

      state.data.forEach((event) => {
        let eventsOnDay = truncatedData.filter((existing) => {
          let onDay =
            moment(existing.startDate).startOf("day").format("MM/DD") ===
            moment(event.startDate).startOf("day").format("MM/DD")

          if (
            moment(event.startDate).isBetween(
              existing.startDate,
              existing.endDate
            )
          ) {
            onDay = true
          }

          return onDay
        })

        if (eventsOnDay.length < 2) {
          truncatedData.push(event)
        }
      })

      state.truncatedData = truncatedData
      state.fetched = true
    })

    builder.addCase(fetchCalendar.fulfilled, (state, action: any) => {
      if (
        moment(state.currentDate).format("MM") !==
        moment(action.meta.arg.dateRange.min).format("MM")
      ) {
        let events =
          action.payload && action.payload.events.length
            ? action.payload.events.map((event: any) => {
                let startDate: string, endDate: string

                if (event.field_all_day === "True") {
                  startDate = moment(event.start_date).startOf("day").format()
                  endDate = moment(event.start_date)
                    .add(1, "day")
                    .startOf("day")
                    .format()
                } else {
                  startDate = moment(setDSTDisplay(event.start_date)).format()
                  endDate = moment(setDSTDisplay(event.end_date)).format()
                }

                if (moment(startDate).isDST() && !moment().isDST()) {
                  startDate = moment(startDate).add(1, "hour").format()
                  endDate = moment(endDate).add(1, "hour").format()
                } else if (!moment(startDate).isDST() && moment().isDST()) {
                  startDate = moment(startDate).subtract(1, "hour").format()
                  endDate = moment(endDate).subtract(1, "hour").format()
                }

                let eventObject = {
                  startDate,
                  categoryId: Number(event.field_event_category),
                  endDate,
                  id: event.uuid,
                  isAllDay: event.field_all_day === "True",
                  isConference: event.field_award_credit_for_conferenc
                    ? true
                    : false,
                  canView: true,
                  rsvp: event.field_rsvp,
                  field_archive: event.field_archive === "True",
                  field_draft: event.field_draft === "True",
                  image: event.field_event_image,
                  drupal_internal__id: event.id,
                  title: event.name,
                  description: event.field_description,
                  location: event.location,
                }

                return eventObject
              })
            : []

        let allRecommended = action.payload.recommended
          ? action.payload.recommended.map((event: any) => {
              return {
                startDate: event.field_start_date_value,
                endDate: event.field_end_date_value,
                id: event.uuid,
                isAllDay: false,
                isConference: false,
                field_draft: false,
                image:
                  event.image &&
                  event.image.replace(process.env.REACT_APP_API_URL, ""),
                title: event.title,
                description: event.description__value,
                fullEvent: event,
                isExternal: true,
              }
            })
          : []

        events = events.concat(allRecommended)

        let allObservation =
          action.payload.observation &&
          action.payload.observation.map((event: any) => {
            const location = action.payload.locations.find(
              (location: any) =>
                location.attributes.drupal_internal__id ===
                Number(event.field_location)
            )

            return {
              startDate: event.start_date,
              endDate: event.end_date,
              drupal_internal__id: event.id,
              uuid: event.uuid,
              isAllDay: Boolean(event.field_all_day === "True"),
              isConference: false,
              isSession: false,
              isObservation: true,
              field_archive: Boolean(event.field_archive === "True"),
              field_draft: Boolean(event.field_draft === "True"),
              image: event.field_event_image,
              title: event.name,
              description: event.field_description,
              fullEvent: event,
              location: [location],
              field_rubric: event.field_rubric,
              isExternal: false,
              observationUser: {
                field_first_name: event.field_first_name,
                field_last_name: event.field_last_name,
                user_picture: event.user_picture,
              },
            }
          })

        events = events.concat(allObservation)

        if (!events.length) {
          const monthsSpan = moment
            .duration(
              moment(action.meta.arg.dateRange.max)
                .add(2, "days")
                .diff(moment(action.meta.arg.dateRange.min))
            )
            .months()
          for (let i = 0; i < monthsSpan; i++) {
            events.push({
              id: moment(action.meta.arg.dateRange.min)
                .add(i, "months")
                .format("YYYY-MM-DD"),
              isEmptyMonth: true,
              startDate: moment(action.meta.arg.dateRange.min)
                .add(i, "months")
                .format("YYYY-MM-DD"),
              month: moment(action.meta.arg.dateRange.min)
                .add(i, "months")
                .format("YYYY-MM-DD"),
            })
          }

          if (!monthsSpan && action.meta.arg.isFetchingMore) {
            events.push({
              id: moment(action.meta.arg.dateRange.min)
                .add(0, "months")
                .format("YYYY-MM-DD"),
              isEmptyMonth: true,
              startDate: moment(action.meta.arg.dateRange.min)
                .add(0, "months")
                .format("YYYY-MM-DD"),
              month: moment(action.meta.arg.dateRange.min)
                .add(0, "months")
                .format("YYYY-MM-DD"),
            })
          }
        }

        events = events.filter((event: any) => event)

        let orderedEvents = _.orderBy(
          events.filter((event: any) => {
            // Make sure we're not bringing in duplicates
            return !state.data.find((existing) => existing.id === event.id)
          }),
          (event: any) => event && event.startDate
        )
        state.data = state.data.concat(orderedEvents)
        let truncatedData: any[] = []

        const actualEvents = state.data.filter((event: any) => !event.month)
        console.log("actualEvents", actualEvents)
        actualEvents.forEach((event: any) => {
          let eventsOnDay = truncatedData.filter((existing) => {
            let onDay =
              moment(existing.startDate).startOf("day").format("MM/DD") ===
              moment(event.startDate).startOf("day").format("MM/DD")

            if (
              moment(event.startDate).isBetween(
                existing.startDate,
                existing.endDate
              )
            ) {
              onDay = true
            }

            return onDay
          })

          if (eventsOnDay.length < 2) {
            truncatedData.push(event)
          }
        })

        state.truncatedData = truncatedData
        state.fetched = true
      }
    })

    builder.addCase(fetchArchivedEvents.fulfilled, (state, action: any) => {
      let events =
        action.payload && action.payload.events.length
          ? action.payload.events.map((event: any) => {
              let startDate: string, endDate: string

              if (event.field_all_day === "True") {
                startDate = moment(event.start_date).startOf("day").format()
                endDate = moment(event.start_date)
                  .add(1, "day")
                  .startOf("day")
                  .format()
              } else {
                startDate = moment(setDSTDisplay(event.start_date)).format()
                endDate = moment(setDSTDisplay(event.end_date)).format()
              }

              let eventObject = {
                startDate,
                endDate,
                id: event.uuid,
                isAllDay: event.field_all_day === "True",
                isConference: event.field_award_credit_for_conferenc
                  ? true
                  : false,
                canView: true,
                rsvp: event.field_rsvp,
                field_archive: event.field_archive === "True",
                field_draft: event.field_draft === "True",
                image: event.field_event_image,
                drupal_internal__id: event.id,
                title: event.name,
                description: event.field_description,
                location: event.location,
              }

              return eventObject
            })
          : []

      state.archived.fetched = true
      state.archived.data = events
    })
  },
})

const truncateData = (data: any) => {
  let truncatedData: any = []

  data.forEach((event: any) => {
    let eventsOnDay = truncatedData.filter(
      (existing: any) =>
        moment(existing.startDate).startOf("day").format() ===
        moment(event.startDate).startOf("day").format()
    )
    if (!event.field_archive) {
      eventsOnDay.length < 2 && truncatedData.push(event)
    }
  })

  return truncatedData
}

export const sortEvents = (state: RootState) => {
  let sorted = _.orderBy(
    state.calendar.data,
    (event: any) => event.startDate,
    "asc"
  )
  return sorted
}

export const {
  setSidebarItem,
  setSearchActive,
  setSearchQuery,
  setCurrentDate,
  setShowArchive,
} = calendarSlice.actions

export default calendarSlice.reducer
