import TimeCapsule from "../../components/TimeCapsule"
import React from "react"
import { Course } from "../../types/explore"

interface RowProps {
  data: Course[]
}

const CoursesPrintRow: React.FC<RowProps> = ({ data }) => {
  return (
    <div className="print-section">
      {data.map((course: Course, courseIndex: number) => {
        const totalMinutes = Number(course.field_course_estimated_time_value)
        const hours =
          totalMinutes >= 60
            ? Math.floor(totalMinutes / 60).toLocaleString()
            : 0
        const minutes = totalMinutes % 60

        return (
          <div key={courseIndex} className="item course-item">
            <img
              src={course.field_category_image_uri}
              style={{
                borderRadius: 100,
                marginRight: 5,
                display: "inline-block",
                width: 28,
                height: 28,
                border: "3px solid white",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.30)",
              }}
            />

            <strong>{course.name}</strong>
            <TimeCapsule hours={Number(hours)} minutes={minutes} />
          </div>
        )
      })}
    </div>
  )
}

export default CoursesPrintRow
