import he from "he"
import moment from "moment"
import React from "react"
import { Badge } from "../../types/explore"

interface RowProps {
  data: Badge[]
}

const BadgesPrintRow: React.FC<RowProps> = ({ data }) => {
  return (
    <div className="print-section">
      {data.map((badge: Badge, index: number) => {
        return (
          <div key={index} className="item badge-item">
            <img
              className="badgeImage"
              src={badge.field_badge_image_uri}
              style={{
                borderRadius: 100,
                marginRight: 10,
                display: "inline-block",
              }}
            />

            <div style={{ flex: 1 }}>
              <strong
                style={{
                  display: "block",
                  fontSize: 14,
                  lineHeight: 1.2,
                }}
              >
                {he.decode(badge.name)}
              </strong>
              <span style={{ opacity: 0.6 }}>
                {moment(badge.earned_date).format("MMMM Do, YYYY")}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default BadgesPrintRow
