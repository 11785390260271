import { useEffect, useState } from "react"
import TimeCapsule from "../../components/TimeCapsule"
import classNames from "classnames"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import { useDispatch } from "react-redux"

import Loading from "../../components/Loading"
import { Course } from "../../types/explore"
import BackpackProps from "../../types/backpack"
import { SlideItem } from "./ContentRow"

interface RowProps {
  data: Course[]
  backpack: BackpackProps
  goTo?: (location: string) => void
  increaseCoursePage: (arg: number) => void
  setCourseCarousel: (arg: number) => void
}

const CoursesRow = (props: RowProps) => {
  const { data, backpack, goTo, increaseCoursePage, setCourseCarousel } = props
  const [chunkSize, setChunkSize] = useState(12)

  const dispatch = useDispatch()

  function onResize() {
    if (window.innerWidth < 1000) {
      setChunkSize(4)
    } else if (window.innerWidth < 1600) {
      setChunkSize(8)
    } else {
      setChunkSize(12)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const chunkedData = []

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize)
    chunkedData.push(chunk)
  }

  function nextSlideChanged(event: SlideItem) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCourseCarousel(event.item > backpack.courses.activeIndex ? 1 : -1)
    )
  }

  function nextSlide(event: SlideItem) {
    if (event.itemsInSlide - 2 === event.item) {
      if (backpack.courses.currentPage < backpack.courses.totalPages - 1) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          increaseCoursePage(event.item)
        )
      }
    }
  }

  const items = chunkedData.map((chunk: Course[], index: number) => {
    return (
      <div key={index} className="chunk" style={{ flexWrap: "wrap" }}>
        {chunk.map((course: Course, courseIndex: number) => {
          const totalMinutes = Number(course.field_course_estimated_time_value)
          const hours =
            totalMinutes >= 60
              ? Math.floor(totalMinutes / 60).toLocaleString()
              : 0
          const minutes = totalMinutes % 60

          return (
            <div
              onClick={() => {
                if (goTo) {
                  goTo("/course/" + course.id)
                }
              }}
              key={courseIndex}
              className="item course-item"
            >
              <span
                className="category-icon"
                style={{
                  backgroundImage:
                    "url(" + course.field_category_image_uri + ")",
                }}
              />
              <strong>{course.name}</strong>
              <TimeCapsule hours={Number(hours)} minutes={minutes} />
            </div>
          )
        })}
      </div>
    )
  })

  return (
    <div
      className={classNames(
        "inner inner-carousel",
        !backpack.courses.activeIndex && "disablePrev",
        chunkedData.length - 1 === backpack.courses.activeIndex && "disableNext"
      )}
    >
      {backpack.courses.isFetching && <Loading />}

      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AliceCarousel
          infinite={true}
          activeIndex={backpack.courses.activeIndex}
          disableDotsControls={chunkedData.length === 1}
          paddingRight={25}
          paddingLeft={25}
          autoWidth={true}
          items={items}
          onSlideChange={(event) => nextSlide(event)}
          onSlideChanged={(event) => nextSlideChanged(event)}
        />
      }
    </div>
  )
}

export default CoursesRow
