import moment from "moment"
import React from "react"
import { MandatedTrainingHistory } from "../../types/mandatedTraining"

interface RowProps {
  data: MandatedTrainingHistory[]
}

export const MandatedTrainingPrintRow: React.FC<RowProps> = ({ data }) => {
  return (
    <div className="print-section">
      {data.map((training: MandatedTrainingHistory, index: number) => {
        return (
          <div key={index} className="item mandated-item">
            <img
              className="icon mandated-black"
              src={
                process.env.REACT_APP_HQ_URL +
                "/static/media/mandatory-black.946b529b2643cdf6b11d.svg"
              }
            />

            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <strong className="title">{training.umta_name}</strong>
              <span style={{ opacity: 0.6, fontSize: 10 }}>
                {moment(training.field_comp_date).format("MMMM Do, YYYY")}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}
